interface FnolSteps {
  CLAIMANT_INFO: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}

interface FnolStepsObj {
  name: string;
  slNo: number;
  title: string;
  progressStepTitle: string;
}

export const FNOL_STEPS_SINGAPORE: FnolSteps = {
  CLAIMANT_INFO: {
    name: "fnol-claimant-info",
    slNo: 1,
    title: `Personal Information`,
    progressStepTitle: `Personal Information`,
  },
  CLAIM_TYPE_DETAILS: {
    name: "fnol-claimtype-details",
    slNo: 2,
    title: `Claim Details`,
    progressStepTitle: `Claim Details`,
  },
  PAYMENT_DETAILS: {
    name: "fnol-payment-details",
    slNo: 3,
    title: `Payment Details`,
    progressStepTitle: `Payment`,
  },
  UPLOAD_DOCS: {
    name: "fnol-upload-docs",
    slNo: 4,
    title: ` Documents`,
    progressStepTitle: `Documents`,
  },
  DECLARATION: {
    name: "fnol-declaration",
    slNo: 5,
    title: `Review and Submit the Claim`,
    progressStepTitle: `Review & Submit`,
  },
};

export const FNOL_STEPS_LITE: FnolSteps = {
  CLAIMANT_INFO: {
    name: "fnol-claimant-info",
    slNo: 1,
    title: `Personal Information`,
    progressStepTitle: `Personal Information`,
  },
  CLAIM_TYPE_DETAILS: {
    name: "fnol-claimtype-details",
    slNo: 2,
    title: `Claim Details`,
    progressStepTitle: `Claim Details`,
  },
  PAYMENT_DETAILS: {
    name: "fnol-payment-details",
    slNo: 3,
    title: `Payment Details`,
    progressStepTitle: `Payment`,
  },
  UPLOAD_DOCS: {
    name: "fnol-upload-docs",
    slNo: 4,
    title: ` Documents`,
    progressStepTitle: `Documents`,
  },
  DECLARATION: {
    name: "fnol-declaration",
    slNo: 5,
    title: `Review and Submit the Claim`,
    progressStepTitle: `Review & Submit`,
  },
};

export const ContainerCountMapSG = {
  addTreatmentMain: 7,
  addFractureMain: 7,
  addDislocationMain: 7,
  addLeaveMain: 7,
  addDoctorMain: 7,
  addWitnessMain: 7,
  addIncomeMain: 7,
  addItemsMain: 7,
  addStolenMain: 7
};

export const DATE_FORMAT = "dd/MM/yyyy";
export const CLAIM_TYPE_DATE_FIELDS = [
  "dateAccident",
  "firstVisit",
  "dateOfAdmission",
  "dateOfDischarge",
  "dateOfStart",
  "dateOfDisability",
  "dateOfDeath",
  "dateSymptom",
  "dateVisit",
  "dateOfAdmissionIllness",
  "dateOfDischargeIllness",
  "dateOfDiagnosed",
];

export const SG_DATA_CONTAINERS = [
  "addTreatmentMain",
  "addFractureMain",
  "addDislocationMain",
  "addLeaveMain",
  "addDoctorMain",
  "addWitnessMain",
  "addIncomeMain",
];

export const HEADINGMANAGERSG = {
  claimTypeAs: [
    "claimTypeAs",
    "others",
    "firstName",
    "lastName",
    "insuredDOB",
    "insuredNric",
    "insuredMobileNumber",
    "insuredEmail",
    "nric",
    "nonInsuredMobileNumber",
    "email",
    "dob",
    "currentAge",
    "country",
    "myAddressLookup",
    "levelUnitNumber",
    "postalCode",
    "insuredCountry",
  ],
  nameBroker: ["nameBroker", "emailBroker", "brokerMobileNumber"],
  accidentOccurred: [
    "accidentOccurred",
    "injuriesSustained",
    "dateAccident",
    "timeIncident",
    "accidentLocationLookup",
    "firstVisit",
    "diagnosis",
    "others",
    "trafficAccident",
  ],
  chooseClaimType: ["chooseClaimType"],
  medicalExpense: ["medicalExpense", "citizen"],
  admittedHospital: [
    "admittedHospital",
    "addTreatmentMain",
    "admittedIcu",
    "admittedToIcu",
  ],
  sustainFracture: [
    "sustainFracture",
    "addFractureMain",
    "sustainDislocation",
    "addDislocationMain",
  ],
  addLeaveMain: ["addLeaveMain", "employed", "dateOfExpected"],
  dateOfDisability: ["dateOfDisability", "typeDisability"],
  dateOfDeath: [
    "dateOfDeath",
    "localTimePersonal",
    "causeDeath",
    "causeOthers",
    "autopsy",
    "reason",
  ],
  doctorInfo: ["doctorInfo", "addDoctorMain"],
  witnessInfo: ["witnessInfo", "addWitnessMain"],
  describeIllness: [
    "describeIllness",
    "dateSymptom",
    "dateVisit",
    "diagnosisIllness",
    "othersIllness",
    "claimingIllness",
  ],
  chooseClaimTypeIllness: ["chooseClaimTypeIllness"],
  dailyIncome: ["addIncomeMain", "admittedIcuIllness", "admittedToIcuIllness"],
  dateOfDiagnosed: ["dateOfDiagnosed", "disabilityIllness"],
  paymentChannel: [
    "paymentChannel",
    "payeeNameBank",
    "bankName",
    "accountNumber",
    "confirmAccountNumber",
    "payeeName",
  ],
  customFileUpload: ["customFileUpload"],
};


export class UtilsConstSGLabelsRetrieve {
  public static getClaimBenefitTypes(benefitType): Array<any> {
    let claimBenefitType = [];
    if (benefitType === "Personal Accident") {
      claimBenefitType = [
        {
          id: "1",
          name: "Copy of Death Certificate",
        },
        {
          id: "2",
          name: "Medical Bill And Receipts",
        },
        {
          id: "3",
          name: "Medical Leave Certificate",
        },
        {
          id: "4",
          name: "Medical Report/Doctor's Memo/Inpatient Discharge Summary",
        },
        {
          id: "5",
          name: "Medical Reports",
        },
        {
          id: "6",
          name: "Police Report If This Is A Road Traffic Accident",
        },
        {
          id: "7",
          name: "Postmortem And Toxicology Report",
        },
        {
          id: "8",
          name: "Referral Letter For Physiotherapy Treatment",
        },
      ];
    } else if (benefitType === "Illness / Critical Illness") {
      claimBenefitType = [
        {
          id: "1",
          name: "All Available Functional Test Results",
        },
        {
          id: "2",
          name: "All Available Test/Laboratory Results",
        },
        {
          id: "3",
          name: "Detailed Medical Report",
        },
        {
          id: "4",
          name: "Hospitalisation Bills",
        },
        {
          id: "5",
          name: "Medical Report/Inpatient discharge summary/Attending physician's statement by treating doctor",
        },
      ];
    }
    return claimBenefitType;
  }
}
export class UtilsConstLITELabelsRetrieve {
  public static getClaimBenefitTypes(benefitType): Array<any> {
    let claimBenefitType = [];
    if (benefitType === "Personal Accident") {
      claimBenefitType = [
        {
          id: "1",
          name: "Copy of Death Certificate",
        },
        {
          id: "2",
          name: "Medical Bill And Receipts",
        },
        {
          id: "3",
          name: "Medical Leave Certificate",
        },
        {
          id: "4",
          name: "Medical Report/Doctor's Memo/Inpatient Discharge Summary",
        },
        {
          id: "5",
          name: "Medical Reports",
        },
        {
          id: "6",
          name: "Police Report If This Is A Road Traffic Accident",
        },
        {
          id: "7",
          name: "Postmortem And Toxicology Report",
        },
        {
          id: "8",
          name: "Referral Letter For Physiotherapy Treatment",
        },
      ];
    } else if (benefitType === "Travel") {
      claimBenefitType = [
        {
          id: "1",
          name: "Acknowledgement receipt on date and time baggage received",
        },
        {
          id: "2",
          name: "Airline Letter on the flight delay",
        },
        {
          id: "3",
          name: "Booking Confirmation/Original Invoice",
        },
        {
          id: "4",
          name: "Copy of death Certificate (Where applicable)",
        },
        {
          id: "5",
          name: "Copy of passport and travel itinerary",
        },
        {
          id: "6",
          name: "Doctor's memo/medical report/inpatient discharge summary",
        },
        {
          id: "7",
          name: "Letter of refund",
        },
        {
          id: "8",
          name: "Medical report/memo from the doctors advising the fitness to travel",
        },
        {
          id: "9",
          name: "Original medical bills and receipts",
        },
        {
          id: "10",
          name: "Original phone bills (For Emergency Telephone Charges benefit)",
        },
        {
          id: "11",
          name: "Original receipts for replacement/temporary travel documents",
        },
        {
          id: "12",
          name: "Original transportations and accommodation receipts in obtaining replacement travel documents",
        },
        {
          id: "13",
          name: "Police Report",
        },
        {
          id: "14",
          name: "Proof of relationship to insured",
        },
        {
          id: "15",
          name: "Proof of unused entertainment ticket/frequent flyer points (for Disruption Benefit)",
        },
        {
          id: "16",
          name: "Property irregularity report",
        },
        {
          id: "17",
          name: "Travel itineraries and Boarding Pass",
        }
      ];
    }
    else {
      claimBenefitType = [
        {
          id: "1",
          name: "Other Documents",
        }
      ]
    }
    return claimBenefitType;
  }
}

export const FRACTURELOCATION = [
  {
    typeId: "1",
    locations: [
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "2",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "3",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "4",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "5",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "6",
    locations: [
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "7",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compression",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "8",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
];
