import { Observable, Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { EncrDecrService } from "././services/utils/encr-decr.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from './config';

@Injectable()
export class Configuration {
  response: any;
  public buildVersion: string = "0.1";
  public hostName: string;

  public googleMapsAPIEndpoint: string;
  public googleMapsAPIKey: string;
  public googleRecapchaSiteKey: string;

  public mClientId: string;
  public cClientId: string;

  public userEmailID: string;

  // APAC ANH change start
  public aigSharedeMailID: any;
  public brokerEmailID: string;
  public applicationUserId: string;
  public countryConfig: string;
  // APAC ANH change end

  public spId: string;

  public cognitoLoginUrl: string;
  public cognitoLogoutUrl: string;

  public smTargetUrl: string;
  public smLoginUrl: string;

  public smLogoutUrl: string;
  public smChangePasswordUrl: string;

  public baseURL: any;
  public baseURLJP: any;
  public baseURLFNOL: any;
  public apiEndPoints: any = {};
  public apiEndPoints_cdp_lite: any = {};
  public authorizationKey: string;
  public authorizationKey_new: string;
  public emailId: string;

  public searchPolicyHeaderEmail: string;

  public aem: string;
  private configSub: Observable<any>;
  public siteId: string;
  public maintanenceSubject = new Subject();
  public static countryConfig = {};
  constructor(private EncrDecr: EncrDecrService, private http: HttpClient) {
    const url = AppConfig.getConfigUrl();
    this.configSub = this.http.get(url);

  }

  initConfig() {
    const url = AppConfig.getConfigUrl();
    this.configSub = this.http.get(url);

    this.configSub.subscribe((response) => {
      this.hostName = location.hostname;
      this.googleMapsAPIEndpoint = response.googleMapsAPIEndpoint;
      this.googleMapsAPIKey = this.EncrDecr.dataEncryptDecrypt(
        response.googleMapsAPIKey,
        "decrypt"
      );

      this.userEmailID = response.userEmailID;
      // APAC ANH change start
      this.aigSharedeMailID = response.aigSharedeMailID;
      this.brokerEmailID = response.brokerEmailID;
      this.applicationUserId = response.applicationUserId;
      this.countryConfig = response.countryConfig;
      // APAC ANH change end

      this.cognitoLoginUrl = response.cognitoLoginUrl;
      this.cognitoLogoutUrl = response.cognitoLogoutUrl;

      this.smTargetUrl = response.smTargetUrl;
      this.smLoginUrl = response.smLoginUrl;

      this.smLogoutUrl = response.smLogoutUrl;
      this.smChangePasswordUrl = response.smChangePasswordUrl;
      this.baseURLJP = response.baseURLJP;
      this.baseURL = response.baseURL;
      this.baseURLFNOL = response.baseURLFNOL;
      this.apiEndPoints = response.apiEndPoints;
      this.apiEndPoints_cdp_lite = response.apiEndPoints_cdp_lite;
      // this.authorizationKey = this.EncrDecr.dataEncryptDecrypt(
      //   response.authorizationKey,
      //   "decrypt"
      // );
      this.authorizationKey_new = response.authorizationKey_new;
      this.emailId = "vinod.biradar@aig.com";

      this.searchPolicyHeaderEmail = response.searchPolicyHeaderEmail;
      this.aem = response.analyticsURL;
      this.siteId = response.siteId;
      this.countryConfig = response.countryConfig;
      this.callMaintanenceApi();
    });

  }
  callMaintanenceApi() {

    this.maintanenceSubject.next('checkMaintanenceApiStatus');
  }
}
