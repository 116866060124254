import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpsRequestInterceptor } from "./shared/utils/http-request-interceptor";
import { AppRoutingModule } from "./app-routing.module";
import { FeaturesModule } from "./features/features.module";
import { DatePipe } from "@angular/common";
import { CacheService } from "./shared/services/cache.service";
import { TimerService } from "./shared/services/timer.service";
import { HttpService } from "./shared/services/http.service";
import { AppComponent, HeaderComponent, FooterComponent } from "./";
import { ModalContactComponent } from "../app/header/contact-modal/modal-contact.component";
import { ModalConfirmComponent } from "../app/header/confirm-modal/modal-confirm.component";
import { ConditionService } from "../app/shared";
import { AppConfig } from "./shared/config";
import { APP_INITIALIZER } from "@angular/core";
import { AxisSharedModule } from "./_shared/axis-shared.module";
import { AxisSharedAugModule } from "./_shared/axis-shared-aug.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { Configuration } from "./shared/configuration";
import { SharedComponentModuleModule } from "./shared-component-module/shared-component-module.module";
import { AbcSearchAddressControlModule } from 'axis-ui-generator/abc/abc-search-address-control';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ModalContactComponent,
    ModalConfirmComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FeaturesModule,
    AppRoutingModule,
    AxisSharedModule,
    AxisSharedAugModule,
    BrowserAnimationsModule,
    AbcSearchAddressControlModule,
    SharedComponentModuleModule
  ],
  providers: [
    HttpService,
    ConditionService,
    CacheService,
    TimerService,
    AppConfig,
    DatePipe,
    Configuration,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: Configuration) => () => config.initConfig(),
      deps: [Configuration],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "en-US" }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }
}
