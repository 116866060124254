

<div >
  <div class="axis-grid _align-items-center axis-reveal" [class.axis-conceal]="uploadList?.children.length < 1">
    <div class="axis-col">
      <h2 class="axis-h5">Uploaded Files ({{uploaded.length}})</h2>
    </div>
    <div class="axis-col-auto">
      <ul data-xs-stack="true" class="axis-list-inline">
        <li i18n="@@dragdrop">Drag and drop or</li>
        <li>
          <axis-upload data-kind="secondary" data-size="small" [imagePreview]="imagePreview" [isMulti]="isMulti"
            (listChange)="uploadFiles($event)" [restrictFiles]="restrictFiles" [title]="afterUploadButtonText"
            #fileUpload></axis-upload>
        </li>
      </ul>
    </div>
  </div>

  <axis-card-row *ngIf="fileUploadFailed">
    <axis-alert data-kind="danger">{{apiFailure}}</axis-alert>
  </axis-card-row>

  <axis-card-row *ngIf="deleteFailedError">
    <axis-alert data-kind="danger">{{deleteFailure}}</axis-alert>
  </axis-card-row>

  <axis-card-row *ngIf="zeroByteError">
    <axis-alert data-kind="danger">
      {{zeroByteErrorMsg}}
    </axis-alert>
  </axis-card-row>

  <axis-card-row *ngIf="exceedSizeError">
    <axis-alert data-kind="danger">
      {{exceedSizeErrorMsg}}
    </axis-alert>
  </axis-card-row>
  <axis-card-row *ngIf="uploadSumErrorSize">
    <axis-alert data-kind="danger">
      {{uploadSumErrorSizeMsg}}
    </axis-alert>
  </axis-card-row>
  <axis-card-row *ngIf="formatUnsupportedError">
    <axis-alert data-kind="danger">
      {{formatUnsupportedErrorMsg}}
      See <a href="supported.html" target="_blank"><span>full list</span></a>.
    </axis-alert>
  </axis-card-row>

  <axis-upload
    *ngIf="(!uploadList?.children.length && !fileLoading) || (!uploadList?.children.length && fileUploadFailed)"
    documentFlag="true" [imagePreview]="imagePreview" [isMulti]="isMulti" (listChange)="uploadFiles($event)"
    [restrictFiles]="restrictFiles" [titleText]="uploadText" type="dragndrop" #fileUpload>
  </axis-upload>
  <axis-card axis-drag-highlight [class._hidden]="uploadList?.children.length < 1 && !fileLoading"
    [class.no-highlight]="uploadList?.children.length < 1" data-size="small" documentFlag="true"
    [imagePreview]="imagePreview" [isMulti]="isMulti" (listChange)="uploadFiles($event)" [restrictFiles]="restrictFiles"
    type="dragndrop" #fileUpload="axis-drag-highlight">
    <div class="axis-table-mobile axis-table-responsive axis-upload-wrapper axis-flush axis-reveal"
      [class.axis-conceal]="uploadList?.children.length < 1 && !fileLoading">
      <table aria-describedby="idUploadCaption" aria-label="Uploaded Files" class="axis-table axis-table-basic"
        role="grid">
        <caption id="idUploadCaption">
          Lists uploaded files.
        </caption>
        <colgroup>
          <col>
          <col>
          <col>
        </colgroup>

        <tbody role="rowgroup" #uploadList>

          <!-- uploaded file list -->
          <ng-container *ngFor="let item of (uploaded); let index = index; trackBy:trackByIndex">
            <tr *ngIf="item.active" [class.row-active]="item.highlight || item.edit"
              [class.row-success]="item.newHighlight" (mouseenter)="item.highlight=true"
              (mouseleave)="item.highlight=false" role="row" class="axis-grid _nm">
              <td data-heading="File" role="gridcell" class="axis-col-lg-5">
                <axis-media data-align="middle">
                  <axis-media-left>
                    <axis-icon aria-hidden="true" data-rotate="left" data-size="xlarge" font="custom" icon="sync"
                      *ngIf="item.newHighlight"></axis-icon>
                    <ng-container *ngIf="!item.newHighlight">
                      <axis-icon *ngIf="!item.img" data-size="xlarge" font="custom"
                        [icon]="item.uploadIcon"></axis-icon>
                      <img *ngIf="item.img" alt="Upload Image" class="axis-img-circle img-responsive" [src]="item.src">
                    </ng-container>
                  </axis-media-left>
                  <axis-media-body>
                    <div class="_text-bold" [class.axis-conceal]="item.edit">
                      <a href [innerHtml]="item.name"></a>
                    </div>
                    <div class="axis-form-group axis-reveal" [class.axis-conceal]="!item.edit"
                      [class._hidden]="editMultipleFields" [class.has-axis-error]="editedNameVar.touched && editedNameVar.blurred &&
                        editedNameVar.value===''" [class.has-axis-required]="editedNameVar.value===''">
                      <input aria-label="File Name" [attr.aria-describedby]="editedNameVar.touched &&
                          editedNameVar.value==='' ? 'editedNameError-'+i : null"
                        [attr.aria-invalid]="editedNameVar.touched && editedNameVar.value===''" autocomplete="off"
                        axis-input-clear (blur)="editedNameVar.blurred=true" class="axis-form-control"
                        (cut)="item.edited=editedNameVar.value"
                        (focus)="editedNameVar.touched=true;editedNameVar.blurred=false" id="editedName-{{index}}"
                        (keyup)="item.edited=editedNameVar.value" (keyup.enter)="renameFile(item,editedNameVar)"
                        (onClear)="item.edited=''" (paste)="item.edited=editedNameVar.value"
                        placeholder="Enter a file name..." required type="text" [value]="item.name" #editedNameVar>
                      <div [attr.aria-hidden]="!editedNameVar.touched || editedNameVar.value!==''"
                        class="axis-error-block" id="editedNameError-{{index}}" role="alert">
                        Please enter a file name or cancel.
                      </div>
                    </div>
                    <small [class._hidden]="editedNameVar.blurred && editedNameVar.touched &&
                        editedNameVar.value===''" data-color="muted">
                      <span class="_mr">{{item.uploadDate}} {{item.uploadDateTime}}</span>
                      {{item.size | fileSize}} bytes
                    </small>
                  </axis-media-body>
                  <axis-media-right *ngIf="item.newHighlight">
                    <button aria-label="Cancel Upload" axis-button (click)="cancelUpload(index)" data-kind="icon">
                      <axis-icon data-size="small" font="custom" icon="fail"></axis-icon>
                    </button>
                  </axis-media-right>
                </axis-media>
              </td>
              <td *ngIf="!item.newHighlight"  data-heading="File Details" role="gridcell" class="axis-col-lg-4">

                <div class="axis-form-group" >
                  <label class="axis-control-label" for="dd" i18n="@@claimBenefit">
                    {{dropDownLabel}}
                  </label>
                  <axis-dropdown [wrap]="true"
                    [attr.aria-describedby]="(ddVar.touched || errorMessage) && !item.claimBenefitType ? 'ddError' : null"
                    [attr.aria-invalid]="(ddVar.touched || errorMessage) && !item.claimBenefitType"
                    [dataProvider]="claimBenefitTypes" id="dd" i18n-placeholder="@@claimBenefitTooltip"
                    placeholder="{{typePlaceholder}}" [ngModel]="item.claimBenefitType"
                    (ngModelChange)="modelChanged($event, index)" required="true" #ddVar="ngModel"></axis-dropdown>
                  <div *ngIf="!item.claimBenefitType && (ddVar.touched || errorMessage)" class="axis-error-block"
                    id="ddError" role="alert" i18n="@@claimBenefitError">
                    {{messageError}}
                  </div>
                </div>
                
              </td>
              <td class="_text-md-right axis-col-lg-3 _pt-xxlarge" data-heading="Actions" role="gridcell">
                <ng-container *ngIf="!item.newHighlight">
                  <div class="axis-reveal" [class.axis-conceal]="item.edit">
                    <button aria-label="Edit File Name" axis-button [class._hidden]="editMultipleFields"
                      (click)="editFileName(item, index)" data-kind="icon" id="editButton-{{index}}">
                      <axis-icon font="custom" icon="edit"></axis-icon>
                    </button>
                    <button aria-label="Edit File Parameters" axis-button [class._hidden]="!editMultipleFields"
                      (click)="openModalEdit(modalEditFile,item)" data-kind="icon" id="editButtonModal-{{index}}">
                      <axis-icon font="custom" icon="edit"></axis-icon>
                    </button>
                    <button aria-label="Delete File" axis-button (click)="modalDeleteFile.open();fileToDelete=item"
                      data-kind="icon">
                      <axis-icon data-size="small" font="custom" icon="delete"></axis-icon>
                    </button>
                  </div>
                  <div class="axis-reveal" [class.axis-conceal]="!item.edit">
                    <axis-media>
                      <axis-media-left>
                        <button aria-label="Rename the File" axis-button (click)="renameFile(item,editedNameVar)"
                          data-kind="secondary" data-size="small">
                          Rename
                        </button>
                      </axis-media-left>
                      <axis-media-right>
                        <button aria-label="Cancel Rename" axis-button
                          (click)="cancelFileEdit(item,editedNameVar,index)" data-kind="icon">
                          <axis-icon data-size="small" font="custom" icon="clear"></axis-icon>
                        </button>
                      </axis-media-right>

                    </axis-media>


                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>

        </tbody>
      </table>
    </div>

  </axis-card>

  <axis-modal heading="Edit File" #modalEditFile>
    <axis-modal-content>
      <div class="axis-grid">
        <div class="axis-col-md-6">
          <div class="axis-form-group" [class.has-axis-error]="editedNameModalVar.touched && editedNameModalVar.blurred &&
             editedNameModalVar.value===''" [class.has-axis-required]="editedNameModalVar.value===''">
            <label class="axis-control-label" for="editedNameModal">File Name</label>
            <input [attr.aria-describedby]="editedNameModalVar.touched &&
              editedNameModalVar.value==='' ? 'editedNameModalError' : null"
              [attr.aria-invalid]="editedNameModalVar.touched && editedNameModalVar.value===''" autocomplete="off"
              axis-input-clear (blur)="editedNameModalVar.blurred=true" class="axis-form-control"
              (cut)="fileToEdit.edited=editedNameModalVar.value"
              (focus)="editedNameModalVar.touched=true;editedNameModalVar.blurred=false" id="editedNameModal"
              (keyup)="fileToEdit.edited=editedNameModalVar.value" (onClear)="fileToEdit.edited=''"
              (paste)="fileToEdit.edited=editedNameModalVar.value" placeholder="Enter a file name..." required
              type="text" [value]="fileToEdit?.name" #editedNameModalVar>
            <div [attr.aria-hidden]="!editedNameModalVar.touched || editedNameModalVar.value!==''"
              class="axis-error-block" id="editedNameModalError" role="alert">
              <span i18n='@@renameError'> Please enter a file name. </span>
            </div>
          </div>
        </div>
        <div class="axis-col-md-6">
          <div class="axis-form-group" [class.has-axis-error]="editedUploadedByModalVar.touched && editedUploadedByModalVar.blurred &&
            editedUploadedByModalVar.value===''" [class.has-axis-required]="editedUploadedByModalVar.value===''">
            <label class="axis-control-label" for="editedUploadedByModal">Uploaded by</label>
            <input [attr.aria-describedby]="editedUploadedByModalVar.touched &&
              editedUploadedByModalVar.value==='' ? 'editedUploadedByModalError' : null"
              [attr.aria-invalid]="editedUploadedByModalVar.touched && editedUploadedByModalVar.value===''"
              autocomplete="off" axis-input-clear (blur)="editedUploadedByModalVar.blurred=true"
              class="axis-form-control" (cut)="fileToEdit.editedUploadedBy=editedUploadedByModalVar.value"
              (focus)="editedUploadedByModalVar.touched=true;editedUploadedByModalVar.blurred=false"
              id="editedUploadedByModal" (keyup)="fileToEdit.editedUploadedBy=editedUploadedByModalVar.value"
              (onClear)="fileToEdit.editedUploadedBy=''"
              (paste)="fileToEdit.editedUploadedBy=editedUploadedByModalVar.value" placeholder="Enter a name..."
              required type="text" [value]="fileToEdit?.uploadedBy" #editedUploadedByModalVar>
            <div [attr.aria-hidden]="!editedUploadedByModalVar.touched || editedUploadedByModalVar.value!==''"
              class="axis-error-block" id="editedUploadedByModalError" role="alert">
              Please enter the name of the uploader.
            </div>
          </div>
        </div>
      </div>
    </axis-modal-content>
    <axis-modal-footer>
      <div class="axis-btn-wrapper">
        <div class="axis-btn-wrapper-right">
          <button axis-button data-kind="link" (click)="modalEditFile.close()">Cancel</button>
          <button axis-button (click)="modalEditFile.close();saveFile()" data-kind="primary"
            [disabled]="fileToEdit?.edited==='' || fileToEdit?.editedUploadedBy===''">Save</button>
        </div>
      </div>
    </axis-modal-footer>
  </axis-modal>

  <axis-modal heading="Delete File" #modalDeleteFile>
    <axis-modal-content>
      Are you sure you want to delete <span class="_text-bold">{{fileToDelete?.name}}</span>?
    </axis-modal-content>
    <axis-modal-footer>
      <div class="axis-btn-wrapper">
        <div class="axis-btn-wrapper-right">
          <button axis-button (click)="modalDeleteFile.close()" data-kind="link">Close</button>
          <button axis-button (click)="modalDeleteFile.close();deleteFile(fileToDelete)"
            data-kind="primary">Delete</button>
        </div>
      </div>
    </axis-modal-footer>
  </axis-modal>
</div>
