import { EncrDecrService } from "./services/utils/encr-decr.service";

export class AppConfig {
  public static buildVersion: string = "0.1";
  public static hostName: string;

  public static googleMapsAPIEndpoint: string;
  public static googleMapsAPIKey: string;
  public static googleRecapchaSiteKey: string;

  public static mClientId: string;
  public static cClientId: string;

  public static userEmailID: string;

  // APAC ANH change start
  public static aigSharedeMailID: any;
  public static brokerEmailID: string;
  public static applicationUserId: string;
  // APAC ANH change end

  public static spId: string;

  public static cognitoLoginUrl: string;
  public static cognitoLogoutUrl: string;

  public static smTargetUrl: string;
  public static smLoginUrl: string;

  public static smLogoutUrl: string;
  public static smChangePasswordUrl: string;

  public static baseURL: any;
  public static baseURLJP: any;
  public static baseURLFNOL: any;
  public static apiEndPoints: any = {};
  public static authorizationKey: string;
  public static authorizationKey_new: string;
  public static emailId: string;

  public static searchPolicyHeaderEmail: string;

  public static analyticsURL: string;

  public static analyticsURLJP: string;
  public static oktaConfig = {};
  public static apiBaseUrlLogin: string;
  public static baseURLOKTA: string;
  public static oktaUrl: string;
  public static authServerID: string;
  public static scopes = ['claimsdigital', 'openid', 'profile', 'email', 'offline_access'];
  public static countryConfig = {};

  public static setEnvConfig(config: any) {
    const decrypt = new EncrDecrService();
    for (const p in config) {
      if (config[p] === null || config[p] === undefined) {
        continue;
      }
      AppConfig[p] = config[p];

      switch (p) {
        case "googleMapsAPIKey":
          AppConfig[p] = decrypt.dataEncryptDecrypt(AppConfig[p], "decrypt");
          break;
        case "authorizationKey":
          AppConfig[p] = decrypt.dataEncryptDecrypt(AppConfig[p], "decrypt");
          break;
        default:
          break;
      }
    }
  }

  public static getConfigUrl() {
    let configUrl = '';
    if (location.hostname.includes("dev.cdpfnol.nprd.aig.com") || location.hostname.includes("localhost") || location.hostname.includes("dev2.cdpfnol.nprd.aig.com") || location.hostname.includes("dev3.cdpfnol.nprd.aig.com")) {
      configUrl = "assets/config/config.json";
    } else if (location.hostname.includes("qa.cdpfnol.nprd.aig.com") || location.hostname.includes("qa2.cdpfnol.nprd.aig.com") || location.hostname.includes("songai-serviceq.aig.co.jp")) {
      configUrl = "assets/config/config-qa.json";
    } else if (location.hostname.includes("uat.cdpfnol.nprd.aig.com") || location.hostname.includes("songai-serviceu.aig.co.jp")) {
      configUrl = "assets/config/config-qa.json";
    } else if (location.hostname.includes("cdpfnol.aig.com")) {
      configUrl = "assets/config/config-prod.json";
    } else {
      configUrl = "assets/config/config-prod.json";
    }

    return "config.json";
  }
}
