import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/shared/config";
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor() { }
  
  dynaTrace(){
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.async = true;
      script.src = AppConfig['dynatraceURL'];
      script.type = "text/javascript";
      script.crossOrigin="anonymous"
      script.addEventListener("load", resolve);
      script.addEventListener("error", () => reject("Error loading script"));
      script.addEventListener("abort", () => reject("Script load aborted"));
      document.head.appendChild(script);
    });
  }

  addAnalyticsURL(lang: string): void | Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.async = true;
      let url= AppConfig[`${lang}AnalyticsURL`];
      script.setAttribute('src',url)
    //  script.src = AppConfig[`${lang}AnalyticsURL`];
      script.type = "text/javascript";
      script.addEventListener("load", resolve);
      script.addEventListener("error", () => reject("Error loading script"));
      script.addEventListener("abort", () => reject("Script load aborted"));
      document.head.appendChild(script);
    });
  }
}

