import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from "@angular/router";
import { ThankYouComponent } from './fnol/success/thank-you/thank-you.component';
import { ServiceUnavailableComponent } from "./fnol/error/service-unavailable/service-unavailable.component";
import { MaintenancePageComponent } from "./fnol/error/maintenance-page/maintenance-page.component";
const routes: Routes = [
  // un-authenticated routes
  {
    path: "cdp-lite",
    loadChildren: () => import("./fnol/cdp-lite/cdp-lite/cdp-lite.module").then((m) => m.CdpLiteModule),
  },
  { path: "success", component: ThankYouComponent },
  { path: "", redirectTo: "cdp-lite", pathMatch: "full" },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [ThankYouComponent, ServiceUnavailableComponent, MaintenancePageComponent],
  exports: [RouterModule],
})
export class FeaturesRoutingModule { }
