<header>

  <ng-container>
    <axis-navbar>
      <axis-navbar-logo>
        <img
          alt="AIG logo"
          src="assets/images/logos/aig-logo-blue.svg"
        />
        <span class="sr-only">American International Group, Inc.</span>
      </axis-navbar-logo>
      <axis-navbar-app-title>Claims Digital Portal</axis-navbar-app-title>

    </axis-navbar>

  </ng-container>



</header>
<modal-confirm #modalConfirm></modal-confirm>
<modal-contact #modalContact></modal-contact>
