<footer class="axis-footer">

  <div class="axis-content-wrapper">


    <div class="axis-container-fluid">
      <ul class="axis-list-inline">
        <li *ngFor="let country of footerLinks">
          <a
            class="_cursor-pointer"
            (click)="handleClick(country.link)"
          >{{country.label}}</a>
        </li>
        <!-- <li><a  href="https://www.aig.sg/privacy"
        target="_blank">Privacy</a></li>
      <li><a href="https://www.aig.sg/legal-notice"
        target="_blank">Legal Notice</a></li>
        <li><a href="supported.html"
          target="_blank">Recommended Software</a></li>

      <li><a  href="https://www.aig.sg/aboutus"
        target="_blank">aig.com</a></li> -->
      </ul>
      <p
        *ngIf="copyrightText"
        class="axis-footer-copyright"
      >
        {{ copyrightText }}
      </p>
      <p
        *ngIf="disclaimerText"
        class="axis-footer-copyright"
      >
        {{ disclaimerText }}
      </p>
    </div>


  </div>

</footer>
<!-- <axis-scroll2tb></axis-scroll2tb> -->
