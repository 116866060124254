import { Utils } from 'axis-ui-generator';
import { FNOL_STEPS, FNOL_STEPS_JP, addContainerMain, FNOL_STEPS_US, FNOL_STEPS_CAT, FNOL_COUNTRY, HEADINGMANAGERUS, HEADINGMANAGER, EXCLUDEINPDF_FIELDS, ADDSPACEINPDF_FIELDS, PRIVACY_POLICY, HEADINGMANAGERJP } from '../../config/fnol-constants';
import { FNOL_STEPS_SINGAPORE, CLAIM_TYPE_DATE_FIELDS } from '../../config/fnol-sg-constants';
import * as moment from 'moment';
import { Country } from '../model/country';
import { URL_PARAMETERS } from '../../config/urlParameters.constant';

export interface Step {
  current: string;
  next: string;
  prev: string;
  completedSlNo: number;
}

export interface currencyTranser {
  estimatedLossCurrency: string;
  estimatedCargoCurrency: string;
}

export class CommonUtils {
  public static isCountryAuOrNz = '';
  public static currencyObject: currencyTranser;
  public static isRouteAllowed: boolean = false;

  public static updateNextStep(step): Step {
    const currentStepIndex = step.current.slNo;
    if (currentStepIndex === 6) {
      step.next = undefined;
      step.prev = 5;
      return step;
    } else if (URL_PARAMETERS.TYPE === 'CAT' && currentStepIndex === 5) {
      step.next = undefined;
      step.prev = 4;
      return step;
    }
    let nextStep;
    const steps = URL_PARAMETERS.TYPE === 'CAT' ? FNOL_STEPS_CAT : FNOL_STEPS;

    for (const key in steps) {
      if (!key || !steps[key]) {
        continue;
      }
      if (steps[key].slNo === currentStepIndex + 1) {
        nextStep = steps[key];
        break;
      }
    }
    step.next = nextStep;
    return step;
  }

  public static updateNextStepUS(step): Step {
    const currentStepIndex = step.current.slNo;
    if (currentStepIndex === 5) {
      step.next = undefined;
      step.prev = 4;
      return step;
    }
    let nextStep;
    for (const key in FNOL_STEPS_US) {
      if (!key || !FNOL_STEPS_US[key]) {
        continue;
      }
      if (FNOL_STEPS_US[key].slNo === currentStepIndex + 1) {
        nextStep = FNOL_STEPS_US[key];
        break;
      }
    }
    step.next = nextStep;
    return step;
  }

  public static updateNextStepSG(step): Step {
    const currentStepIndex = step.current.slNo;
    if (currentStepIndex === 5) {
      step.next = undefined;
      step.prev = 4;
      return step;
    }
    let nextStep;
    for (const key in FNOL_STEPS_SINGAPORE) {
      if (!key || !FNOL_STEPS_SINGAPORE[key]) {
        continue;
      }
      if (FNOL_STEPS_SINGAPORE[key].slNo === currentStepIndex + 1) {
        nextStep = FNOL_STEPS_SINGAPORE[key];
        break;
      }
    }
    step.next = nextStep;
    return step;
  }

  public static getLabels(key): string {
    const gridObj = [
      { 'name': 'accountType', 'value': $localize`:@@accountType:Account Type` },
      { 'name': 'accountNumber', 'value': $localize`:@@accountNumber:Account Number` },
      { 'name': 'masterPolicyNumber', 'value': $localize`:@@numberPolicy:Policy Number` },
      { 'name': 'inceptionDate', 'value': $localize`:@@inceptionDate:Inception Date` },
      { 'name': 'expiryDate', 'value': $localize`:@@expiryDate:Expiry Date` },
      { 'name': 'productName', 'value': $localize`:@@productName:Product Name` },
      { 'name': 'lob', 'value': $localize`:@@lob:LOB` }
    ];
    return gridObj.filter(grid => grid.name === key).map(header => header.value).toString();
  }

  public static updatePrevStepUS(step): Step {
    const currentStepIndex = step.current.slNo;
    if (currentStepIndex === 0) {
      step.prev = undefined;
      return step;
    }
    let prevStep;
    for (const key in FNOL_STEPS_US) {
      if (!key || !FNOL_STEPS_US[key]) {
        continue;
      }
      if (FNOL_STEPS_US[key].slNo === currentStepIndex - 1) {
        prevStep = FNOL_STEPS_US[key];
        break;
      }
    }
    step.prev = prevStep;
    return step;
  }

  public static updatePrevStepSG(step): Step {
    const currentStepIndex = step.current.slNo;
    if (currentStepIndex === 0) {
      step.prev = undefined;
      return step;
    }
    let prevStep;
    for (const key in FNOL_STEPS_SINGAPORE) {
      if (!key || !FNOL_STEPS_SINGAPORE[key]) {
        continue;
      }
      if (FNOL_STEPS_SINGAPORE[key].slNo === currentStepIndex - 1) {
        prevStep = FNOL_STEPS_SINGAPORE[key];
        break;
      }
    }
    step.prev = prevStep;
    return step;
  }

  public static updatePrevStep(step): Step {
    const currentStepIndex = step.current.slNo;
    if (currentStepIndex === 0) {
      step.prev = undefined;
      return step;
    }
    let prevStep;

    const steps = URL_PARAMETERS.TYPE === 'CAT' ? FNOL_STEPS_CAT : FNOL_STEPS;
    for (const key in steps) {

      if (!key || !steps[key]) {
        continue;
      }
      if (steps[key].slNo === currentStepIndex - 1) {
        prevStep = steps[key];
        break;
      }
    }
    step.prev = prevStep;
    return step;
  }

  public static convertDateObj(step: string, data: any): void {
    switch (step) {
      case FNOL_STEPS.CLAIMANT_INFO.name:
        data.uiData.fromDate = data.uiData.fromDate ? new Date(data.uiData.fromDate) : null;
        data.uiData.toDate = data.uiData.toDate ? new Date(data.uiData.toDate) : null;
        data.uiData.dob = data.uiData.dob ? new Date(data.uiData.dob) : null;
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
          data.uiData.insuranceStart = data.uiData.insuranceStart ? new Date(data.uiData.insuranceStart) : null;
          data.uiData.insuranceExpiry = data.uiData.insuranceExpiry ? new Date(data.uiData.insuranceExpiry) : null;
        } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORENEW.countryCode) {
          data.uiData.dob = data.uiData.dob ? new Date(data.uiData.dob) : null;
        }
        break;
      case FNOL_STEPS.INCIDENT_DETAILS.name:
        data.uiData.tripIncidentDate = data.uiData.tripIncidentDate ? new Date(data.uiData.tripIncidentDate) : null;
        data.uiData.dateApplied = data.uiData.dateApplied ? new Date(data.uiData.dateApplied) : null;
        data.uiData.dateReceived = data.uiData.dateReceived ? new Date(data.uiData.dateReceived) : null;
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
          data.uiData.dateLoss = data.uiData.dateLoss ? new Date(data.uiData.dateLoss) : null;
        }
        break;
      case FNOL_STEPS.CLAIM_TYPE_DETAILS.name:
        data.uiData.dateAccident = data.uiData.dateAccident ? new Date(data.uiData.dateAccident) : null;
        data.uiData.timeIncident = data.uiData.timeIncident ? new Date(data.uiData.timeIncident) : '';
        data.uiData.firstVisit = data.uiData.firstVisit ? new Date(data.uiData.firstVisit) : null;
        data.uiData.timeIncident = data.uiData.timeIncident ? new Date(data.uiData.timeIncident) : '';
        data.uiData.timeAdmission = data.uiData.timeAdmission ? new Date(data.uiData.timeAdmission) : '';
        data.uiData.timeDischarge = data.uiData.timeDischarge ? new Date(data.uiData.timeDischarge) : '';
        data.uiData.localTimePersonal = data.uiData.localTimePersonal ? new Date(data.uiData.localTimePersonal) : '';
        data.uiData.originalArriveTime = data.uiData.originalArriveTime ? new Date(data.uiData.originalArriveTime) : '';
        data.uiData.actualArriveTime = data.uiData.actualArriveTime ? new Date(data.uiData.actualArriveTime) : '';
        data.uiData.timeLand = data.uiData.timeLand ? new Date(data.uiData.timeLand) : '';
        data.uiData.timeReturned = data.uiData.timeReturned ? new Date(data.uiData.timeReturned) : '';

        data.uiData.accidentDate = data.uiData.accidentDate ? new Date(data.uiData.accidentDate) : null;
        data.uiData.dateOfAdmission = data.uiData.dateOfAdmission ? new Date(data.uiData.dateOfAdmission) : null;
        data.uiData.dateOfDischarge = data.uiData.dateOfDischarge ? new Date(data.uiData.dateOfDischarge) : null;
        data.uiData.dateOfStart = data.uiData.dateOfStart ? new Date(data.uiData.dateOfStart) : null;
        data.uiData.dateOfEnd = data.uiData.dateOfEnd ? new Date(data.uiData.dateOfEnd) : null;
        data.uiData.dateOfExpected = data.uiData.dateOfExpected ? new Date(data.uiData.dateOfExpected) : null;
        data.uiData.dateOfDisability = data.uiData.dateOfDisability ? new Date(data.uiData.dateOfDisability) : null;
        data.uiData.dateOfDeath = data.uiData.dateOfDeath ? new Date(data.uiData.dateOfDeath) : null;
        data.uiData.dateSymptom = data.uiData.dateSymptom ? new Date(data.uiData.dateSymptom) : null;
        data.uiData.dateVisit = data.uiData.dateVisit ? new Date(data.uiData.dateVisit) : null;
        data.uiData.whenOccur = data.uiData.whenOccur ? new Date(data.uiData.whenOccur) : null;
        data.uiData.whenOccurSick = data.uiData.whenOccurSick ? new Date(data.uiData.whenOccurSick) : null;
        data.uiData.originalArriveDate = data.uiData.originalArriveDate ? new Date(data.uiData.originalArriveDate) : null;
        data.uiData.actualArriveDate = data.uiData.actualArriveDate ? new Date(data.uiData.actualArriveDate) : null;
        data.uiData.dateOfCancellation = data.uiData.dateOfCancellation ? new Date(data.uiData.dateOfCancellation) : null;
        data.uiData.landDate = data.uiData.landDate ? new Date(data.uiData.landDate) : null;
        data.uiData.returnedDate = data.uiData.returnedDate ? new Date(data.uiData.returnedDate) : null;
        data.uiData.damagedDate = data.uiData.damagedDate ? new Date(data.uiData.damagedDate) : null;
        data.uiData.stolenDate = data.uiData.stolenDate ? new Date(data.uiData.stolenDate) : null;
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
          data.uiData.arrivalDate = data.uiData.arrivalDate ? new Date(data.uiData.arrivalDate) : null;
          data.uiData.landingDate = data.uiData.landingDate ? new Date(data.uiData.landingDate) : null;
          data.uiData.inlandArrivalDate = data.uiData.inlandArrivalDate ? new Date(data.uiData.inlandArrivalDate) : null;
          data.uiData.inlandArrivalDateMTC = data.uiData.inlandArrivalDateMTC ? new Date(data.uiData.inlandArrivalDateMTC) : null;
          data.uiData.landingDateInland = data.uiData.landingDateInland ? new Date(data.uiData.landingDateInland) : null;
        } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORENEW.countryCode) {
          data.uiData.dateAccident = data.uiData.dateAccident ? new Date(data.uiData.dateAccident) : null;
          data.uiData.timeIncident = data.uiData.timeIncident ? new Date(data.uiData.timeIncident) : '';
          data.uiData.firstVisit = data.uiData.firstVisit ? new Date(data.uiData.firstVisit) : null;

          if (data.uiData.addTreatmentMainList.length > 1) {
            data.uiData.addTreatmentMainList.forEach((admissionData, index) => {
              if (index === 0) {
                data.uiData.dateOfAdmission = data.uiData.dateOfAdmission ? new Date(data.uiData.dateOfAdmission) : null;
                data.uiData.timeAdmission = data.uiData.timeAdmission ? new Date(data.uiData.timeAdmission) : '';
                data.uiData.dateOfDischarge = data.uiData.dateOfDischarge ? new Date(data.uiData.dateOfDischarge) : null;
                data.uiData.timeDischarge = data.uiData.timeDischarge ? new Date(data.uiData.timeDischarge) : '';
              } else {
                data.uiData['dateOfAdmission' + index] = admissionData['Date of Admission'] ? new Date(admissionData['Date of Admission']) : null;
                data.uiData['timeAdmission' + index] = admissionData['Time of Admission'] ? new Date(admissionData['Time of Admission']) : '';
                data.uiData['dateOfDischarge' + index] = admissionData['Date of Discharge'] ? new Date(admissionData['Date of Discharge']) : null;
                data.uiData['timeDischarge' + index] = admissionData['Time of Discharge'] ? new Date(admissionData['Time of Discharge']) : '';
              }
            });
          } else {
            data.uiData.dateOfAdmission = data.uiData.dateOfAdmission ? new Date(data.uiData.dateOfAdmission) : null;
            data.uiData.timeAdmission = data.uiData.timeAdmission ? new Date(data.uiData.timeAdmission) : '';
            data.uiData.dateOfDischarge = data.uiData.dateOfDischarge ? new Date(data.uiData.dateOfDischarge) : null;
            data.uiData.timeDischarge = data.uiData.timeDischarge ? new Date(data.uiData.timeDischarge) : '';
          }
          if (data.uiData.addLeaveMainList.length > 1) {
            data.uiData.addLeaveMainList.forEach((leaveData, index) => {
              if (index === 0) {
                data.uiData.dateOfStart = data.uiData.dateOfStart ? new Date(data.uiData.dateOfStart) : null;
                data.uiData.dateOfEnd = data.uiData.dateOfEnd ? new Date(data.uiData.dateOfEnd) : null;
              } else {
                data.uiData['dateOfStart' + index] = leaveData['Start of medical leave'] ? new Date(leaveData['Start of medical leave']) : null;
                data.uiData['dateOfEnd' + index] = leaveData['End of medical leave'] ? new Date(leaveData['End of medical leave']) : null;
              }
            });
          } else {
            data.uiData.dateOfStart = data.uiData.dateOfStart ? new Date(data.uiData.dateOfStart) : null;
            data.uiData.dateOfEnd = data.uiData.dateOfEnd ? new Date(data.uiData.dateOfEnd) : null;
          }
          if (data.uiData.addIncomeMainList.length > 1) {
            data.uiData.addIncomeMainList.forEach((illnessAdmissionData, index) => {
              if (index === 0) {
                data.uiData.dateOfAdmissionIllness = data.uiData.dateOfAdmissionIllness ? new Date(data.uiData.dateOfAdmissionIllness) : null;
                data.uiData.timeAdmissionIllness = data.uiData.timeAdmissionIllness ? new Date(data.uiData.timeAdmissionIllness) : '';
                data.uiData.dateOfDischargeIllness = data.uiData.dateOfDischargeIllness ? new Date(data.uiData.dateOfDischargeIllness) : null;
                data.uiData.timeDischargeIllness = data.uiData.timeDischargeIllness ? new Date(data.uiData.timeDischargeIllness) : '';
              } else {
                data.uiData['dateOfAdmissionIllness' + index] = illnessAdmissionData['Date of Admission'] ? new Date(illnessAdmissionData['Date of Admission']) : null;
                data.uiData['timeAdmissionIllness' + index] = illnessAdmissionData['Time of Admission'] ? new Date(illnessAdmissionData['Time of Admission']) : '';
                data.uiData['dateOfDischargeIllness' + index] = illnessAdmissionData['Date of Discharge'] ? new Date(illnessAdmissionData['Date of Discharge']) : null;
                data.uiData['timeDischargeIllness' + index] = illnessAdmissionData['Time of Discharge'] ? new Date(illnessAdmissionData['Time of Discharge']) : '';
              }
            });
          } else {
            data.uiData.dateOfAdmissionIllness = data.uiData.dateOfAdmissionIllness ? new Date(data.uiData.dateOfAdmissionIllness) : null;
            data.uiData.timeAdmissionIllness = data.uiData.timeAdmissionIllness ? new Date(data.uiData.timeAdmissionIllness) : '';
            data.uiData.dateOfDischargeIllness = data.uiData.dateOfDischargeIllness ? new Date(data.uiData.dateOfDischargeIllness) : null;
            data.uiData.timeDischargeIllness = data.uiData.timeDischargeIllness ? new Date(data.uiData.timeDischargeIllness) : '';
          }
          data.uiData.dateOfExpected = data.uiData.dateOfExpected ? new Date(data.uiData.dateOfExpected) : null;
          data.uiData.dateOfDisability = data.uiData.dateOfDisability ? new Date(data.uiData.dateOfDisability) : null;
          data.uiData.dateOfDeath = data.uiData.dateOfDeath ? new Date(data.uiData.dateOfDeath) : null;
          data.uiData.localTimePersonal = data.uiData.localTimePersonal ? new Date(data.uiData.localTimePersonal) : '';
          data.uiData.dateVisit = data.uiData.dateVisit ? new Date(data.uiData.dateVisit) : null;
          data.uiData.dateOfDiagnosed = data.uiData.dateOfDiagnosed ? new Date(data.uiData.dateOfDiagnosed) : null;
        }
        break;
    }
  }


  public static setCurrencyTransfer(currencyObj) {
    this.currencyObject = currencyObj;
  }

  public static commaFormatCurrency(money: string, countryCode: string, item?: string): string {
    const country = this.getCountryData(countryCode);
    const digitedAmount = this.decimalConverter(parseFloat(money), country.countryCode);
    if (country.countryCode === FNOL_COUNTRY.NAM.countryCode) {
      if (item === 'estimateLoss') {
        return digitedAmount + ' ' + this.currencyObject['estimatedLossCurrency'];
      } else if (item === 'estimateCargo') {
        return digitedAmount + ' ' + this.currencyObject['estimatedCargoCurrency'];
      }
    } else {
      return digitedAmount + ' ' + country.currencyCode;
    }
  }

  private static decimalConverter(num: number, locale: string): string {
    return num.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  public static setMaxDateValidation(response, step: string, country): void {
    if (this.isCountryAuOrNz) {
      switch (step) {
        case FNOL_STEPS.CLAIMANT_INFO.name:
          Utils.getFieldFromStructure(response, 'fromDate')['maxDate'] = new Date();
          Utils.getFieldFromStructure(response, 'toDate')['custom'] = true;
          break;
        case FNOL_STEPS.INCIDENT_DETAILS.name:
          Utils.getFieldFromStructure(response, 'tripIncidentDate')['maxDate'] = new Date();
          if (URL_PARAMETERS.TYPE !== 'CAT') {
            Utils.getFieldFromStructure(response, 'dateApplied')['maxDate'] = new Date();
            Utils.getFieldFromStructure(response, 'dateReceived')['maxDate'] = new Date();
          }
          break;
      }
    } else if (country === FNOL_COUNTRY.NAM.countryCode) {
      switch (step) {
        case FNOL_STEPS.INCIDENT_DETAILS.name:
          Utils.getFieldFromStructure(response, 'dateLoss')['maxDate'] = new Date();
          break;
        case FNOL_STEPS.CLAIM_TYPE_DETAILS.name:
          Utils.getFieldFromStructure(response, 'arrivalDate')['maxDate'] = new Date();
          Utils.getFieldFromStructure(response, 'landingDate')['maxDate'] = new Date();
          break;
      }
    } else if (country === FNOL_COUNTRY.JAPAN.countryCode) {
      switch (step) {
        case FNOL_STEPS_JP.CLAIMANT_INFO.name:
          Utils.getFieldFromStructure(response, 'dob')['maxDate'] = new Date();
          Utils.getFieldFromStructure(response, 'accidentDate')['maxDate'] = new Date();
          break;
        case FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name:

          break;
      }
    } else if (!this.isCountryAuOrNz && step === FNOL_STEPS.CLAIMANT_INFO.name) {
      Utils.getFieldFromStructure(response, 'dob')['maxDate'] = new Date();
    } else if (!this.isCountryAuOrNz && step === FNOL_STEPS.INCIDENT_DETAILS.name) {
      Utils.getFieldFromStructure(response, 'tripIncidentDate')['maxDate'] = new Date();
    } else if (country === FNOL_COUNTRY.SINGAPORE.countryCode && step === FNOL_STEPS.CLAIM_TYPE_DETAILS.name) {
      const futureDateBlock = ['dateAdmission', 'dateDischarge', 'startleave', 'endleave', 'dateDisability', 'visitDoctor', 'dod'];
      futureDateBlock.forEach(dateField => {
        Utils.getFieldFromStructure(response, dateField)['maxDate'] = new Date();
      });
    } else if (country === FNOL_COUNTRY.SINGAPORENEW.countryCode && step === FNOL_STEPS.CLAIM_TYPE_DETAILS.name) {
      CLAIM_TYPE_DATE_FIELDS.forEach(dateField => {
        Utils.getFieldFromStructure(response, dateField)['maxDate'] = new Date();
      });
    } else if (country === FNOL_COUNTRY.VIETNAM.countryCode && step === FNOL_STEPS.CLAIM_TYPE_DETAILS.name) {
      const futureDateBlock = ['visitDoctor', 'hospitalization', 'dod', 'dateDisability'];
      futureDateBlock.forEach(dateField => {
        Utils.getFieldFromStructure(response, dateField)['maxDate'] = new Date();
      });
    } else if (country === FNOL_COUNTRY.THAILAND.countryCode && step === FNOL_STEPS.CLAIM_TYPE_DETAILS.name) {
      const futureDateBlock = ['visitDoctor', 'hospitalization', 'dod'];
      futureDateBlock.forEach(dateField => {
        Utils.getFieldFromStructure(response, dateField)['maxDate'] = new Date();
      });
    }
  }


  public static isDisplayHeading(field: string, displayDataArr: Array<any>): boolean {
    let HM;
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
      HM = HEADINGMANAGERUS;
    } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
      HM = HEADINGMANAGERJP;
    } else {
      HM = HEADINGMANAGER;
    }
    if (HM[field]) {
      let selectedValue;
      for (const element of HM[field]) {
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode ||
          URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
          selectedValue = displayDataArr.filter(value => value.name === element)[0] ? displayDataArr.filter(value => value.name === element)[0].value : null;
        } else {
          selectedValue = displayDataArr.filter(value => value.name === element)[0].value;
        }
        if (selectedValue) {
          return true;
        } else { return false; }
      }
    } else {
      return false;
    }
  }

  public static preparePdfGenerationObj(submissionSummary): Array<any> {
    const pdfGenerationData = [];
    const policyObj = {
      title: 'Privacy Policy and Terms of Use',
      attribute: [{
        name: PRIVACY_POLICY.byContinue,
        value: 'Yes'
      },
      {
        name: PRIVACY_POLICY.byContinue1,
        value: ''
      },
      {
        name: PRIVACY_POLICY.byContinue2,
        value: ''
      }
      ]
    };
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
      policyObj.attribute.push(
        {
          name: PRIVACY_POLICY.byContinue4,
          value: ''
        }
      );
    } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode) {
      policyObj.attribute.push(
        {
          name: PRIVACY_POLICY.byContinue3_AU,
          value: ''
        }
      );
    } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NEWZEALAND.countryCode) {
      policyObj.attribute.push(
        {
          name: PRIVACY_POLICY.byContinue3_NZ,
          value: ''
        }
      );
    }

    pdfGenerationData.push(policyObj);
    submissionSummary.forEach(element => {
      if (element.displayData && element.displayData.length) {
        const bindObj = {
          title: element.section,
          attribute: []
        };
        element.displayData.forEach(item => {
          if (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.NAM.countryCode && item.name !== 'description' && this.isDisplayHeading(item.name, element.displayData)) {
            bindObj.attribute.push(
              {
                name: '',
                value: ''
              }
            );
          }
          if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode && item.name !== 'tpClaim' && this.isDisplayHeading(item.name, element.displayData)) {
            bindObj.attribute.push(
              {
                name: '',
                value: ''
              }
            );
          }
          if ((item.name === 'identify' || item.name === 'inlandIdentify' || item.name === 'cargoIdentify') && item.value) {
            bindObj.attribute.push(
              {
                name: 'Individual/Company:',
                value: item.value
              }
            );
          }
          if ((item.name === 'brokerName' && item.value === '') || (item.name === 'brokerBusinessName' && item.value === '') ||
            (item.name === 'brokerPrimary' && item.value['phoneNumber'] === "") || (item.name === 'brokerSecondary' && item.value['phoneNumber'] === "") ||
            (item.name === 'brokerEmail' && item.value === '')) {
            bindObj.attribute.push(
              {
                name: `${item.label}:`,
                value: 'Detail not provided'
              }
            );
          }
          if (item.value && item.label) {
            if (item.name === 'estimateLoss' || item.name === 'estimateCargo') {
              bindObj.attribute.push(
                {
                  name: `${item.label}:`,
                  value: this.commaFormatCurrency(item.value, URL_PARAMETERS.COUNTRY.toLowerCase(), item.name)
                }
              );
            } else if (item.name === 'submitting' || item.name === 'conveyance' || item.name === 'claimantCountry' ||
              item.name === 'cargoCountry' || item.name === 'inlandCountry' || item.name === 'inlandCountryMTC' ||
              item.name === 'lossLocationCountry') {
              bindObj.attribute.push(
                {
                  name: `${item.label}:`,
                  value: item.value.name
                }
              );
            } else if (item.name === 'cargoInlandLoss' && item.value === 'VPDMTC') {
              bindObj.attribute.push(
                {
                  name: `${item.label}:`,
                  value: 'VPD/MTC'
                }
              );
            } else if (item.name === 'cargoAddress1' || item.name === 'inlandAddress1' || item.name === 'inlandAddress1MTC' || item.name === 'claimantAddress1') {
              bindObj.attribute.push(
                {
                  name: `${item.label}:`,
                  value: item.value['name'] ? item.value['name'] : item.value
                }
              );
            } else if ((item.name === 'brokerPrimary' && item.value['phoneNumber'] !== "") ||
              (item.name === 'brokerSecondary' && item.value['phoneNumber'] !== "") ||
              (item.name === 'claimantPrimaryNumber') ||
              (item.name === 'claimantSecondaryNumber' && item.value['phoneNumber'] !== "") ||
              (item.name === 'cargoPrimaryNumber' && item.value['phoneNumber'] !== '') ||
              (item.name === 'cargoSecondaryNumber' && item.value['phoneNumber'] !== '') ||
              (item.name === 'cargoPhoneNumber' && item.value['phoneNumber'] !== '') ||
              (item.name === 'inlandPrimaryNumber' && item.value['phoneNumber'] !== '') ||
              (item.name === 'inlandSecondaryNumber' && item.value['phoneNumber'] !== '') ||
              (item.name === 'inlandPhoneNumber' && item.value['phoneNumber'] !== '') ||
              (item.name === 'inlandPhoneNumberMTC' && item.value['phoneNumber'] !== '')) {
              bindObj.attribute.push(
                {
                  name: `${item.label}:`,
                  value: item.value['phoneCode'] + '-' + item.value['phoneNumber']
                }
              );
            } else if ((item.name === 'brokerPrimary' && item.value['phoneNumber'] === "") ||
              (item.name === 'brokerSecondary' && item.value['phoneNumber'] === "") ||
              (item.name === 'claimantSecondaryNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'cargoPrimaryNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'cargoSecondaryNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'cargoPhoneNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'inlandPrimaryNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'inlandSecondaryNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'inlandPhoneNumber' && item.value['phoneNumber'] === '') ||
              (item.name === 'inlandPhoneNumberMTC' && item.value['phoneNumber'] === '')) {

            }
            else if (item.name === 'cargoIdentify' || item.name === 'inlandIdentify') {
              bindObj.attribute.push(
                {
                  name: 'Individual/Company:',
                  value: item.value
                }
              )
            } else {
              bindObj.attribute.push(
                {
                  name: `${item.label}:`,
                  value: item.value
                }
              );
            }
          }
        });
        pdfGenerationData.push(bindObj);
      }
    });
    return pdfGenerationData;
  }

  public static convertDateGMT(datePassed, dateFormat, zeroCheck?): string {
    const dateFormatted = moment(datePassed).format(dateFormat);
    if (zeroCheck && zeroCheck === 'zeroTime') {
      return dateFormatted + 'T000000.000 GMT';
    } else { return dateFormatted + 'T050000.000 GMT'; }
  }

  public static resetSelectedFields(eventData, fieldsToBeReset: string): void {
    if (fieldsToBeReset) {
      for (const element of fieldsToBeReset.split(',')) {
        if (eventData.form.model.controls[element]) {
          eventData.form.model.controls[element].reset();
          eventData.form.data.uiData[element] = '';
          eventData.form.model.controls[element].markAsUntouched();
        }
      }
    }
  }

  public static getCountryData(country: string): Country {
    for (const key in FNOL_COUNTRY) {
      if (!FNOL_COUNTRY.hasOwnProperty(key)) {
        continue;
      }
      const obj = FNOL_COUNTRY[key];
      for (const prop in obj) {
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }
        if (obj[prop] === country) {
          return obj;
        }
      }
    }
  }

  public static changeSequence(allFields) {
    const allFieldsArray = [];
    allFields.forEach(field => {
      allFieldsArray.push(field);
      if (field.name === 'country' || field.name === 'country1') {
        const insertIndex = allFieldsArray.map(obj => obj.type).lastIndexOf('addressLookup');
        allFieldsArray.splice(insertIndex, 0, field);
        allFieldsArray.pop();
      } else if (field.sequenceRef === 'Extension') {
        const insertIndex = allFieldsArray.map(obj => obj.inputType).lastIndexOf('email');
        const emailTemp = allFieldsArray[insertIndex];
        allFieldsArray.splice(insertIndex, 1);
        allFieldsArray.push(emailTemp);
      }
    });
    return allFieldsArray;
  }

  public static getBatchName(): string {
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORENEW.countryCode) {
      return '';
    } else {
      return moment().format('[AIG] MM[/]DD[/]YY h:mm:ssA');
    }
  }

  public static getPdfDataSequentialOrder(allFields, index, forms, countryCode): Array<any> {
    const country = this.getCountryData(countryCode);
    const sequentialArray = [];
    const sectionName = `mainSectionStep${index + 1}`;
    const updatedSequence = this.changeSequence(allFields);
    updatedSequence.forEach(field => {
      const sectionField = forms[sectionName][field.name];
      if (sectionField) {
        if (field.type === 'custom' && field.name === 'medicalExpense' &&
          URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.THAILAND.countryCode) {
          for (const element in sectionField) {
            if (sectionField[element]) {
              const labelObj = {
                name: '',
                value: this.commaFormatCurrency(sectionField[element], countryCode)
              };
              if (element === 'westernMedicine') {
                labelObj.name = $localize`:@@westernMedicine:Western Medicine:`;
              } else if (element === 'dentalTreatment') {
                labelObj.name = $localize`:@@dentalTreatment:Dental Treatment:`;
              } else if (element === 'chineseMedicine') {
                labelObj.name = $localize`:@@chineseMedicine:Traditional Chinese Medicine:`;
              } else if (element === 'sum') {
                labelObj.name = $localize`:@@sumAmount:Total Claim Amount:`;
              }
              sequentialArray.push(labelObj);
            }
          }
        } else if (field.name === 'claimName' && country.countryCode === FNOL_COUNTRY.VIETNAM.countryCode ||
          field.name === 'medicalExp' && country.countryCode === FNOL_COUNTRY.THAILAND.countryCode ||
          field.name === 'claimAmount' && country.countryCode !== FNOL_COUNTRY.SINGAPORE.countryCode) {
          const labelObj = {
            name: `${field.label}:`,
            value: this.commaFormatCurrency(sectionField, countryCode)
          };
          sequentialArray.push(labelObj);
        } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode && field.name === 'payeeName') {
          if (forms[sectionName]['paymentType'] === 'Electronic Bank Transfer') {
            const labelObj = {
              name: `${field.label}:`,
              value: sectionField
            };
            sequentialArray.push(labelObj);
          }
        } else if (field.type === 'custom' && field.name === 'customFileUpload') {
          for (let i = 0; i < sectionField.uploads.length; i++) {
            const labelObj = {
              name: $localize`:@@uploadName:Upload ` + (i + 1) + ':',
              value: sectionField.uploads[i].name
            };
            sequentialArray.push(labelObj);
          }
        } else if (field.type === 'addressLookup') {
          let lookupVal = sectionField;
          if (lookupVal.name) {
            lookupVal = lookupVal.name;
          } else if (lookupVal.autoCompleteAddress) {
            lookupVal = lookupVal.autoCompleteAddress.split(',')[0];
          }
          const labelObj = {
            name: `${field.label}:`,
            value: lookupVal
          };
          sequentialArray.push(labelObj);
        } else if (field.sequenceRef === 'Extension') {
          if (forms[sectionName]['phoneNumber'] ||
            (forms[sectionName]['phoneNumberBeneficiary'] && forms[sectionName].extensionBeneficiary && field.name === 'extensionBeneficiary') ||
            (forms[sectionName]['phoneNumberDoctor'] && forms[sectionName].extensionDoctor && field.name === 'extensionDoctor')) {
            const labelObj = {
              name: `${field.label}:`,
              value: sectionField
            };
            sequentialArray.push(labelObj);
          }
        } else {
          if (ADDSPACEINPDF_FIELDS.indexOf(field.name) > -1 &&
            (field.name !== 'country' || (field.name === 'country' && index === 0))) {
            sequentialArray.push({ name: '', value: '' });
            if (field.name === 'damageIncident') {
              sequentialArray.push({ name: '', value: '' });
            }
          }
          if (EXCLUDEINPDF_FIELDS.indexOf(field.name) === -1) {
            if (field.name === 'title') {
              const titleName = forms[sectionName]['title']['en-name'] ? forms[sectionName]['title']['en-name'] : forms[sectionName]['title']['name'];
              const titleAttrObj = {
                name: $localize`:@@name-step3:Name:`,
                value: `${titleName} ${forms[sectionName]['firstName']} ${forms[sectionName]['lastName']}`
              };
              sequentialArray.push(titleAttrObj);
            } else if (field.name === 'firstNameDoctor') {
              const labelObj = {
                name: $localize`:@@doctor-step3:Doctor's Name:`,
                value: `Dr ${forms[sectionName]['firstNameDoctor']} ${forms[sectionName]['lastNameDoctor']}`
              };
              sequentialArray.push(labelObj);
            } else if (field.name === 'firstNameBeneficiary') {
              const labelObj = {
                name: $localize`:@@Beneficiary-step3:Beneficiary Name:`,
                value: `${forms[sectionName]['firstNameBeneficiary']} ${forms[sectionName]['lastNameBeneficiary']}`
              };
              sequentialArray.push(labelObj);
            } else if (field.name === 'dod') {
              const dodAttrObj = {};
              if (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.THAILAND.countryCode) {
                dodAttrObj['name'] = $localize`:@@dodtod-step3:Date and Time of Death:`;
              } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode) {
                dodAttrObj['name'] = $localize`:@@dodtod-step3:Date and Time of Accident:`;
              }
              dodAttrObj['value'] = `${moment(forms[sectionName]['dod']).format('DD/MM/YYYY')} and ${moment(forms[sectionName]['tod']).format('hh:mm a')}`;
              sequentialArray.push(dodAttrObj);
            } else if (field.name === 'diagnosis' && (forms[sectionName]['diagnosis']['name'] === 'Others' || forms[sectionName]['diagnosis']['en-name'] === 'Others')) {
              const diagnosisAttrObj = {
                name: $localize`:@@diagnosis:What was the diagnosis?:`,
                value: forms[sectionName]['others']
              };
              sequentialArray.push(diagnosisAttrObj);
            } else if (field.name === 'causeDeath' && (forms[sectionName]['causeDeath']['name'] === 'Others' || forms[sectionName]['causeDeath']['en-name'] === 'Others')) {
              const causeDeathAttrObj = {
                name: $localize`:@@cuse-step3:Cause of Death:`,
                value: forms[sectionName]['others1']
              };
              sequentialArray.push(causeDeathAttrObj);
            } else if (field.name === 'permanent' && (forms[sectionName]['permanent']['name'] === 'Others' || forms[sectionName]['permanent']['en-name'] === 'Others')) {
              const permDisableAttrObj = {
                name: $localize`:@@perm-disable:Type of Permanent disability:`,
                value: forms[sectionName]['others2']
              };
              sequentialArray.push(permDisableAttrObj);
            } else {
              const commonAttrObj = {
                name: `${field.label}:`,
                value: null
              };
              if (field.type === 'dropdown') {
                commonAttrObj.value = sectionField['en-name'] ? sectionField['en-name'] : sectionField.name;
              } else if (field.type === 'datepicker') {
                commonAttrObj.value = moment(sectionField).format('DD/MM/YYYY');
              } else if (field.type === 'timepicker') {
                commonAttrObj.value = moment(sectionField).format('hh:mm a');
              } else if (field.contact && field.contact === 'contactNumber') {
                commonAttrObj.value = countryCode + ' ' + sectionField;
              } else if (field.type === 'radio') {
                commonAttrObj.value = (sectionField === 'yes' || sectionField === 'no') ?
                  sectionField.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase()) : sectionField;
              } else {
                commonAttrObj.value = sectionField;
              }
              sequentialArray.push(commonAttrObj);
            }
          }
        }
      }
    });
    return sequentialArray;
  }

  public static isNRICValid(enteredNric: string): boolean {
    enteredNric = enteredNric.trim();
    if (enteredNric?.length != 9)
      return false;

    enteredNric = enteredNric.toUpperCase();

    let i, icArray = [];
    for (i = 0; i < 9; i++) {
      icArray[i] = enteredNric.charAt(i);
    }

    icArray[1] = parseInt(icArray[1], 10) * 2;
    icArray[2] = parseInt(icArray[2], 10) * 7;
    icArray[3] = parseInt(icArray[3], 10) * 6;
    icArray[4] = parseInt(icArray[4], 10) * 5;
    icArray[5] = parseInt(icArray[5], 10) * 4;
    icArray[6] = parseInt(icArray[6], 10) * 3;
    icArray[7] = parseInt(icArray[7], 10) * 2;

    let weight = 0;
    for (i = 1; i < 8; i++) {
      weight += icArray[i];
    }

    const offset = (icArray[0] == "T" || icArray[0] == "G") ? 4 : (icArray[0] == "M") ? 3 : 0;
    const temp = (offset + weight) % 11;

    const st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
    const fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];
    const m = ["X", "W", "U", "T", "R", "Q", "P", "N", "J", "L", "K"];

    let theAlpha;
    if (icArray[0] == "S" || icArray[0] == "T") { theAlpha = st[temp]; }
    else if (icArray[0] == "F" || icArray[0] == "G") { theAlpha = fg[temp]; }
    else if (icArray[0] == "M") { theAlpha = m[temp]; }

    return (icArray[8] === theAlpha);
  }

  public static calculateAge(birthday): string {
    const ageDifMs = Date.now() - birthday;
    const ageDate = new Date(ageDifMs);
    return JSON.stringify(Math.abs(ageDate.getUTCFullYear() - 1970));
  }

  public static stringTrimmer(str): string {
    let nameValue = str.replace(/\s\s+/g, ' ').trim().toLowerCase();
    if (nameValue && nameValue.indexOf(' ') !== -1) {
      return nameValue.split(' ').map(w => w.substring(0, 1).toUpperCase() + w.substring(1)).join(' ');
    } else if (nameValue && nameValue.indexOf(' ') === -1) {
      return nameValue.charAt(0).toUpperCase() + nameValue.slice(1);
    } else {
      return '';
    }
  }

}
