import { URL_PARAMETERS } from './urlParameters.constant';
import { Country } from '../shared/model/country';
import { ErrorMessages } from 'axis-ui-generator';

interface FnolSteps {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsUS {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsCAT {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsJP {
  CLAIMANT_INFO: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}

interface FnolStepsObj {
  name: string;
  slNo: number;
  title: string;
  progressStepTitle: string;
}
export const NAME_DATE_LOSS = {
  AU: 'tripIncidentDate',
  US: 'dateLoss',
  SG: 'tripIncidentDate',
  NZ: 'tripIncidentDate',
};
export const FNOL_STEPS: FnolSteps = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Claimant Information`, progressStepTitle: `Claimant Information` },
  INCIDENT_DETAILS: { name: 'fnol-incident-details', slNo: 2, title: `Incident Details`, progressStepTitle: `Incident Details` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 3, title: `Claim Type Details`, progressStepTitle: `Claim Type Details` },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Payment Details`, progressStepTitle: `Payment Details` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 5, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 6, title: `Review and Submit the Claim`, progressStepTitle: `Declaration` }
};
export const FNOL_STEPS_CAT: FnolStepsCAT = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Claimant Information`, progressStepTitle: `Claimant Information` },
  INCIDENT_DETAILS: { name: 'fnol-incident-details', slNo: 2, title: `Incident Details`, progressStepTitle: `Incident Details` },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 3, title: `Payment Details`, progressStepTitle: `Payment Details` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 4, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Declaration` }
};

export const FNOL_STEPS_US: FnolStepsUS = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Policy & Claimant`, progressStepTitle: `Policy & Claimant` },
  INCIDENT_DETAILS: { name: 'fnol-incident-details', slNo: 2, title: `Incident Details`, progressStepTitle: `Incident Details` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 3, title: `Claim Type`, progressStepTitle: `Claim Type` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 4, title: ` Documents`, progressStepTitle: `Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Declaration` }
};

interface FnolCountry {
  SINGAPORE: Country;
  SINGAPORENEW: Country;
  THAILAND: Country;
  VIETNAM: Country;
  AUSTRALIA: Country;
  NEWZEALAND: Country;
  NAM: Country
  JAPAN: Country;
  LITE: Country;
}

export const profileCheck = {
  "zipCode": "postalCode",
  "lastNameKanji": "lastNameKanjiClaim",
  "firstNameKanji": "firstNameKanjiClaim",
  "lastNameKatakana": "lastNameKatakanaClaim",
  "firstNameKatakana": "firstNameKatakanaClaim",
  "prefecture": "prefecture",
  "addressline1": "condominium",
  "city": "city",
  "phoneNumber": "phone",
  "mobileNumber": "mobile",
  "emailId": "email"
}

export const addContainerMain = ['addHospitalizationMain',
  'addSurgeryMain',
  'addFixturesMain',
  'addDamageInjuryMain',
  'addInpatientMain',
  'addSurgeryIllnessMain',
  'addRadiationMain',
  'addDamageMain',
  'addTheftMain'];
export const FNOL_COUNTRY: FnolCountry = {
  SINGAPORE: { countryCode: 'sg', callingCode: '+65', currencyCode: 'SGD', currencyDescription: 'Singapore Dollar', language: 'en', selectedLiteType: '' },
  SINGAPORENEW: { countryCode: 'singapore', callingCode: '+65', currencyCode: 'SGD', currencyDescription: 'Singapore Dollar', language: 'en', selectedLiteType: '' },
  THAILAND: { countryCode: 'th', callingCode: '+66', currencyCode: 'THB', currencyDescription: 'Thai baht', language: 'th', selectedLiteType: '' },
  VIETNAM: { countryCode: 'vn', callingCode: '+84', currencyCode: 'VND', currencyDescription: 'Vietnamese dong', language: 'vn', selectedLiteType: '' },
  AUSTRALIA: { countryCode: 'au', callingCode: '+43', currencyCode: 'AUD', currencyDescription: 'Australian Dollar', language: 'en', selectedLiteType: '' },
  NEWZEALAND: { countryCode: 'nz', callingCode: '+64', currencyCode: 'NZD', currencyDescription: 'New Zealand Dollar', language: 'en', selectedLiteType: '' },
  NAM: { countryCode: 'us', callingCode: '+1', currencyCode: 'USD', currencyDescription: 'United States Dollar', language: 'en', selectedLiteType: '' },
  JAPAN: { countryCode: 'jp', callingCode: '+81', currencyCode: 'JPY', currencyDescription: 'Japanese yen', language: 'jp', lob: '8', selectedLiteType: '' },
  LITE: { countryCode: 'cdp-lite', callingCode: '+81', currencyCode: 'JPY', currencyDescription: 'Japanese yen', language: 'en', lob: '8', selectedLiteType: 'Other' }
};

export const PAYMENTCHANNEL = {
  us: "Check",
  ca: "Cheque",
  my: "Electronic Bank Transfer",
  au: "EFT Details & ITC Entitlements",
  nz: "EFT Details / Bank Account Details",
  ie: "Electronic Bank Transfer",
  za: "Electronic Bank Transfer",
  gb: "Electronic Bank Transfer"
}

export const LABELMAPPING = {
  location: "Location",
  PoBox: "Level, Unit Number, PO Box",
  city: "City",
  state: "State",
  postalCode: "Postal Code",
  FullName: "Full Name",
  fullName: "Full Name (Per Bank Account)",
  fullNameAsBank: 'Full Name (Per Bank Account)',
  IBANnumber: "IBAN Number",
  confirmIBAN: "Confirm IBAN Number",
  IDNumber: "ID Number",
  EmailAddress: "Email address",
  bankConfirmAccountNumber: "Confirm Bank Account Number",
  bankAccountNumber: "Bank Account Number",
  bankName: "Bank Name",
  AccountNumber: "Account Number",
  ConfirmAccountNumber: "Confirm Account Number",
  fullNamePayNow: 'Full Name (Per Bank Account)',
  NRICPayNow: 'NRIC/FIN ',
  mobileNoPayNow: 'Mobile Number',
  uenPayNow: 'UEN',
  nameOfAccount: 'Name of Account',
  bankNameETF: 'Bank Name',
  BSBNumber: 'BSB Number (6 digits in total)',
  registeredPayNow: "Registered PayNow",
  nameBankCode: "Bank Name/Bank Code",
  financialInstitution: 'Financial Institution Account Number (up to 9 digits only)',
  ownerStructure: 'Have you claimed or do you intend to claim an Input Tax Credit (ITC) in respect of the GST paid on the insurance premium for this policy?  ',
  claimInsured: 'If Yes, what percentage of the GST did you claim, or are you intending to claim? Insured ITC %',
  claimPercent: '%',
  creditNumber: 'Direct credit to NZ bank account. Please complete bank details and account number',
  accountHome: "Account Holder's Name ",
  bankSociety: "Name of Bank/Building Society",
  typeOfAccount: "Type of account",
  branchCode: "Branch Code",
  HKID: "HKID",
  name: "FPS Name",
  FPSPhoneNumber: "FPS Phone Number",
  FPSEmail: "FPS Email",
  FPSID: "FPS ID",
  bankCode: "Bank Code",
  streetAddress: "Street Address",
  fullNameAsPerBankAccount: "Full Name (Per Bank Account)"
}

export const DOUBLEBYTECONVERSIONFIELDS = {
  lastNameKatakana: true,
  firstNameKatakana: true,
  lastNameKatakanaClaim: true,
  firstNameKatakanaClaim: true,
  financialInstitution: true,
  branchName: true,
  accountHolder: true,
  accountNumberKatakana: true,
  lastKatakana: true,
  firstKatakana: true
};

export const ZENHANONLY = {
  "lastNameKanji": true,
  "firstNameKanji": true,
  "lastNameKanjiClaim": true,
  "firstNameKanjiClaim": true,
  "financialInstitution": true,
  "branchName": true,
  "accountHolder": true
};

export const KATAKANAONLY = {
  lastNameKatakana: true,
  firstNameKatakana: true,
  lastNameKatakanaClaim: true,
  firstNameKatakanaClaim: true,
};

export const ADDSPACEINPDF_FIELDS = ['country', 'visitDoctor', 'damageIncident', 'dod', 'dateDisability', 'insuranceCovering', 'damageIncident'];
export const EXCLUDEINPDF_FIELDS = ['firstName', 'lastNameDoctor', 'lastName', 'lastNameBeneficiary', 'tod',
  'others', 'others1', 'others2'];
export const APPLICATION_ID = 'EXTPOR-001';
export const DATE_FORMAT = 'dd/MM/yyyy';

export const FNOL_JSON = {
  FNOL_FEEDBACK: { name: 'fnol-feedback' }
};

export const FIELDNAMEWITHCOUNTRYLITE = ['selectedCountry', 'personalCountry', 'diagnosisIllness'];
export const CHECKBOXHEADINGLITETravel = [
  { key: 'claimingIncident', heading: 'I had a medical incident' },
  { key: 'claimingDelayed', heading: 'My flight was delayed' },
  { key: 'claimingTrip', heading: 'I had to cancel my trip or my trip was cancelled' },
  { key: 'claimingLost', heading: 'My bags / items were delayed / damaged / lost' },
  { key: 'claimingOther', heading: 'Other' }
]
export const CHECKBOXLITEPERSONAL = [
  { key: 'medicalExpenseClaim', heading: 'Accident Medical Reimbursement/ Daily Hospital Income/ Fracture or Dislocation' },
  { key: 'leaveBenefit', heading: 'Medical Leave Benefit' },
  { key: 'permanentDisability', heading: 'Permanent/Temporary Disablement' },
  { key: 'accidentalDeath', heading: 'Accidental Death' },
  { key: 'otherClaim', heading: 'Other' }
]
export const CHECKBOXLITEBAG = [
  { key: 'myBags', heading: 'My Bags' },
  { key: 'myItems', heading: 'My Items' }
]
export const CHECKBOXLITEINJURED = [
  { key: '', heading: '' },
  { key: '', heading: '' }
]
export const ADDRESS_FIELDS = ['Address1', 'Address2', 'State', 'City', 'PostalCode', 'Country'];
export const PHONENUMBERDETAILSLITE = {
  sg: { code: "+65", src: "assets/images/flags/sg.svg" },
  us: { code: "+1", src: "assets/images/flags/us.svg" },
}

export const DISPLAY_MESSAGES = {
  API_FAILURE: 'Document upload timed-out or failed. Please try again.',
  API_FAILURE_DEL: 'Failed to delete the document.',
  FEEDBACK_FAILURE: 'Failed to submit your feedback. Please try again.',
  ZEROBYTE_ERROR: 'Upload files must be greater than 0 bytes.',
  FILE_TYPE: 'The file format you submitted is not among the formats supported.',
  FILE_TYPE_NO: 'Filformatet du sendte inn er ikke blant formatene som støttes.',
  FILE_SIZE: $localize`Sorry that file size is too large. Please upload a file under 15 MB.`,
  FILE_TYPE_NORDICS: 'Please upload the file in the specified file format with a file size of 15MB or less.',
  FILE_TYPE_NORDICS_HLF: 'The file format you submitted is not among the formats supported.',
  UPLOAD_ALL_FILE_SIZE :'The upload total file size(s) should not exceed 15MB. Please try again.'
};


export const MARINE_FILE_ALLOWED_FORMAT =  [ 'DOC', 'DOCX',  'JPEG', 'JPG', 
  'PDF', 'PPT', 'PPTX', 'TIF', 'TIFF', 'PNG','BMP'];

export const FNOL_STEPS_JP: FnolStepsJP = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Policy & Claim Incident`, progressStepTitle: `Policy & Claim Incident` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim Type`, progressStepTitle: `Claim Type` },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 3, title: `Payment`, progressStepTitle: `Payment` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 4, title: `Documents`, progressStepTitle: `Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Declaration`, progressStepTitle: `Declaration` }
};

export const FNOL_STEPS_JP_FOLLOW_UP_CLAIM = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Policy & Claim Incident`, progressStepTitle: `Policy & Claim Incident` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim Type`, progressStepTitle: `Claim Type` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Declaration`, progressStepTitle: `Declaration` }
};

export const JP_DATA_CONTAINERS = ['addMedicalMain', 'addInstitutionMain', 'addInjuredMain', 'addHospitalizationMain', 'addInpatientMain', 'addDamageInjuryMain', 'addSurgeryMain', 'addSurgeryIllnessMain', 'addFixturesMain', 'addInsuranceMain', 'addRadiationMain', 'addTheftMain', 'addDamageMain'];

export const CUSTOMERRORMESSAGE = {
  incidentDate: $localize`Date of Incident cannot be greater than current date`,
  doctorVisitDate: $localize`"When did you visit the doctor?" must not be greater than current date`,
  hospitalizationDate: $localize`"Date of hospitalization" cannot be greater than current date`,
  dod: $localize`"Date and Time of Death" must not be greater than current date and time`,
  disabilityDate: $localize`"Date disability was diagnosed" cannot be greater than current date`,
  policyGreaterDate: $localize`"Period of Insurance from" cannot be greater than current date`,
  timeGreater: $localize`Time cannot be greater than the current time`,
  incidentTime: $localize`'Time of incident' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`,
  commencementTime: `'Passenger pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`,
  commencementTimeVnTh: $localize`'Passenger/Parcel/Food pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`,
  completionTimeBefore: $localize`'Trip Completion Time' is expected after 'Job Acceptance Time'`,
  jobAcceptanceTimeBefore: $localize`'Job Acceptance Time' is expected before 'Trip Completion Time'`,
  timeAdmissionGreater: $localize`"Time of Admission" cannot be greater than current date and time`,
  dischargeDateSame: $localize`"Date of Admission" and "Date of Discharge" cannot be same`,
  admissionDateGreater: $localize`"Date of Admission" is expected before "Date of discharge"`,
  dischargeDateLesser: $localize`"Date of Discharge" is expected after "Date of admission"`,
  medicalLeaveStartDateGreater: $localize`"Start of outpatient medical leave" is expected before "End of outpatient medical leave"`,
  medicalLeaveEndDateLesser: $localize`"End of outpatient medical leave" is expected after "Start of outpatient medical leave"`,
  medicalLeaveDatesSame: $localize`"Start of outpatient medical leave" and "End of outpatient medical leave" cannot be same`,
  timeDischarge: $localize`Time of Discharge cannot be greater than current date and time`,
  fromDate: $localize`Date cannot be greater than "Period of Insurance To"`,
  toDate: $localize`Date cannot be less than "Period of Insurance From"`,
  ageLesser: $localize`:@@age18:Insured can only be selected if current Insured age is equal or greater than 18 years old`,
  confirmMail: $localize`:@@confirmEmail:Email and Confirm mail should match.`,
  policyNumber: `A minimum character of 3 to a maximum 10 is expected. Please update your entry based on your policy record.`,
  serviceErrorMsg: $localize`Internal Server Error.Please try again`,
  policyInvalid: $localize`:@@policyInvalid:ご入力の内容が確認できませんでした。このお手続きをスキップするか、お手数ですがご確認の上もう一度ご入力ください。このお手続きをスキップして次へ進む`,
  doiGreater: $localize`:@@doiGreater:Date of Hospital Visit must be greater or equal to Date of Accident`,
  consultationGreater: $localize`:@@consultationGreater:Date of Hospital Visit must be greater or equal to Date of Accident`,
  illnessHospitalGreater: $localize`:@@illnessHospitalGreater:Date of Hospital Visit must be greater or equal to Admission Date`,
  purchaseDateGreater: $localize`:@@purchaseDateGreater:Purchase Date must occur on or before Date of Loss/Incident`,
  notificationDateGreater: $localize`:@@notificationDateGreater:Notification Date can not occur before Date of Loss/Incident`,
  accountSymbolInvalid: $localize`:@@accountSymbolInvalid:Account Symbol is expected to be exactly 5 characters including the first 1 and the last 0. `,
  illnessSurgeryGreater: $localize`:@@illnessSurgeryGreater:Day of Surgery must be greater or equal to Date of Initial Consultation`,
  illnessRadiationGreater: $localize`:@@illnessRadiationGreater:Radiation Start Date must be greater or equal to Date of Initial Consultation`,
  dateOfSurgeryGreater: $localize`:@@dateOfSurgeryGreater: Date Of Surgery cannot occur before Date of Incident/Loss`,
  dateOfSurgeryAdmissionGreater: $localize`:@@dateOfSurgeryAdmissionGreater: Date Of Surgery must occur after Admission or Treatment Date`,
  outPatientGreater: $localize`:@@outPatientGreater: Out Patient From cannot occur before Date of Incident/Loss`,
  fixingGreater: $localize`:@@fixingGreater: Fixing Date cannot occur before Date of Incident/Loss`,
  outPatientSame: $localize`"Outpatient Treatment From" and "Outpatient Treatment To" cannot be same`,
  outPatientEndDateLesser: $localize`:@@toOutpatient:Outpatient Treatment To must occur after Outpatient Treatment From`,
  outPatientStartDateGreater: $localize`:@@fromOutpatient:Outpatient Treatment From is expected before Outpatient Treatment To`,
  fixtureDateSame: $localize`"Fixing Date" and "Date Fixture Removed" cannot be same`,
  fixingDateGreater: $localize`Fixing Date is expected before Date Fixture Removed`,
  fixtureRemoveDateLesser: $localize`Date Fixture Removed must occur after Fixing Date`,
  radiationDateSame: $localize`"Radiation Start Date" and "Radiation End Date" cannot be same`,
  radiationStartDateGreater: $localize`Radiation Start Date is expected before Radiation End Date`,
  radiationEndDateLesser: $localize`Radiation End Date must occur after Radiation Start Date`,
};
export const Constants = {
  today: 'Today',
  clear: 'Clear'
}

export const IMG_ALLOWED_FORMAT = ['TIF', 'JPG', 'PDF', 'TIFF', 'JPEG'];
export const DATE_FORMAT_JPN = 'yyyy-MM-dd';

export const ContainerCountMap =
{
  'addInjuredMain': 3,
  'addMedicalMain': 3,
  'addHospitalizationMain': 3,
  'addSurgeryMain': 3,
  'addFixturesMain': 3,
  'addInsuranceMain': 3,
  'addInstitutionMain': 3,
  'addInpatientMain': 3,
  'addSurgeryIllnessMain': 3,
  'addRadiationMain': 3,
  'addDamageMain': 20,
  'addTheftMain': 20,
  'addDamageInjuryMain': 20
};

export const CONSCENT_DECLARATION = {
  conscentAll: 'I have read and agree to the declarations and authorisation:',
  declarationAll: 'By submitting this form, I have read and agree with the declarations and authorisation:',
  consent: $localize`:@@consentAIG:I consent to AIG collecting, using and disclosing personal information as set out in this notice. If I have provided or will provide information to AIG about any other individuals, I confirm that I am authorised to disclose his or her personal information to AIG and also to give this consent on both my and their behalf.`,
  declaration: 'I have read and agree to the declarations and authorisation. By submitting this form, I have read and agree with the declarations and authorisation:',
  provision: `I have read 'Claim Preparation Costs' provision`
};

export const HEADINGMANAGERUS = {
  submitting: ['submitting',
    'policyNumber',
    'policyCheck',
    'policyNameInsured',
    'insuranceStartInsured',
    'insuranceExpiryInsured'
  ],
  brokerName: ['brokerName',
    'brokerBusinessName',
    'brokerPrimary',
    'brokerPrimaryExtn',
    'brokerSecondary',
    'brokerEmail'],
  claimantFirstName: ['identify',
    'claimantFirstName',
    'claimantLastName',
    'claimantCompanyName',
    'claimantAddress1',
    'claimantAddress2',
    'claimantCity',
    'claimantState',
    'claimantPostalCode',
    'claimantCountry',
    'claimantPrimaryNumber',
    'claimantPrimaryNumberExtn',
    'claimantSecondaryNumber',
    'claimantEmail'],
  certificate: ['certificate',
    'dateLoss',
    'estimateLoss',
    'estimateCargo',
    'tpClaim',
    'location',
    'loss'
  ],
  lossLocationAddress1: [
    'lossLocationAddress1',
    'lossLocationAddress2',
    'lossLocationCity',
    'lossLocationState',
    'lossLocationPostalCode',
    'lossLocationCountry'
  ],
  claimBenefits: ['claimBenefits'],
  cargoLoss: ['cargoLoss',
    'cargoIdentify',
    'cargoFirstName',
    'cargoLastName',
    'cargoCompanyName',
    'cargoPrimaryNumber',
    'cargoPrimaryNumberExtn',
    'cargoSecondaryNumber',
    'cargoEmail'],
  cargoAddress1: [
    'cargoEntryAddress1',
    'cargoEntryAddress2',
    'cargoEntryCity',
    'cargoEntryState',
    'cargoEntryPostalCode',
    'cargoEntryCountry',
    'cargoAddress1',
    'cargoAddress2',
    'cargoCity',
    'cargoState',
    'cargoPostalCode',
    'cargoCountry',
    'cargoPhoneNumber',
    'cargoPhoneNumberExtn',
    'arrivalDate',
    'cargoAdditional'],
  conveyance: ['conveyance',
    'carrier',
    'vessel',
    'voyage',
    'descriptionCargo',
    'lading',
    'landingDate',
    'origin',
    'destination'],
  howManyVPD: ['howManyVPD'],
  vehicle: ['vehicle',
    'year',
    'make',
    'model',
    'vin',
    'unit'],
  vehicle2: ['vehicle2',
    'year2',
    'make2',
    'model2',
    'vin2',
    'unit2'],
  cargoInlandLoss: [
    'cargoInlandLoss',
    'inlandIdentify',
    'inlandFirstName',
    'inlandLastName',
    'inlandCompanyName',
    'inlandPrimaryNumber',
    'inlandPrimaryNumberExtn',
    'inlandSecondaryNumber',
    'inlandEmail'],
  vehicle2CRD: [
    'vehicle2CRD',
    'year2CRD',
    'make2CRD',
    'model2CRD',
    'unit2CRD'
  ],
  sameLocation: ['sameLocation'],
  inlandAddress1: [
    'inlandEntryAddress1',
    'inlandEntryAddress2',
    'inlandEntryCity',
    'inlandEntryState',
    'inlandEntryPostalCode',
    'inlandEntryCountry',
    'inlandAddress1',
    'inlandAddress2',
    'inlandCity',
    'inlandState',
    'inlandPostalCode',
    'inlandCountry',
    'inlandPhoneNumber',
    'inlandPhoneNumberExtn',
    'inlandArrivalDate',
    'inlandAdditional'],
  inlandAddress1MTC: [
    'inlandEntryAddress1MTC',
    'inlandEntryAddress2MTC',
    'inlandEntryCityMTC',
    'inlandEntryStateMTC',
    'inlandEntryPostalCodeMTC',
    'inlandEntryCountryMTC',
    'inlandAddress1MTC',
    'inlandAddress2MTC',
    'inlandCityMTC',
    'inlandStateMTC',
    'inlandPostalCodeMTC',
    'inlandCountryMTC',
    'inlandPhoneNumberMTC',
    'inlandPhoneNumberMTCExtn',
    'inlandArrivalDateMTC',
    'inlandAdditionalMTC'],
  mtcAvailable: ['mtcAvailable'],
  carrierInland: ['carrierInland',
    'ladingInland',
    'landingDateInland',
    'cargoProduct',
    'descriptionCargoInland',
    'originInland',
    'destinationInland']
};

export const HEADINGMANAGER = {
  firstName: ['firstName', 'lastName', 'contactPhone', 'contactEmail'],
  subsidiaries: ['subsidiaries', 'otherParties', 'parentCompany'],
  brokerName: ['brokerName', 'brokerBusinessName', 'brokerNumber', 'brokerEmail'],
  policyHolder: ['policyHolder', 'firstNameAccountant', 'lastNameAccountant', 'phoneAccountant', 'emailAccountant'],
  assist: ['assist', 'arising', 'detailsFinancial'],
  description: ['description'],
  premises: ['premises', 'caseDate'], // 'policy', 'closedWhole', 'insured', 'reClosed', 'exactDate'],
  policy: ['policy', 'confirmedCase'],
  closedWhole: ['closedWhole', 'durationDate'],
  insured: ['insured', 'partiallyClosed'],
  exactDate: ['exactDate'],
  insuredNotice: ['insuredNotice', 'confirmation'],
  restrictions: ['restrictions', 'writtenDoc'],
  infoAig: ['infoAig']
};

export const HEADINGMANAGERJP = {
  accidentDate: ['accidentDate',
    'accidentTime',
    'lossLocation'],
  lastNameKanji: ['lastNameKanji',
    'firstNameKanji',
    'lastNameKatakana',
    'firstNameKatakana',
    'dob'],
  relationshipInsured: ['relationshipInsured',
    'insuredOthers',
    'lastNameKanjiClaim',
    'firstNameKanjiClaim',
    'lastNameKatakanaClaim',
    'firstNameKatakanaClaim'],
  postalCode: ['postalCode1',
    'prefecture',
    'city',
    'condominium',
    'country',
    'phone',
    'mobile',
    'email'],

  claimBenefits: ['claimBenefits'],

  injuryCode: ['injuryCode',
    'injuryCause',
    'injuryStatus',
    'injuryDescription'],

  addInjuredMain: ['addInjuredMain'],

  addMedicalMain: ['addMedicalMain'],

  // treatment: [
  //   'treatment'],
  selectStatus: ['selectStatus'],
  addHospitalizationMain: ['addHospitalizationMain'],

  surgery: ['surgery'],
  addSurgeryMain: ['addSurgeryMain'],

  outpatient: ['outpatient'],
  outPatientTreatmentDate: ['outPatientTreatmentDate',
    'lastOutpatient',
    'hospitalVisits'],

  fixtures: ['fixtures'],
  addFixturesMain: ['addFixturesMain'],

  coverage: ['coverage'],
  outPocket: ['outPocket'],
  transportation: [
    'transportation',

    'distanceTraveled',
    'tollRoad'],
  expensesIncurred: ['expensesIncurred'],
  contracts: ['contracts'],
  addInsuranceMain: ['addInsuranceMain'],
  damagePE: ['damagePE'],

  damageLocationInjury: [
    "damageLocationInjury",
    "schoolManagementInjury"],

  addDamageInjuryMain: ['addDamageInjuryMain'],


  surgeryCause: ["surgeryCause",
    "symptoms",
    "occurrenceDate",
    "consultationDate"],

  addInstitutionMain: ['addInstitutionMain'],



  // deliveryAddress:["deliveryAddress",
  // "nameDestination",
  // "treatmentIllness"],

  illnessSelectStatus: [
    'illnessSelectStatus'],

  addInpatientMain: ['addInpatientMain'],
  diagnosed: ['diagnosed',
    'insuranceBenefits'],
  surgeryIllness: ['surgeryIllness'],

  addSurgeryIllnessMain: ['addSurgeryIllnessMain'],

  radiotherapy: ['radiotherapy'],

  addRadiationMain: ["addRadiationMain"],

  accidentDateTime: ["accidentDateTime",
    "tripIncidentTime",
    "damage",
    "damageLocation",
    "accidentSituation",
    "accidentDescription",
    "schoolManagement"],

  addDamageMain: ["addDamageMain"],

  addTheftMain: ['addTheftMain'],
  reported: ["reported",
    "receiptNumber",
    "notificationDate",
    "reportingParty"],


  account: ['account'],
  institutions: ['institutions'],
  financialInstitution: ['financialInstitution',
    'typeFinancial',
    'bankCode',
    'branchName',
    'accountType',
    'accountNumber',
    'accountHolder'],
  symbol: ['symbol',
    'number',
    'accountNumberKatakana']
};

export const UPLOADTEXT = {
  vn: {
    uploadText: 'Kéo và Thả tài liệu hoặc <u>Tìm</u> tài liệu',
    uploadButtonText: 'Tìm tài liệu'
  },
  th: {
    uploadText: 'ลากและวางหรือ<u>ค้นหา</u>ไฟล์',
    uploadButtonText: 'ค้นหาไฟล์'
  },
  sg: {
    uploadText:
      `<p>Drag and drop or <u>Browse</u> here to select the upload File.</p>
       <p data-color="gray" class="_text-small">Maximum file size: 25 MB</p>`
  },
  Lite: {
    uploadText:
      `<p>Drag and drop or <u>Browse</u> here to select the upload File.</p>
       <p data-color="gray" class="_text-small">Maximum file size for all uploads: 15 MB</p>`
  },
  uploadText:
    `<p>Drag and drop or <u>Browse</u> here to select the upload File.</p>
  <p data-color="gray" class="_text-small">Maximum supported file size: 25 MB</p>`,
  uploadTextJP: `Drag and drop, or Browse here to select the upload file.
  <p data-color="gray" class="_text-small _nmb">Supported file types: JPG, JPEG, TIF, TIFF, PDF and HEIC.</p>
  <p data-color="gray" class="_text-small">Maximum supported file size: 25 Mb</p>`,
  uploadTextJPLocalize: `ここにファイルをドラッグするか端末内からアップロードするファイルを選択してください
  <p data-color="gray" class="_text-small _nmb">対応するファイル形式：JPG, JPEG, TIF, TIFF, PDF, HEIC</p>
  <p data-color="gray" class="_text-small">ファイルサイズの上限：25 MB</p>`
};

export const PRIVACY_POLICY = {
  byContinue_US: 'By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use: ',
  byContinue: 'By ticking this box and making use of this form and website, I acknowledge: ',
  byContinue1: '(i) I have read the Privacy Policy and the Terms of Use;',
  byContinue2: '(ii) I assent and agree with the provisions found in the Privacy Policy and the Terms of Use; and',
  byContinue3_AU: '(iii) that AIG Australia may revise the Privacy Policy and Terms of Use by updating the same on this website and that, as and when I make use of this website, I am bound by any such revisions.',
  byContinue3_NZ: '(iii) that AIG New Zealand may revise the Privacy Policy and Terms of Use by updating the same on this website and that, as and when I make use of this website, I am bound by any such revisions.',
  byContinue4: '(iii) that AIG may revise the Privacy Policy and Terms of Use by updating the same on this website and that, as and when I make use of this website, I am bound by any such revisions.',
  byContinue_JP: $localize`:@@consentAIG:I consent to AIG collecting, using and disclosing personal information as set out in this notice. If I have provided or will provide information to AIG about any other individuals, I confirm that I am authorised to disclose his or her personal information to AIG and also to give this consent on both my and their behalf.`
};

export const DISPLAY_NAME_CHANGE_LIST_AU = ['firstName', 'lastName', 'contactPhone', 'contactEmail', 'brokerName', 'brokerBusinessName', 'brokerNumber', 'brokerEmail'];

export const DISPLAY_NAME_CHANGE_LIST_NAM = ['claimantFirstName', 'claimantLastName', 'claimantCompanyName', 'claimantAddress1', 'claimantAddress2', 'claimantCity', 'claimantState', 'claimantPostalCode', 'claimantCountry',
  'claimantPrimaryNumber', 'claimantPrimaryNumberExtn', 'claimantSecondaryNumber', 'claimantEmail', 'contactPhone', 'contactEmail', 'brokerName', 'brokerBusinessName', 'brokerPrimary', 'brokerPrimaryExtn', 'brokerSecondary', 'brokerEmail'];

export const NEW_DISPLAY_NAME_MAPPING_AU = {
  firstName: 'Policy Holder First Name',
  lastName: 'Policy Holder Last Name',
  contactPhone: 'Policy Holder Phone',
  contactEmail: 'Policy Holder Email',
  brokerName: 'Broker Name',
  brokerBusinessName: 'Broker Business Name',
  brokerNumber: 'Broker Phone',
  brokerEmail: 'Broker Email'
};

export const NEW_DISPLAY_NAME_MAPPING_NAM = {
  claimantFirstName: 'Claimant First Name',
  claimantLastName: 'Claimant Last Name',
  claimantCompanyName: 'Claimant Company Name',
  claimantAddress1: 'Claimant Address Line 1',
  claimantAddress2: 'Claimant Address Line 2',
  claimantCity: 'Claimant Town/City',
  claimantState: 'Claimant State/Province',
  claimantPostalCode: 'Claimant Zip/Postal Code',
  claimantCountry: 'Claimant Country',
  claimantPrimaryNumber: 'Claimant Primary Contact Number',
  claimantPrimaryNumberExtn: 'Claimant Primary Contact Number - Extension',
  claimantSecondaryNumber: 'Claimant Secondary Contact Number',
  claimantEmail: 'Claimant Email',
  brokerName: 'Broker Name',
  brokerBusinessName: 'Broker Business Name',
  brokerPrimary: 'Broker Primary Contact Number',
  brokerPrimaryExtn: 'Broker Primary Contact Number - Extension',
  brokerSecondary: 'Broker Secondary Contact Number',
  brokerEmail: 'Broker Email'
};

export class UtilsConstLabelsLocalization {

  public static getFnolSteps(): void {
    // this.stepTitleList[0].progressStepTitle = 'Policy/Claimant Information';
    if (URL_PARAMETERS?.COUNTRY?.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
      FNOL_STEPS_JP['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP['CLAIM_TYPE_DETAILS']['title'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP['CLAIM_TYPE_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP['PAYMENT_DETAILS']['title'] = $localize`:@@stpesLabel-1137120387899046074:Payment`;
      FNOL_STEPS_JP['PAYMENT_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-1137120387899046074:Payment`;
      FNOL_STEPS_JP['UPLOAD_DOCS']['progressStepTitle'] = $localize`:@@stpesLabel-6433493604936913919:Documents`;
      FNOL_STEPS_JP['UPLOAD_DOCS']['title'] = $localize`:@@stpesLabel-6433493604936913919:Documents`;
      FNOL_STEPS_JP['DECLARATION']['progressStepTitle'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;
      FNOL_STEPS_JP['DECLARATION']['title'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;

      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIM_TYPE_DETAILS']['title'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIM_TYPE_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['DECLARATION']['progressStepTitle'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['DECLARATION']['title'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;

    }
    if (URL_PARAMETERS?.COUNTRY?.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode ||
      URL_PARAMETERS?.COUNTRY?.toLowerCase() === FNOL_COUNTRY.NEWZEALAND.countryCode) {
      FNOL_STEPS['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-57960076916508864544:Policy/Claimant Information`;
      FNOL_STEPS['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-57960076916508864555:Policy and Claimant Information`;
    } else {
      FNOL_STEPS['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-5796007691650886453:Claimant Information`;
      FNOL_STEPS['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-5796007691650886453:Claimant Information`;
    }
    FNOL_STEPS['INCIDENT_DETAILS']['title'] = $localize`:@@stpesLabel-1309456754889132509:Incident Details`;
    FNOL_STEPS['INCIDENT_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-1309456754889132509:Incident Details`;
    FNOL_STEPS['CLAIM_TYPE_DETAILS']['title'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type Details`;
    FNOL_STEPS['CLAIM_TYPE_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type Details`;
    FNOL_STEPS['PAYMENT_DETAILS']['title'] = $localize`:@@stpesLabel-1137120387899046074:Payment Details`;
    FNOL_STEPS['PAYMENT_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-1137120387899046074:Payment Details`;
    FNOL_STEPS['UPLOAD_DOCS']['progressStepTitle'] = $localize`:@@stpesLabel-6433493604936913917:Upload Documents`;
    FNOL_STEPS['UPLOAD_DOCS']['title'] = $localize`:@@stpesLabel-6433493604936913919:Upload Supporting Documents`;
    FNOL_STEPS['DECLARATION']['progressStepTitle'] = $localize`:@@stpesLabel-4312716739160141243:Declaration`;
    FNOL_STEPS['DECLARATION']['title'] = $localize`:@@stpesLabel-4312716739160141249:Review and Submit the Claim`;
  }

  public static getCustomText(): void {
    PRIVACY_POLICY.byContinue_JP = $localize`:@@consentAIG:I consent to AIG collecting, using and disclosing personal information as set out in this notice. If I have provided or will provide information to AIG about any other individuals, I confirm that I am authorised to disclose his or her personal information to AIG and also to give this consent on both my and their behalf.`;
  }

  public static getDisplayMessages(): void {
    DISPLAY_MESSAGES.API_FAILURE_DEL = $localize`:@@deleteFail:Failed to delete the document.`
    DISPLAY_MESSAGES.API_FAILURE = $localize`:@@docFail:Document upload timed-out or failed. Please try again.`;
    DISPLAY_MESSAGES.FEEDBACK_FAILURE = $localize`:@@submitFeedback:Failed to submit your feedback. Please try again.`;
    DISPLAY_MESSAGES.ZEROBYTE_ERROR = $localize`:@@docBytes:Upload files must be greater than 0 bytes.`;
    DISPLAY_MESSAGES.FILE_TYPE = $localize`:@@docSize:Please upload the file in the specified file format with a file size of 10MB or less.`;
    DISPLAY_MESSAGES.FILE_TYPE_NORDICS = $localize`:@@docSize:Please upload the file in the specified file format with a file size of 25MB or less.`;
  }

  public static getClaimBenefitType(country: string, type?): Array<any> {
    let claimBenefitType = [];
    if (country === FNOL_COUNTRY.SINGAPORE.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death`
      },
      {
        id: '3',
        name: $localize`:@@permanentDisability:Permanent Disability`
      }];
    } else if (country === FNOL_COUNTRY.VIETNAM.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death`
      }, {
        id: '3',
        name: $localize`:@@permanentDisability:Permanent Disability`
      }, {
        id: '4',
        name: $localize`:@@DamageLoss:Damage or Loss of Property`
      }];
    } else if (country === FNOL_COUNTRY.THAILAND.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death / Dismemberment  / Loss of sight / Permanent Total Disability`
      }, {
        id: '3',
        name: $localize`:@@DamageLoss:Damage or Loss of Property`
      }];
    } else if (country === FNOL_COUNTRY.JAPAN.countryCode) {
      if (type === 'Personal Effects') {
        claimBenefitType = [
          {
            id: '9',
            code: 'DMG,DMG013,IR4P07',
            name: $localize`:@@PE1:Photo of damaged goods`
          },
          {
            id: '10',
            code: 'DMG,DMG011,IR4P08',
            name: $localize`:@@PE2:Repair quote`
          },
          {
            id: '11',
            code: 'DMG,DMG101,IDX040',
            name: $localize`:@@PE3:Nonrepairable certificate`
          },
          {
            id: '12',
            code: 'DMG,DMG101,IR4069',
            name: $localize`:@@PE4:Receipt or warranty when purchased damaged goods`
          },
          {
            id: '13',
            code: 'POL,POL011,4S1181',
            name: $localize`:@@PE5:Certificate of enrollment and certificate of accident (claimant needs to submit specific document 在籍確認兼事故証明書.pdf)`
          },
          {
            id: '14',
            code: 'COR,COR004,IR4118',
            name: $localize`:@@PE6:Theft report`
          },
          {
            id: '15',
            code: 'POL,POL012,IDX010',
            name: $localize`:@@PE7:Driver's License (without description of Honseki(permanent address))`
          },
          {
            id: '16',
            code: 'POL,POL012,IDX022',
            name: $localize`:@@PE8:Passport Number`
          },
          {
            id: '17',
            code: 'POL,POL011,IDX043',
            name: $localize`:@@PE9:National Pension Handbook`
          }
        ]
      } else {
        claimBenefitType = [{
          id: '1',
          code: 'MED,MED004,IDX004',
          name: $localize`:@@Illness1:Receipt`
        },
        {
          id: '2',
          code: 'MED,MED002,IDX002',
          name: $localize`:@@Illness2:Attending Physician's Statement`
        },
        {
          id: '3',
          code: 'MED,MED004,IR4043',
          name: $localize`:@@Illness3:Certificate of Discharge`
        },
        {
          id: '4',
          code: 'MED,MED001,IDX047',
          name: $localize`:@@Illness4:Medical Certificate`
        },
        {
          id: '23',
          code: 'MED,MED001,4SM-651',
          name: $localize`:@@Illness9:入通院申告書（病気）`
        },
        {
          id: '24',
          code: 'MED,MED001,4SP-631',
          name: $localize`:@@Illness10:入通院申告書（ケガ）`
        },
        {
          id: '5',
          code: 'MED,MED004,IDX034',
          name: $localize`:@@Illness5:Receipt for transportation`
        },
        {
          id: '6',
          code: 'POL,POL012,IDX010',
          name: $localize`:@@Illness6:Driver's License（without description of Honseki(permanent address)）`
        },
        {
          id: '7',
          code: 'POL,POL012,IDX022',
          name: $localize`:@@Illness7:Passport Number`
        },
        {
          id: '8',
          code: 'POL,POL011,IDX043',
          name: $localize`:@@Illness8:National Pension Handbook`
        },
        {
          id: '18',
          code: 'DMG,DMG013,IR4P07',
          name: $localize`:@@PE1:Photo of damaged goods`
        },
        {
          id: '19',
          code: 'DMG,DMG011,IR4P08',
          name: $localize`:@@PE2:Repair quote`
        },
        {
          id: '20',
          code: 'DMG,DMG101,IDX040',
          name: $localize`:@@PE3:Nonrepairable certificate`
        },
        {
          id: '21',
          code: 'DMG,DMG101,IR4069',
          name: $localize`:@@PE4:Receipt or warranty when purchased damaged goods`
        },
        {
          id: '22',
          code: 'POL,POL011,4S1181',
          name: $localize`:@@PE5:Certificate of enrollment and certificate of accident (claimant needs to submit specific document 在籍確認兼事故証明書.pdf)`
        },

        ]
      }

    }
    return claimBenefitType;
  }
  public static getFILE_SIZE(): void {
    DISPLAY_MESSAGES.FILE_SIZE = $localize`:@@customError-fileSize:Sorry that file size is too large. Please upload a file under 25 MB`;
  }

  public static getCustomErrorMessages(): void {
    CUSTOMERRORMESSAGE.incidentDate = $localize`:@@customError-incidentDate:Date of Incident cannot be greater than current date`;
    CUSTOMERRORMESSAGE.doctorVisitDate = $localize`:@@customError-doctorVisit:"When did you visit the doctor?" must not be greater than current date`;
    CUSTOMERRORMESSAGE.hospitalizationDate = $localize`:@@customError-hospitalizationDate:Date of hospitalization cannot be greater than current date`;
    CUSTOMERRORMESSAGE.dod = $localize`:@@customError-dod:Date and Time of Death must not be greater than current date and time`;
    CUSTOMERRORMESSAGE.disabilityDate = $localize`:@@customError-disabilityDate:"Date disability was diagnosed" cannot be greater than current date`;
    CUSTOMERRORMESSAGE.timeGreater = $localize`:@@customError-timeGreater:Time cannot be greater than the current time`;
    CUSTOMERRORMESSAGE.incidentTime = $localize`:@@customError-incidentTime:'Time of incident' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`;
    CUSTOMERRORMESSAGE.commencementTime = `'Passenger pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`;
    CUSTOMERRORMESSAGE.commencementTimeVnTh = $localize`:@@customError-commencementTimeVnTh:'Passenger/Parcel/Food pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`;
    // tslint:disable-next-line: align:@@customError
    CUSTOMERRORMESSAGE.completionTimeBefore = $localize`:@@customError-completionTimeBefore:'Trip Completion Time' is expected after 'Job Acceptance Time'`;
    CUSTOMERRORMESSAGE.jobAcceptanceTimeBefore = $localize`:@@customError-jobAcceptanceTimeBefore:Job Acceptance Time is expected before Trip Completion Time`;
    CUSTOMERRORMESSAGE.timeAdmissionGreater = $localize`:@@customError-timeAdmissionGreater:"Time of Admission" cannot be greater than current date and time`;
    CUSTOMERRORMESSAGE.dischargeDateSame = $localize`:@@customError-dischargeDateSame:"Date of Admission" and "Date of Discharge" cannot be same`;
    CUSTOMERRORMESSAGE.admissionDateGreater = $localize`:@@customError-admissionDateGreater:"Date of Admission" is expected before "Date of discharge"`;
    CUSTOMERRORMESSAGE.dischargeDateLesser = $localize`:@@customError-dischargeDateLesser:"Date of Discharge" is expected after "Date of admission"`;
    CUSTOMERRORMESSAGE.medicalLeaveStartDateGreater = $localize`:@@customError-medicalLeaveStartDateGreater:"Start of outpatient medical leave" is expected before "End of outpatient medical leave"`;
    CUSTOMERRORMESSAGE.medicalLeaveEndDateLesser = $localize`:@@customError-medicalLeaveEndDateLesser:"End of outpatient medical leave" is expected after "Start of outpatient medical leave"`;
    CUSTOMERRORMESSAGE.medicalLeaveDatesSame = $localize`:@@customError-medicalLeaveDatesSame:"Start of outpatient medical leave" and "End of outpatient medical leave" cannot be same`;
    CUSTOMERRORMESSAGE.timeDischarge = $localize`:@@customError-timeDischarge:Time of Discharge cannot be greater than current date and time`;
    CUSTOMERRORMESSAGE.toDate = $localize`:@@customError-toDate:Date cannot be less than Period of Insurance From`;
    CUSTOMERRORMESSAGE.doiGreater = $localize`:@@doiGreater:Date of Hospital Visit must be greater or equal to Date of Accident`;
    CUSTOMERRORMESSAGE.consultationGreater = $localize`:@@consultationGreater:Date of Hospital Visit must be greater or equal to Date of Accident`;
    CUSTOMERRORMESSAGE.illnessHospitalGreater = $localize`:@@illnessHospitalGreater:Date of Hospital Visit must be greater or equal to Admission Date`;
    CUSTOMERRORMESSAGE.purchaseDateGreater = $localize`:@@purchaseDateGreater:Purchase Date must occur on or before Date of Loss/Incident`;
    CUSTOMERRORMESSAGE.notificationDateGreater = $localize`:@@notificationDateGreater:Notification Date can not occur before Date of Loss/Incident`;
    CUSTOMERRORMESSAGE.accountSymbolInvalid = $localize`:@@accountSymbolInvalid:Account Symbol is expected to be exactly 5 characters including the first 1 and the last 0.`;
    CUSTOMERRORMESSAGE.illnessSurgeryGreater = $localize`:@@illnessSurgeryGreater:Day of Surgery must be greater or equal to Date of Initial Consultation`;
    CUSTOMERRORMESSAGE.illnessRadiationGreater = $localize`:@@illnessRadiationGreater:Radiation Start Date must be greater or equal to Date of Initial Consultation`;
    CUSTOMERRORMESSAGE.dateOfSurgeryGreater = $localize`:@@dateOfSurgeryGreater: Date Of Surgery cannot occur before Date of Incident/Loss`;
    CUSTOMERRORMESSAGE.dateOfSurgeryAdmissionGreater = $localize`:@@dateOfSurgeryAdmissionGreater: Date Of Surgery must occur after Admission or Treatment Date`,
      CUSTOMERRORMESSAGE.outPatientGreater = $localize`:@@outPatientGreater: Out Patient From cannot occur before Date of Incident/Loss`;
    CUSTOMERRORMESSAGE.fixingGreater = $localize`:@@fixingGreater: Fixing Date cannot occur before Date of Incident/Loss`;
    CUSTOMERRORMESSAGE.outPatientEndDateLesser = $localize`:@@toOutpatient:Outpatient Treatment To must occur after Outpatient Treatment From`;
    CUSTOMERRORMESSAGE.outPatientStartDateGreater = $localize`:@@fromOutpatient:Outpatient Treatment From is expected before Outpatient Treatment To`;
    CUSTOMERRORMESSAGE.outPatientSame = $localize`:@@sameOutpatient:"Outpatient Treatment From" and "Outpatient Treatment To" cannot be same`;
    CUSTOMERRORMESSAGE.policyInvalid = $localize`:@@policyInvalid:ご入力いただいた番号ではご契約が確認できませんでした。もう一度お試しいただくか、このお手続きをスキップする場合は、上記の「いいえ」を選択してください。`;
    CUSTOMERRORMESSAGE.radiationDateSame = $localize`:@@radiationSame:"Radiation Start Date" and "Radiation End Date" cannot be same`;
    CUSTOMERRORMESSAGE.admissionDateGreater = $localize`:@@admissionDate:Date of Admission is expected before Date of discharge`;
    CUSTOMERRORMESSAGE.dischargeDateLesser = $localize`:@@dischargeDate:Discharge Date must occur after Admission Date`;
    CUSTOMERRORMESSAGE.outPatientEndDateLesser = $localize`:@@toOutpatient:Outpatient Treatment To must occur after Outpatient Treatment From`;
    CUSTOMERRORMESSAGE.outPatientStartDateGreater = $localize`:@@fromOutpatient:Outpatient Treatment From is expected before Outpatient Treatment To`;
    CUSTOMERRORMESSAGE.outPatientSame = $localize`:@@sameOutpatient:"Outpatient Treatment From" and "Outpatient Treatment To" cannot be same`;
    CUSTOMERRORMESSAGE.fixtureDateSame = $localize`:@@fixtureSame:"Fixing Date" and "Date Fixture Removed" cannot be same`;
    CUSTOMERRORMESSAGE.fixingDateGreater = $localize`:@@dateFixing:Fixing Date is expected before Date Fixture Removed`;
    CUSTOMERRORMESSAGE.fixtureRemoveDateLesser = $localize`:@@removeFixing:Date Fixture Removed must occur after Fixing Date`;
    CUSTOMERRORMESSAGE.radiationStartDateGreater = $localize`:@@startRadiation:Radiation Start Date is expected before Radiation End Date`;
    CUSTOMERRORMESSAGE.radiationEndDateLesser = $localize`:@@endRadiation:Radiation End Date must occur after Radiation Start Date`;
    CUSTOMERRORMESSAGE.ageLesser = $localize`:@@age18:Insured can only be selected if current Insured age is equal or greater than 18 years old`;
    CUSTOMERRORMESSAGE.confirmMail = $localize`:@@confirmEmail:Email and Confirm mail should match.`;
    CUSTOMERRORMESSAGE.policyNumber = $localize`:@@minChar:A minimum character of 3 to a maximum 10 is expected. Please update your entry based on your policy record.`;
  }
}

export const ErrorMessagesShow = [
  { type: '1', value: '認証失敗' },
  { type: '2', value: 'トークンが当社の記録と一致しません。もう一度お試しください。' },
  { type: '3', value: '認証失敗' }
]

export const PHONELIST = [
  {
    "countryCode": "AD",
    "label": "+376",
    "src": "assets/images/flags/ad.svg"
  },
  {
    "countryCode": "AE",
    "label": "+971",
    "src": "assets/images/flags/ae.svg"
  },
  {
    "countryCode": "AF",
    "label": "+93",
    "src": "assets/images/flags/af.svg"
  },
  {
    "countryCode": "AG",
    "label": "+1268",
    "src": "assets/images/flags/ag.svg"
  },
  {
    "countryCode": "AI",
    "label": "+1264",
    "src": "assets/images/flags/ai.svg"
  },
  {
    "countryCode": "AL",
    "label": "+355",
    "src": "assets/images/flags/al.svg"
  },
  {
    "countryCode": "AM",
    "label": "+374",
    "src": "assets/images/flags/am.svg"
  },
  {
    "countryCode": "AO",
    "label": "+244",
    "src": "assets/images/flags/ao.svg"
  },
  {
    "countryCode": "AQ",
    "label": "+672",
    "src": "assets/images/flags/aq.svg"
  },
  {
    "countryCode": "AR",
    "label": "+54",
    "src": "assets/images/flags/ar.svg"
  },
  {
    "countryCode": "AS",
    "label": "+1684",
    "src": "assets/images/flags/as.svg"
  },
  {
    "countryCode": "AT",
    "label": "+43",
    "src": "assets/images/flags/at.svg"
  },
  {
    "countryCode": "AU",
    "label": "+61",
    "src": "assets/images/flags/au.svg"
  },
  {
    "countryCode": "AW",
    "label": "+297",
    "src": "assets/images/flags/aw.svg"
  },
  {
    "countryCode": "AX",
    "label": "+358",
    "src": "assets/images/flags/ax.svg"
  },
  {
    "countryCode": "AZ",
    "label": "+994",
    "src": "assets/images/flags/az.svg"
  },
  {
    "countryCode": "BA",
    "label": "+387",
    "src": "assets/images/flags/ba.svg"
  },
  {
    "countryCode": "BB",
    "label": "+1246",
    "src": "assets/images/flags/bb.svg"
  },
  {
    "countryCode": "BD",
    "label": "+880",
    "src": "assets/images/flags/bd.svg"
  },
  {
    "countryCode": "BE",
    "label": "+32",
    "src": "assets/images/flags/be.svg"
  },
  {
    "countryCode": "BF",
    "label": "+226",
    "src": "assets/images/flags/bf.svg"
  },
  {
    "countryCode": "BG",
    "label": "+359",
    "src": "assets/images/flags/bg.svg"
  },
  {
    "countryCode": "BH",
    "label": "+973",
    "src": "assets/images/flags/bh.svg"
  },
  {
    "countryCode": "BI",
    "label": "+257",
    "src": "assets/images/flags/bi.svg"
  },
  {
    "countryCode": "BJ",
    "label": "+229",
    "src": "assets/images/flags/bj.svg"
  },
  {
    "countryCode": "BL",
    "label": "+590",
    "src": "assets/images/flags/bl.svg"
  },
  {
    "countryCode": "BM",
    "label": "+1441",
    "src": "assets/images/flags/bm.svg"
  },
  {
    "countryCode": "BN",
    "label": "+673",
    "src": "assets/images/flags/bn.svg"
  },
  {
    "countryCode": "BO",
    "label": "+591",
    "src": "assets/images/flags/bo.svg"
  },
  {
    "countryCode": "BR",
    "label": "+55",
    "src": "assets/images/flags/br.svg"
  },
  {
    "countryCode": "BS",
    "label": "+1242",
    "src": "assets/images/flags/bs.svg"
  },
  {
    "countryCode": "BT",
    "label": "+975",
    "src": "assets/images/flags/bt.svg"
  },
  // {
  //     "countryCode": "BV",
  //     "label": "+47",
  //     "src": "assets/images/flags/bv.svg"
  // },
  {
    "countryCode": "BW",
    "label": "+267",
    "src": "assets/images/flags/bw.svg"
  },
  {
    "countryCode": "BY",
    "label": "+375",
    "src": "assets/images/flags/by.svg"
  },
  {
    "countryCode": "BZ",
    "label": "+501",
    "src": "assets/images/flags/bz.svg"
  },
  {
    "countryCode": "CA",
    "label": "+1",
    "src": "assets/images/flags/ca.svg"
  },
  {
    "countryCode": "CC",
    "label": "+61",
    "src": "assets/images/flags/cc.svg"
  },
  {
    "countryCode": "CD",
    "label": "+243",
    "src": "assets/images/flags/cd.svg"
  },
  {
    "countryCode": "CF",
    "label": "+236",
    "src": "assets/images/flags/cf.svg"
  },
  {
    "countryCode": "CG",
    "label": "+242",
    "src": "assets/images/flags/cg.svg"
  },
  {
    "countryCode": "CH",
    "label": "+41",
    "src": "assets/images/flags/ch.svg"
  },
  {
    "countryCode": "CI",
    "label": "+225",
    "src": "assets/images/flags/ci.svg"
  },
  {
    "countryCode": "CK",
    "label": "+682",
    "src": "assets/images/flags/ck.svg"
  },
  {
    "countryCode": "CL",
    "label": "+56",
    "src": "assets/images/flags/cl.svg"
  },
  {
    "countryCode": "CM",
    "label": "+237",
    "src": "assets/images/flags/cm.svg"
  },
  {
    "countryCode": "CN",
    "label": "+86",
    "src": "assets/images/flags/cn.svg"
  },
  {
    "countryCode": "CO",
    "label": "+57",
    "src": "assets/images/flags/co.svg"
  },
  {
    "countryCode": "CR",
    "label": "+506",
    "src": "assets/images/flags/cr.svg"
  },
  {
    "countryCode": "CU",
    "label": "+53",
    "src": "assets/images/flags/cu.svg"
  },
  {
    "countryCode": "CV",
    "label": "+238",
    "src": "assets/images/flags/cv.svg"
  },
  {
    "countryCode": "CX",
    "label": "+61",
    "src": "assets/images/flags/cx.svg"
  },
  {
    "countryCode": "CY",
    "label": "+357",
    "src": "assets/images/flags/cy.svg"
  },
  {
    "countryCode": "CZ",
    "label": "+420",
    "src": "assets/images/flags/cz.svg"
  },
  {
    "countryCode": "DE",
    "label": "+49",
    "src": "assets/images/flags/de.svg"
  },
  {
    "countryCode": "DJ",
    "label": "+253",
    "src": "assets/images/flags/dj.svg"
  },
  {
    "countryCode": "DK",
    "label": "+45",
    "src": "assets/images/flags/dk.svg"
  },
  {
    "countryCode": "DM",
    "label": "+1767",
    "src": "assets/images/flags/dm.svg"
  },
  {
    "countryCode": "DO",
    "label": "+1809",
    "src": "assets/images/flags/do.svg"
  },
  {
    "countryCode": "DZ",
    "label": "+213",
    "src": "assets/images/flags/dz.svg"
  },
  {
    "countryCode": "EC",
    "label": "+593",
    "src": "assets/images/flags/ec.svg"
  },
  {
    "countryCode": "EE",
    "label": "+372",
    "src": "assets/images/flags/ee.svg"
  },
  {
    "countryCode": "EG",
    "label": "+20",
    "src": "assets/images/flags/eg.svg"
  },
  {
    "countryCode": "EH",
    "label": "+212",
    "src": "assets/images/flags/eh.svg"
  },
  {
    "countryCode": "ER",
    "label": "+291",
    "src": "assets/images/flags/er.svg"
  },
  {
    "countryCode": "ES",
    "label": "+34",
    "src": "assets/images/flags/es.svg"
  },
  {
    "countryCode": "ET",
    "label": "+251",
    "src": "assets/images/flags/et.svg"
  },
  {
    "countryCode": "FI",
    "label": "+358",
    "src": "assets/images/flags/fi.svg"
  },
  {
    "countryCode": "FJ",
    "label": "+679",
    "src": "assets/images/flags/fj.svg"
  },
  {
    "countryCode": "FK",
    "label": "+500",
    "src": "assets/images/flags/fk.svg"
  },
  {
    "countryCode": "FM",
    "label": "+691",
    "src": "assets/images/flags/fm.svg"
  },
  {
    "countryCode": "FO",
    "label": "+298",
    "src": "assets/images/flags/fo.svg"
  },
  {
    "countryCode": "FR",
    "label": "+33",
    "src": "assets/images/flags/fr.svg"
  },
  {
    "countryCode": "GA",
    "label": "+241",
    "src": "assets/images/flags/ga.svg"
  },
  {
    "countryCode": "GB",
    "label": "+44",
    "src": "assets/images/flags/gb.svg"
  },
  {
    "countryCode": "GD",
    "label": "+1473",
    "src": "assets/images/flags/gd.svg"
  },
  {
    "countryCode": "GE",
    "label": "+995",
    "src": "assets/images/flags/ge.svg"
  },
  {
    "countryCode": "GF",
    "label": "+594",
    "src": "assets/images/flags/gf.svg"
  },
  {
    "countryCode": "GH",
    "label": "+233",
    "src": "assets/images/flags/gh.svg"
  },
  {
    "countryCode": "GI",
    "label": "+350",
    "src": "assets/images/flags/gi.svg"
  },
  {
    "countryCode": "GL",
    "label": "+299",
    "src": "assets/images/flags/gl.svg"
  },
  {
    "countryCode": "GM",
    "label": "+220",
    "src": "assets/images/flags/gm.svg"
  },
  {
    "countryCode": "GN",
    "label": "+224",
    "src": "assets/images/flags/gn.svg"
  },
  {
    "countryCode": "GP",
    "label": "+590",
    "src": "assets/images/flags/gp.svg"
  },
  {
    "countryCode": "GQ",
    "label": "+240",
    "src": "assets/images/flags/gq.svg"
  },
  {
    "countryCode": "GR",
    "label": "+30",
    "src": "assets/images/flags/gr.svg"
  },
  {
    "countryCode": "GT",
    "label": "+502",
    "src": "assets/images/flags/gt.svg"
  },
  {
    "countryCode": "GU",
    "label": "+1671",
    "src": "assets/images/flags/gt.svg"
  },
  {
    "countryCode": "GW",
    "label": "+245",
    "src": "assets/images/flags/gw.svg"
  },
  {
    "countryCode": "GY",
    "label": "+592",
    "src": "assets/images/flags/gy.svg"
  },
  {
    "countryCode": "HK",
    "label": "+852",
    "src": "assets/images/flags/hk.svg"
  },
  {
    "countryCode": "HN",
    "label": "+504",
    "src": "assets/images/flags/hn.svg"
  },
  {
    "countryCode": "HR",
    "label": "+385",
    "src": "assets/images/flags/hr.svg"
  },
  {
    "countryCode": "HT",
    "label": "+509",
    "src": "assets/images/flags/ht.svg"
  },
  {
    "countryCode": "HU",
    "label": "+36",
    "src": "assets/images/flags/hu.svg"
  },
  {
    "countryCode": "ID",
    "label": "+62",
    "src": "assets/images/flags/id.svg"
  },
  {
    "countryCode": "IE",
    "label": "+353",
    "src": "assets/images/flags/ie.svg"
  },
  {
    "countryCode": "IL",
    "label": "+972",
    "src": "assets/images/flags/il.svg"
  },
  {
    "countryCode": "IM",
    "label": "+44",
    "src": "assets/images/flags/im.svg"
  },
  {
    "countryCode": "IN",
    "label": "+91",
    "src": "assets/images/flags/in.svg"
  },
  {
    "countryCode": "IQ",
    "label": "+964",
    "src": "assets/images/flags/iq.svg"
  },
  {
    "countryCode": "IR",
    "label": "+98",
    "src": "assets/images/flags/ir.svg"
  },
  {
    "countryCode": "IS",
    "label": "+354",
    "src": "assets/images/flags/is.svg"
  },
  {
    "countryCode": "IT",
    "label": "+39",
    "src": "assets/images/flags/it.svg"
  },
  {
    "countryCode": "JM",
    "label": "+1876",
    "src": "assets/images/flags/jm.svg"
  },
  {
    "countryCode": "JO",
    "label": "+962",
    "src": "assets/images/flags/jo.svg"
  },
  {
    "countryCode": "JP",
    "label": "+81",
    "src": "assets/images/flags/jp.svg"
  },
  {
    "countryCode": "KE",
    "label": "+254",
    "src": "assets/images/flags/ke.svg"
  },
  {
    "countryCode": "KG",
    "label": "+996",
    "src": "assets/images/flags/kg.svg"
  },
  {
    "countryCode": "KH",
    "label": "+855",
    "src": "assets/images/flags/kh.svg"
  },
  {
    "countryCode": "KI",
    "label": "+686",
    "src": "assets/images/flags/ki.svg"
  },
  {
    "countryCode": "KM",
    "label": "+269",
    "src": "assets/images/flags/km.svg"
  },
  {
    "countryCode": "KN",
    "label": "+1869",
    "src": "assets/images/flags/kn.svg"
  },
  {
    "countryCode": "KP",
    "label": "+850",
    "src": "assets/images/flags/kp.svg"
  },
  {
    "countryCode": "KR",
    "label": "+82",
    "src": "assets/images/flags/kr.svg"
  },
  {
    "countryCode": "KW",
    "label": "+965",
    "src": "assets/images/flags/kw.svg"
  },
  {
    "countryCode": "KY",
    "label": "+1345",
    "src": "assets/images/flags/ky.svg"
  },
  {
    "countryCode": "KZ",
    "label": "+7",
    "src": "assets/images/flags/kz.svg"
  },
  {
    "countryCode": "LA",
    "label": "+856",
    "src": "assets/images/flags/la.svg"
  },
  {
    "countryCode": "LB",
    "label": "+961",
    "src": "assets/images/flags/lb.svg"
  },
  {
    "countryCode": "LC",
    "label": "+1758",
    "src": "assets/images/flags/lc.svg"
  },
  {
    "countryCode": "LI",
    "label": "+423",
    "src": "assets/images/flags/li.svg"
  },
  {
    "countryCode": "LK",
    "label": "+94",
    "src": "assets/images/flags/lk.svg"
  },
  {
    "countryCode": "LR",
    "label": "+231",
    "src": "assets/images/flags/lr.svg"
  },
  {
    "countryCode": "LS",
    "label": "+266",
    "src": "assets/images/flags/ls.svg"
  },
  {
    "countryCode": "LT",
    "label": "+370",
    "src": "assets/images/flags/lt.svg"
  },
  {
    "countryCode": "LU",
    "label": "+352",
    "src": "assets/images/flags/lu.svg"
  },
  {
    "countryCode": "LV",
    "label": "+371",
    "src": "assets/images/flags/lv.svg"
  },
  {
    "countryCode": "LY",
    "label": "+218",
    "src": "assets/images/flags/ly.svg"
  },
  {
    "countryCode": "MA",
    "label": "+212",
    "src": "assets/images/flags/ma.svg"
  },
  {
    "countryCode": "MC",
    "label": "+377",
    "src": "assets/images/flags/mc.svg"
  },
  {
    "countryCode": "MD",
    "label": "+373",
    "src": "assets/images/flags/md.svg"
  },
  {
    "countryCode": "ME",
    "label": "+382",
    "src": "assets/images/flags/me.svg"
  },
  {
    "countryCode": "MF",
    "label": "+1599",
    "src": "assets/images/flags/mf.svg"
  },
  {
    "countryCode": "MG",
    "label": "+261",
    "src": "assets/images/flags/mg.svg"
  },
  {
    "countryCode": "MH",
    "label": "+692",
    "src": "assets/images/flags/mh.svg"
  },
  {
    "countryCode": "MK",
    "label": "+389",
    "src": "assets/images/flags/mk.svg"
  },
  {
    "countryCode": "ML",
    "label": "+223",
    "src": "assets/images/flags/ml.svg"
  },
  {
    "countryCode": "MM",
    "label": "+95",
    "src": "assets/images/flags/mm.svg"
  },
  {
    "countryCode": "MN",
    "label": "+976",
    "src": "assets/images/flags/mn.svg"
  },
  {
    "countryCode": "MO",
    "label": "+853",
    "src": "assets/images/flags/mo.svg"
  },
  {
    "countryCode": "MP",
    "label": "+1670",
    "src": "assets/images/flags/mp.svg"
  },
  {
    "countryCode": "MQ",
    "label": "+596",
    "src": "assets/images/flags/mq.svg"
  },
  {
    "countryCode": "MR",
    "label": "+222",
    "src": "assets/images/flags/mr.svg"
  },
  {
    "countryCode": "MS",
    "label": "+1664",
    "src": "assets/images/flags/ms.svg"
  },
  {
    "countryCode": "MT",
    "label": "+356",
    "src": "assets/images/flags/mt.svg"
  },
  {
    "countryCode": "MU",
    "label": "+230",
    "src": "assets/images/flags/mu.svg"
  },
  {
    "countryCode": "MV",
    "label": "+960",
    "src": "assets/images/flags/mv.svg"
  },
  {
    "countryCode": "MW",
    "label": "+265",
    "src": "assets/images/flags/mw.svg"
  },
  {
    "countryCode": "MX",
    "label": "+52",
    "src": "assets/images/flags/mx.svg"
  },
  {
    "countryCode": "MY",
    "label": "+60",
    "src": "assets/images/flags/my.svg"
  },
  {
    "countryCode": "MZ",
    "label": "+258",
    "src": "assets/images/flags/mz.svg"
  },
  {
    "countryCode": "NA",
    "label": "+264",
    "src": "assets/images/flags/na.svg"
  },
  {
    "countryCode": "NC",
    "label": "+687",
    "src": "assets/images/flags/nc.svg"
  },
  {
    "countryCode": "NE",
    "label": "+227",
    "src": "assets/images/flags/ne.svg"
  },
  {
    "countryCode": "NF",
    "label": "+672",
    "src": "assets/images/flags/nf.svg"
  },
  {
    "countryCode": "NG",
    "label": "+234",
    "src": "assets/images/flags/ng.svg"
  },
  {
    "countryCode": "NI",
    "label": "+505",
    "src": "assets/images/flags/ni.svg"
  },
  {
    "countryCode": "NL",
    "label": "+31",
    "src": "assets/images/flags/nl.svg"
  },
  {
    "countryCode": "NO",
    "label": "+47",
    "src": "assets/images/flags/no.svg"
  },
  {
    "countryCode": "NP",
    "label": "+977",
    "src": "assets/images/flags/np.svg"
  },
  {
    "countryCode": "NR",
    "label": "+674",
    "src": "assets/images/flags/nr.svg"
  },
  {
    "countryCode": "NU",
    "label": "+683",
    "src": "assets/images/flags/nu.svg"
  },
  {
    "countryCode": "NZ",
    "label": "+64",
    "src": "assets/images/flags/nz.svg"
  },
  {
    "countryCode": "OM",
    "label": "+968",
    "src": "assets/images/flags/om.svg"
  },
  {
    "countryCode": "PA",
    "label": "+507",
    "src": "assets/images/flags/pa.svg"
  },
  {
    "countryCode": "PE",
    "label": "+51",
    "src": "assets/images/flags/pe.svg"
  },
  {
    "countryCode": "PF",
    "label": "+689",
    "src": "assets/images/flags/pf.svg"
  },
  {
    "countryCode": "PG",
    "label": "+675",
    "src": "assets/images/flags/pg.svg"
  },
  {
    "countryCode": "PH",
    "label": "+63",
    "src": "assets/images/flags/ph.svg"
  },
  {
    "countryCode": "PK",
    "label": "+92",
    "src": "assets/images/flags/pk.svg"
  },
  {
    "countryCode": "PL",
    "label": "+48",
    "src": "assets/images/flags/pl.svg"
  },
  {
    "countryCode": "PM",
    "label": "+508",
    "src": "assets/images/flags/pm.svg"
  },
  {
    "countryCode": "PN",
    "label": "+870",
    "src": "assets/images/flags/pn.svg"
  },
  {
    "countryCode": "PR",
    "label": "+1",
    "src": "assets/images/flags/pr.svg"
  },
  {
    "countryCode": "PS",
    "label": "+970",
    "src": "assets/images/flags/ps.svg"
  },
  {
    "countryCode": "PT",
    "label": "+351",
    "src": "assets/images/flags/pt.svg"
  },
  {
    "countryCode": "PW",
    "label": "+680",
    "src": "assets/images/flags/pw.svg"
  },
  {
    "countryCode": "PY",
    "label": "+595",
    "src": "assets/images/flags/py.svg"
  },
  {
    "countryCode": "QA",
    "label": "+974",
    "src": "assets/images/flags/qa.svg"
  },
  {
    "countryCode": "RO",
    "label": "+40",
    "src": "assets/images/flags/ro.svg"
  },
  {
    "countryCode": "RS",
    "label": "+381",
    "src": "assets/images/flags/rs.svg"
  },
  {
    "countryCode": "RU",
    "label": "+7",
    "src": "assets/images/flags/ru.svg"
  },
  {
    "countryCode": "RW",
    "label": "+250",
    "src": "assets/images/flags/rw.svg"
  },
  {
    "countryCode": "SA",
    "label": "+966",
    "src": "assets/images/flags/sa.svg"
  },
  {
    "countryCode": "SB",
    "label": "+677",
    "src": "assets/images/flags/sb.svg"
  },
  {
    "countryCode": "SC",
    "label": "+248",
    "src": "assets/images/flags/sc.svg"
  },
  {
    "countryCode": "SD",
    "label": "+249",
    "src": "assets/images/flags/sd.svg"
  },
  {
    "countryCode": "SE",
    "label": "+46",
    "src": "assets/images/flags/se.svg"
  },
  {
    "countryCode": "SG",
    "label": "+65",
    "src": "assets/images/flags/sg.svg"
  },
  {
    "countryCode": "SH",
    "label": "+290",
    "src": "assets/images/flags/sh.svg"
  },
  {
    "countryCode": "SI",
    "label": "+386",
    "src": "assets/images/flags/si.svg"
  },
  // {
  //     "countryCode": "SJ",
  //     "label": "+47",
  //     "src": "assets/images/flags/sj.svg"
  // },
  {
    "countryCode": "SK",
    "label": "+421",
    "src": "assets/images/flags/sk.svg"
  },
  {
    "countryCode": "SL",
    "label": "+232",
    "src": "assets/images/flags/sl.svg"
  },
  {
    "countryCode": "SM",
    "label": "+378",
    "src": "assets/images/flags/sm.svg"
  },
  {
    "countryCode": "SN",
    "label": "+221",
    "src": "assets/images/flags/sn.svg"
  },
  {
    "countryCode": "SO",
    "label": "+252",
    "src": "assets/images/flags/so.svg"
  },
  {
    "countryCode": "SR",
    "label": "+597",
    "src": "assets/images/flags/sr.svg"
  },
  {
    "countryCode": "ST",
    "label": "+239",
    "src": "assets/images/flags/st.svg"
  },
  {
    "countryCode": "SV",
    "label": "+503",
    "src": "assets/images/flags/sv.svg"
  },
  {
    "countryCode": "SY",
    "label": "+963",
    "src": "assets/images/flags/sy.svg"
  },
  {
    "countryCode": "SZ",
    "label": "+268",
    "src": "assets/images/flags/sz.svg"
  },
  {
    "countryCode": "TC",
    "label": "+1649",
    "src": "assets/images/flags/tc.svg"
  },
  {
    "countryCode": "TD",
    "label": "+235",
    "src": "assets/images/flags/td.svg"
  },
  {
    "countryCode": "TG",
    "label": "+228",
    "src": "assets/images/flags/tg.svg"
  },
  {
    "countryCode": "TH",
    "label": "+66",
    "src": "assets/images/flags/th.svg"
  },
  {
    "countryCode": "TJ",
    "label": "+992",
    "src": "assets/images/flags/tj.svg"
  },
  {
    "countryCode": "TK",
    "label": "+690",
    "src": "assets/images/flags/tk.svg"
  },
  {
    "countryCode": "TL",
    "label": "+670",
    "src": "assets/images/flags/tl.svg"
  },
  {
    "countryCode": "TM",
    "label": "+993",
    "src": "assets/images/flags/tm.svg"
  },
  {
    "countryCode": "TN",
    "label": "+216",
    "src": "assets/images/flags/tn.svg"
  },
  {
    "countryCode": "TO",
    "label": "+676",
    "src": "assets/images/flags/to.svg"
  },
  {
    "countryCode": "TR",
    "label": "+90",
    "src": "assets/images/flags/tr.svg"
  },
  {
    "countryCode": "TT",
    "label": "+1868",
    "src": "assets/images/flags/tt.svg"
  },
  {
    "countryCode": "TV",
    "label": "+688",
    "src": "assets/images/flags/tv.svg"
  },
  {
    "countryCode": "TW",
    "label": "+886",
    "src": "assets/images/flags/tw.svg"
  },
  {
    "countryCode": "TZ",
    "label": "+255",
    "src": "assets/images/flags/tz.svg"
  },
  {
    "countryCode": "UA",
    "label": "+380",
    "src": "assets/images/flags/ua.svg"
  },
  {
    "countryCode": "UG",
    "label": "+256",
    "src": "assets/images/flags/ug.svg"
  },
  {
    "countryCode": "US",
    "label": "+1",
    "src": "assets/images/flags/us.svg"
  },
  {
    "countryCode": "UY",
    "label": "+598",
    "src": "assets/images/flags/uy.svg"
  },
  {
    "countryCode": "UZ",
    "label": "+998",
    "src": "assets/images/flags/uz.svg"
  },
  {
    "countryCode": "VA",
    "label": "+39",
    "src": "assets/images/flags/va.svg"
  },
  {
    "countryCode": "VC",
    "label": "+1784",
    "src": "assets/images/flags/vc.svg"
  },
  {
    "countryCode": "VE",
    "label": "+58",
    "src": "assets/images/flags/ve.svg"
  },
  {
    "countryCode": "VG",
    "label": "+1284",
    "src": "assets/images/flags/vg.svg"
  },
  {
    "countryCode": "VI",
    "label": "+1340",
    "src": "assets/images/flags/vi.svg"
  },
  {
    "countryCode": "VN",
    "label": "+84",
    "src": "assets/images/flags/vn.svg"
  },
  {
    "countryCode": "VU",
    "label": "+678",
    "src": "assets/images/flags/vu.svg"
  },
  {
    "countryCode": "WF",
    "label": "+681",
    "src": "assets/images/flags/wf.svg"
  },
  {
    "countryCode": "WS",
    "label": "+685",
    "src": "assets/images/flags/ws.svg"
  },
  {
    "countryCode": "YE",
    "label": "+967",
    "src": "assets/images/flags/ye.svg"
  },
  {
    "countryCode": "YT",
    "label": "+262",
    "src": "assets/images/flags/yt.svg"
  },
  {
    "countryCode": "ZA",
    "label": "+27",
    "src": "assets/images/flags/za.svg"
  },
  {
    "countryCode": "ZM",
    "label": "+260",
    "src": "assets/images/flags/zm.svg"
  },
  {
    "countryCode": "ZW",
    "label": "+263",
    "src": "assets/images/flags/zw.svg"
  }
]

export const CURRENCY = [
  {
    "label": "DKK",
    "value": "Denmark Krone"
  },
  {
    "label": "EUR",
    "value": "Euro"
  },
  {
    "label": "NOK",
    "value": "Norway Krone"
  },
  {
    "label": "SEK",
    "value": "Sweden Krona"
  },
  {
    "label": "USD",
    "value": "United States Dollar"
  },
  {
    "label": "AED",
    "value": "Uae Dirham"
  },
  {
    "label": "AFN",
    "value": "Afghanistan Afghani"
  },
  {
    "label": "ALL",
    "value": "Albanian Lek"
  },
  {
    "label": "AMD",
    "value": "Armenian Dram"
  },
  {
    "label": "ANG",
    "value": "Netherlands Antillian Guilder"
  },
  {
    "label": "AOA",
    "value": "Angola Kwanza"
  },
  {
    "label": "ARS",
    "value": "Argentina - Peso"
  },
  {
    "label": "AUD",
    "value": "Australia Dollar"
  },
  {
    "label": "AWG",
    "value": "Aruba Guilder"
  },
  {
    "label": "AZN",
    "value": "Azerbaijan New Manat"
  },
  {
    "label": "BAM",
    "value": "Bosnia & Herzegovina Convertible Mark"
  },
  {
    "label": "BBD",
    "value": "Barbados Dollar"
  },
  {
    "label": "BDT",
    "value": "Bangladesh Taka"
  },
  {
    "label": "BGN",
    "value": "Bulgarian Lev"
  },
  {
    "label": "BHD",
    "value": "Bahrain Dinar"
  },
  {
    "label": "BIF",
    "value": "Burundi Franc"
  },
  {
    "label": "BMD",
    "value": "Bermuda Dollar"
  },
  {
    "label": "BND",
    "value": "Brunei Dollar"
  },
  {
    "label": "BOB",
    "value": "Bolivia - Boliviano"
  },
  {
    "label": "BRL",
    "value": "Brazilian Real"
  },
  {
    "label": "BSD",
    "value": "Bahamas Dollar"
  },
  {
    "label": "BWP",
    "value": "Botswana Pula"
  },
  {
    "label": "BYN",
    "value": "Belarussian Ruble"
  },
  {
    "label": "BZD",
    "value": "Belize Dollar"
  },
  {
    "label": "CAD",
    "value": "Canada Dollar"
  },
  {
    "label": "CDF",
    "value": "Congolese Franc"
  },
  {
    "label": "CHF",
    "value": "Switzerland Franc"
  },
  {
    "label": "CLP",
    "value": "Chile New Peso"
  },
  {
    "label": "CNY",
    "value": "China Ren Yuan"
  },
  {
    "label": "COP",
    "value": "Colombia Peso"
  },
  {
    "label": "CRC",
    "value": "Costa Rica Colon"
  },
  {
    "label": "CUP",
    "value": "Cuban Peso"
  },
  {
    "label": "CVE",
    "value": "Cape Verde Escudo "
  },
  {
    "label": "CZK",
    "value": "Czeck Rep. Koruna"
  },
  {
    "label": "DJF",
    "value": "Djibouti Franc"
  },
  {
    "label": "DOP",
    "value": "Dominican Rep Peso"
  },
  {
    "label": "DZD",
    "value": "Algeria Dinar"
  },
  {
    "label": "EGP",
    "value": "Egypt Pound"
  },
  {
    "label": "ERN",
    "value": "Nakfa"
  },
  {
    "label": "ETB",
    "value": "Ethiopia Birr"
  },
  {
    "label": "FJD",
    "value": "Fiji Dollars"
  },
  {
    "label": "FKP",
    "value": "Falkland Islands Pound "
  },
  {
    "label": "GBP",
    "value": "Great Britain Pound"
  },
  {
    "label": "GEL",
    "value": "Georgia Lari"
  },
  {
    "label": "GHS",
    "value": "Ghana Cedi"
  },
  {
    "label": "GIP",
    "value": "Gibraltar Pound"
  },
  {
    "label": "GMD",
    "value": "Gambia Dalasi"
  },
  {
    "label": "GNF",
    "value": "Guinea Franc"
  },
  {
    "label": "GTQ",
    "value": "Guatemala Quetzal"
  },
  {
    "label": "GYD",
    "value": "Guyana Dollar"
  },
  {
    "label": "HKD",
    "value": "Hong Kong Dollar"
  },
  {
    "label": "HNL",
    "value": "Honduras Lempira"
  },
  {
    "label": "HRK",
    "value": "Croatia Kuna"
  },

  {
    "label": "HTG",
    "value": "Haiti Gourde"
  },
  {
    "label": "HUF",
    "value": "Hungary forint"
  },
  {
    "label": "IDR",
    "value": "Indonesia Rupiah"
  },
  {
    "label": "ILS",
    "value": "Israel Shekel"
  },
  {
    "label": "INR",
    "value": "India Rupee"
  },
  {
    "label": "IQD",
    "value": "Iraq Dinar"
  },
  {
    "label": "IRR",
    "value": "Iran Rial"
  },
  {
    "label": "ISK",
    "value": "Iceland Krona-New"
  },
  {
    "label": "JMD",
    "value": "Jamaica Dollar"
  },
  {
    "label": "JOD",
    "value": "Jordan Dinar"
  },
  {
    "label": "JPY",
    "value": "Japan Yen"
  },
  {
    "label": "KES",
    "value": "Kenya Shilling"
  },
  {
    "label": "KGS",
    "value": "Som"
  },
  {
    "label": "KHR",
    "value": "Cambodia Riel"
  },
  {
    "label": "KMF",
    "value": "Comoro Franc"
  },
  {
    "label": "KPW",
    "value": "North Korean Won "
  },
  {
    "label": "KRW",
    "value": "Korea (South) Won"
  },
  {
    "label": "KWD",
    "value": "Kuwait Dinar"
  },
  {
    "label": "KYD",
    "value": "Cayman Isles. Dollar"
  },
  {
    "label": "KZT",
    "value": "Kazakhstan Tenge"
  },
  {
    "label": "LAK",
    "value": "Kip "
  },
  {
    "label": "LBP",
    "value": "Lebanon Pound"
  },
  {
    "label": "LKR",
    "value": "Sri Lanka Rupee"
  },
  {
    "label": "LRD",
    "value": "Liberian Dollar "
  },
  {
    "label": "LSL",
    "value": "Lesotho Loti"
  },
  {
    "label": "LTL",
    "value": "Lithuania Litas"
  },
  {
    "label": "LVL",
    "value": "Latvia Lats"
  },
  {
    "label": "LYD",
    "value": "Libya Dinar"
  },
  {
    "label": "MAD",
    "value": "Morocco Dirham"
  },
  {
    "label": "MDL",
    "value": "Moldovan Leu"
  },
  {
    "label": "MGA",
    "value": "Madagascar Ariary"
  },

  {
    "label": "MKD",
    "value": "Denar"
  },
  {
    "label": "MMK",
    "value": "Burma Kyat"
  },
  {
    "label": "MNT",
    "value": "Mongolian Tugrik"
  },
  {
    "label": "MOP",
    "value": "Macao Pataca"
  },
  {
    "label": "MRO",
    "value": "Mauritania Ouguiya"
  },
  {
    "label": "MUR",
    "value": "Mauritius Rupee"
  },
  {
    "label": "MVR",
    "value": "Maldivian Rufiyaa"
  },
  {
    "label": "MWK",
    "value": "Malawi Kwacha"
  },
  {
    "label": "MXN",
    "value": "New Mexican Peso"
  },
  {
    "label": "MYR",
    "value": "Malaysia Ringgit"
  },
  {
    "label": "MZN",
    "value": "Mozambique New Metical"
  },
  {
    "label": "NAD",
    "value": "Namibia Dollar"
  },
  {
    "label": "NGN",
    "value": "Nigeria Naira"
  },
  {
    "label": "NIO",
    "value": "Nicaraguan Cordoba"
  },
  {
    "label": "NPR",
    "value": "Nepal Rupee"
  },
  {
    "label": "NZD",
    "value": "New Zealand Dollar"
  },
  {
    "label": "OMR",
    "value": "Oman Rial"
  },
  {
    "label": "PAB",
    "value": "Panama Balboa"
  },
  {
    "label": "PEN",
    "value": "Peru New Sol"
  },
  {
    "label": "PGK",
    "value": "Papua New G'Nea Kina"
  },
  {
    "label": "PHP",
    "value": "Philippines Peso"
  },
  {
    "label": "PKR",
    "value": "Pakistan Rupee"
  },
  {
    "label": "PLN",
    "value": "Poland New Zloty"
  },
  {
    "label": "PYG",
    "value": "Paraguay Guarani"
  },
  {
    "label": "QAR",
    "value": "Qatar Riyal"
  },
  {
    "label": "RON",
    "value": "Romania Leu New"
  },
  {
    "label": "RSD",
    "value": "Serbia Dinar"
  },
  {
    "label": "RUB",
    "value": "Russian Ruble"
  },
  {
    "label": "RWF",
    "value": "Rwanda Franc"
  },
  {
    "label": "SAR",
    "value": "Saudi Arabia Riyal"
  },
  {
    "label": "SBD",
    "value": "Solomon Islands"
  },
  {
    "label": "SCR",
    "value": "Seychelles Rupee"
  },
  {
    "label": "SDG",
    "value": "Sudanese Pound "
  },
  {
    "label": "SGD",
    "value": "Singapore Dollar"
  },
  {
    "label": "SHP",
    "value": "Saint Helena Pound "
  },
  {
    "label": "SLL",
    "value": "Sierra Leone Leone"
  },
  {
    "label": "SOS",
    "value": "Somalia Shilling"
  },
  {
    "label": "SRD",
    "value": "Surinam Dollar"
  },
  {
    "label": "STD",
    "value": "Sao Tome Dobra"
  },
  {
    "label": "SVC",
    "value": "El Salvador Colon"
  },
  {
    "label": "SYP",
    "value": "Syria Pound"
  },
  {
    "label": "SZL",
    "value": "Swaziland Emalangeni"
  },
  {
    "label": "THB",
    "value": "Thailand Baht"
  },
  {
    "label": "TJS",
    "value": "Somoni"
  },
  {
    "label": "TMT",
    "value": "Turkmenistan New Manat"
  },
  {
    "label": "TND",
    "value": "Tunisia Dinar"
  },
  {
    "label": "TOP",
    "value": "Tongan Pa'anga "
  },
  {
    "label": "TRY",
    "value": "Turkish Lire New"
  },
  {
    "label": "TTD",
    "value": "Trinidad-Tobago Dollar"
  },
  {
    "label": "TWD",
    "value": "Taiwan N.T. Dollar"
  },
  {
    "label": "TZS",
    "value": "Tanzania Shilling"
  },
  {
    "label": "UAH",
    "value": "Ukraine Hryvna"
  },
  {
    "label": "UGX",
    "value": "Uganda Shilling"
  },
  {
    "label": "UYU",
    "value": "Uruguay - New Peso"
  },
  {
    "label": "UZS",
    "value": "Uzbekistan Sum"
  },
  {
    "label": "VEF",
    "value": "Venezuela Bolivar Fuerte"
  },
  {
    "label": "VND",
    "value": "Vietnam Dong"
  },
  {
    "label": "VUV",
    "value": "Vanuatu Vatu"
  },
  {
    "label": "WST",
    "value": "Western Samoa Tala"
  },
  {
    "label": "XAF",
    "value": "Cfa Franc Beac / Senegal Franc"
  },
  {
    "label": "XCD",
    "value": "East Caribbean Dollar"
  },
  {
    "label": "XKX",
    "value": "Kosovo"
  },
  {
    "label": "XOF",
    "value": "CFA Franc BCEAO"
  },
  {
    "label": "XPF",
    "value": "CFP Franc"
  },
  {
    "label": "YER",
    "value": "Yemen Rial (Rep of )"
  },
  {
    "label": "ZAR",
    "value": "South Africa Rand"
  },
  {
    "label": "ZMW",
    "value": "Zambia Kwacha"
  },
  {
    "label": "ZWL",
    "value": "Zimbabwe Dollar"
  }

]

export const CURRENCYLITE = [
  {
    "label": "$",
    "value": "Singapore"
  }
]
