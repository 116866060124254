import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FeaturesRoutingModule } from "./features-routing.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpsRequestInterceptor } from "../shared/utils/http-request-interceptor";
import { FnolService } from "./fnol/services/fnol.service";
import { DataResolver } from "./fnol/services/data-resolver.service";
@NgModule({
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    GoogleMapsModule
  ],
  declarations: [],
  exports: [],
  providers: [
    FnolService, DataResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
  ],
})
export class FeaturesModule { }
