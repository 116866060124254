/**
 * This shared module contains all the axis-components, axis-charts, and axis-abc components modules.
 * You can comment/uncomment the modules whichever you want in your project.
 * Make sure when you uncomment any imports, that dependency should be present/install in node_modules.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


// AXIS COMPONENTS
import { AxisComponentsAllModule } from 'axis-components';
// import { AccordionModule } from 'axis-components';
// import { AlertModule } from 'axis-components';
// import { AQIModule } from 'axis-components';
// import { ButtonsModule } from 'axis-components';
// import { CardModule } from 'axis-components';
// import { CalendarModule } from 'axis-components';
// import { CarouselModule } from 'axis-components';
// import { CheckboxModule } from 'axis-components';
// import { ChipListModule } from 'axis-components';
// import { CountModule } from 'axis-components';
// import { DropdownModule } from 'axis-components';
// import { EditorModule } from 'axis-components/editor';
// import { DatepickerModule } from 'axis-components';
// import { DualListModule } from 'axis-components';
// import { FileUploadModule } from 'axis-components';
import { IconModule } from 'axis-components';
// import { InputModule } from 'axis-components';
// import { ListModule } from 'axis-components';
// import { ModalModule } from 'axis-components';
// import { LoadingModule } from 'axis-components';
// import { NavbarModule } from 'axis-components';
// import { ProgressBarModule } from 'axis-components';
import { RadioButtonModule } from 'axis-components';
// import { TimepickerModule } from 'axis-components';
// import { Scroll2tbModule } from 'axis-components';
// import { SliderModule } from 'axis-components';
// import { TabsModule } from 'axis-components';
// import { TooltipModule } from 'axis-components';
// import { TruncateModule } from 'axis-components';
// import { IntroTourModule } from 'axis-components';
// import { UtilsModule } from 'axis-components';


// AXIS CHARTS
import { AxisChartsAllModule } from 'axis-charts';
// import { AreaPlusLineChartModule } from 'axis-charts';
// import { BarPlusMultiLineChartModule } from 'axis-charts';
// import { BarPlusLineChartModule } from 'axis-charts';
// import { BodymapChartModule } from 'axis-charts';
// import { BubbleChartModule } from 'axis-charts';
// import { DatamapsChartModule } from 'axis-charts';
// import { DonutChartModule } from 'axis-charts';
// import { GaugeChartModule } from 'axis-charts';
// import { HorizontalBarChartModule } from 'axis-charts';
// import { HorizontalStackedProgressChartModule } from 'axis-charts';
// import { WordcloudChartModule } from 'axis-charts';
// import { D3ChartsModule } from 'axis-charts';
// import { UtilsModule } from 'axis-charts';

// AXIS BUSINESS COMPONENTS
// import { AxisAbcPortalMenuModule } from 'axis-abc-portal-menu';
// import { AxisAbcSearchAccountModule } from 'axis-search-account';
import { AxisAddressModule } from 'axis-abc-search-address';
// import { AxisDatagridModule } from 'axis-datagrid';
// import { AxisNotificationModule } from 'axis-notification';

// MODULES
export const MODULES = [

    // >>> COMPONENTS
    AxisComponentsAllModule, // (ALL)
    // AccordionModule,
    // AlertModule,
    // AQIModule,
    // ButtonsModule,
    // CardModule,
    // CalendarModule,
    // CarouselModule,
    // CheckboxModule,
    // ChipListModule,
    // CountModule,
    // DropdownModule,
    // DatepickerModule,
    // DualListModule,
    // EditorModule,
    // FileUploadModule,
    // InputModule,
    IconModule,
    // ListModule,
    // ModalModule,
    // NavbarModule,
    // ProgressBarModule,
    RadioButtonModule,
    // TimepickerModule,
    // Scroll2tbModule,
    // SliderModule,
    // TabsModule,
    // TooltipModule,
    // TruncateModule,
    // UtilsModule,
    // LoadingModule,
    // IntroTourModule,

    // >>> CHARTS
    AxisChartsAllModule, // (ALL)
    // AreaPlusLineChartModule,
    // BarPlusMultiLineChartModule,
    // BarPlusLineChartModule,
    // BodymapChartModule,
    // BubbleChartModule,
    // DatamapsChartModule,
    // DonutChartModule,
    // GaugeChartModule,
    // HorizontalBarChartModule,
    // HorizontalStackedProgressChartModule,
    // WordcloudChartModule,
    // D3ChartsModule,
    // UtilsModule,

    // >>> Axis ABC's Components(Business components)
    // AxisAbcPortalMenuModule,
    // AxisAbcSearchAccountModule,
    AxisAddressModule,
    // AxisDatagridModule,
    // AxisNotificationModule
];

// SHARED MODULE
@NgModule({
    imports: [CommonModule, ...MODULES],
    declarations: [],
    exports: [CommonModule, FormsModule, ...MODULES]
})
export class AxisSharedModule { }
