import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/shared/configuration';
import { DataStoreService } from '../../../services/data-store.service';
import { FnolModelService } from '../../../services/fnol-model.service';
import * as moment from 'moment';
import { cdpLite } from './cdp-lite-model'
import { URL_PARAMETERS } from '../../../config/urlParameters.constant';
import { CHECKBOXHEADINGLITETravel, CHECKBOXLITEBAG, PAYMENTCHANNEL, LABELMAPPING, CHECKBOXLITEPERSONAL, FNOL_COUNTRY } from '../../../config/fnol-constants';
import { Utils } from 'axis-ui-generator';
import { AxisInputNumberPipe } from 'axis-components';
import { CacheService } from 'src/app/shared/services/cache.service';
import { FnolService } from '../../../services/fnol.service';


@Injectable({
  providedIn: 'root'
})
export class CdpLiteModelService {
  momentDateFormat = 'DD-MM-YYYY';
  personalAccident = [];
  permanantdiablity;
  leavebenifit;
  otherClaimShow;
  accidentalDeatBoolean;
  foundmedical
  policyDetails;
  phoneCodeList;
  insuredDetails = null;
  step2 = 'fnol-claimtype-details';
  step1 = 'fnol-claimant-info';
  country;
  personalDetails = []
  hsotDrInfo = []
  data
  uiStructure: any;
  uiData: any;
  mySettings51: any = {
    view_roundoff: false,
    thousands_separator: ",",
    decimal_separator: ".",
    fraction_size: "2",
    reg_exp: "^\d{1,3}(,\d{3})*(\.\d{2})?$",
    decimal: "",
    restrict: false
  };
  constructor(
    private config: Configuration,
    private dataStoreService: DataStoreService,
    private fnolModelService: FnolModelService,
    public axisPipe: AxisInputNumberPipe,
    private fnolService: FnolService,
    public cacheService: CacheService
  ) {
    this.fnolService.landingSelectedCountry.subscribe(selectedCountry => {
      if (selectedCountry.code) {
        const country = selectedCountry.code.toLowerCase();
        this.country = selectedCountry.code.toLowerCase();
        if (config.countryConfig[country]["customDateFormat"]) {
          this.momentDateFormat = config.countryConfig[country]["customDateFormat"].split('/').join("-");
        }
      }
    });

  }

  setPolicyHolderDetails(details: any) {
    this.insuredDetails = {
      policyNo: details["policyNumber"],
      firstName: details["firstName"] || details["policyFirstName"],
      lastName: details["lastName"] || details["policyLastName"],
      dob: details["dateOfBirth"] || details["policyDateOfBirth"],
      nric: details["nricFin"] || details["policyNricFin"],
    };
  }

  getPrivacyPolicySectionInfo() {
    return [
      {
        name: "Privacy Policy:",
        value: "Yes",
      },
      {
        name: "Terms of Use:",
        value: "Yes",
      },
      {
        name: "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
        value: "Yes",
      },
    ];
  }

  getClaimTypeData() {
    if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel') {
      return this.getTravelDetails(this.step2)
    }
    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other') {
      return this.getOtherDetails()
    }

    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident') {
      return this.personalAccidentPayload()
    }
  }

  convertDateGMT(datePassed, dateFormat: string, zeroCheck?): string {
    const dateFormatted = moment(datePassed).format(dateFormat);
    if (zeroCheck && zeroCheck === 'zeroTime') {
      return dateFormatted + 'T000000.000 GMT';
    } else { return dateFormatted + 'T050000.000 GMT'; }
  }

  getLossDate() {
    if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident') {
      const lossDate = this.convertDateGMT(
        this.dataStoreService.get(this.step2).uiData["accidentDate"],
        "YYYYMMDD"
      );
      return lossDate;
    }
    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel') {
      const lossDate = this.convertDateGMT(
        this.dataStoreService.get(this.step2).uiData["dateSymptom"],
        "YYYYMMDD"
      );
      return lossDate;
    }

    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other') {
      const lossDate = this.convertDateGMT(
        this.dataStoreService.get(this.step2).uiData["otherDate"],
        "YYYYMMDD"
      );
      return lossDate;
    }
  }

  getLossDocDate() {
    if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident') {
      return this.dataStoreService.get(this.step2).uiData["accidentDate"]
    }
    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel') {
      return this.dataStoreService.get(this.step2).uiData["dateSymptom"]
    }

    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other') {
      const currentDate = new Date().toString()
      return currentDate
    }
  }

  getDetails(requestName: string) {
    if (requestName === "policyNo") {
      if (this.policyDetails?.response?.policyDetails?.externalReference) {
        return this.policyDetails.response.policyDetails.externalReference;
      }
      else {
        return 'NOPOLICY';
      }
    }
  }

  getFnolRequestBody(
    type: string = "",
    policyDetailsData: any = {},
    phoneCodeListData: any = []
  ) {
    this.policyDetails = policyDetailsData;
    this.phoneCodeList = phoneCodeListData;

    cdpLite["applicationContext"]["userId"] =
      this.config.userEmailID;
    cdpLite["claimsDetails"]["userId"] = this.config.userEmailID;
    cdpLite["claimsDetails"]["claimNumber"] =
      this.fnolModelService.getClaimNumber();
    cdpLite["claimsDetails"]["dateofIncident"] =
      this.getLossDate();
    cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.lossDate = this.getLossDate();
    cdpLite.applicationContext.countryCode = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.requestHeader.countryCode = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.ApplicationContext.country = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.correspondenceItem.distributionGenerationData.country = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.requestHeader.requestMessageID = this.fnolModelService.getClaimNumber();
    cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.claimID = this.fnolModelService.getClaimNumber()

    if (
      (type === "setFnol")
    ) {
      return cdpLite;
    }
    let finalValue = this.prepDataForPDF(this.step1);
    if (URL_PARAMETERS.COUNTRY.toLowerCase() ===
      FNOL_COUNTRY.LITE.countryCode) {
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[0].attribute = finalValue
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[1].attribute = this.getClaimTypeData();
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[2].attribute = this.getPaymentData('fnol-payment-details');
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[3].attribute = this.getUploadDetails('fnol-upload-docs');
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.claimantEmail = this.dataStoreService.get("fnol-claimant-info").uiData['email'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.sharedServiceEmail = this.config.aigSharedeMailID
        ? this.config.aigSharedeMailID["sg"]
        : this.config.aigSharedeMailID;
      cdpLite.applicationContext.claimTypeCd = this.dataStoreService.get(this.step2).uiData["claimBenefits"];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.mobile = this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode'].hasOwnProperty('label') ? this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode']['label'] + "-" + this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneNumber'] : this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode'] + "-" + this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneNumber'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByFirstName = this.dataStoreService.get("fnol-claimant-info").uiData['firstName'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByLastName = this.dataStoreService.get("fnol-claimant-info").uiData['lastName'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.insuredName = this.dataStoreService.get("fnol-claimant-info").uiData['firstName'] + " " + this.dataStoreService.get("fnol-claimant-info").uiData['lastName'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.policyNumber = this.dataStoreService.get("fnol-claimant-info").uiData['policyNumber'];
    }
    return cdpLite;
  }

  decimalConverter(num: string, locale: string): string {
    let convert2Num: number = parseFloat(num);
    let decimalNum = convert2Num.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    if (decimalNum.substring(decimalNum.length - 3)[0] === ',') {
      let decimalNumber = decimalNum.replace(decimalNum.substring(decimalNum.length - 3)[0], '.');
      return decimalNumber;
    } else {
      return decimalNum;
    }
  }

  getData(name, type, stepName) {
    let resultData
    this.uiStructure = this.dataStoreService.get(stepName).uiStructure;
    this.uiData = this.dataStoreService.get(stepName).uiData;
    try {
      resultData = Utils.getFieldFromStructure(this.uiStructure, name);
    }
    catch (error) {
      resultData = '';
      return resultData;
    }
    if (type == "label") {
      if (!resultData) {
        return resultData = '';
      }
      if (resultData?.type === "checkbox") {
        return {
          key: resultData?.heading,
          value: resultData?.questionCd,
        }
      }
      return resultData;
    } else if (type === "rpa") {
      if (resultData?.type === "dropdown") {
        if (resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name].map(i => i.id).join(',')
        } else if (!resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name]?.id
        }
      } else if (resultData?.type === "radio") {

        return this.uiData[name];
      }
      else if (resultData?.type === "checkbox") {

        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No') ? "Yes" : "No"

      }

      else if (resultData?.type === "text" || resultData?.type === "datepicker" || resultData?.type === "timepicker" || resultData?.type === null || resultData?.type === "textarea") {
        return null
      }
    } else {
      if (resultData?.type === "datepicker" && this.uiData[name] != 'Invalid date' && this.uiData[name] != null && this.uiData[name] != '') {
        return (this.uiData[name] != null && this.uiData[name] != "") ? moment(new Date(this.uiData[name])).format(this.momentDateFormat) : this.uiData[name];
      } else if (resultData?.type === "timepicker") {
        return (this.uiData[name] != null && this.uiData[name] != "") && this.uiData[name]?.length > 5 ? moment(this.uiData[name]).format("hh:mm A") : this.uiData[name];
      } else if (resultData?.type === "checkbox") {
        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No') ? $localize`:@@yesNor:Yes` : $localize`:@@noNor:No`
      }
      else if (resultData?.type === "dropdown") {

        if (resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name].map(i => i.name).join(',')
        } else if (!resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name]?.name
        }

      } else if (resultData?.type === "radio") {
        return this.uiData[name];
      }
      else {
        return this.uiData[name]
      }
    }
  }

  checkCustom(field, index, step) {
    if (this.getData(field + index, "label", step)['type'] === "custom" &&
      this.getData(field + index, "label", step)['element'] === "CurrencyDropdownComponent"
    ) {
      return this.getData(field + index, "", step)['phoneNumber'] ?
        this.getData(field + index, "", step)['phoneCode'].hasOwnProperty('label') ?
          this.getData(field + index, "", step)['phoneCode']['label'] + " " + this.axisPipe.transform(this.getData(field + index, "", step)['phoneNumber'].toString(), this.mySettings51) :
          this.getData(field + index, "", step)['phoneCode'] + " " + this.axisPipe.transform(this.getData(field + index, "", step)['phoneNumber'].toString(), this.mySettings51)
        : null
    }
    else {
      return this.getData(field + index, "", step)
    }
  }

  prepDataForPDF(step) {
    let personalInfo = ["heading", "policyNumber", "groupName", "empId", "firstName", "lastName", "dob", "currentAge"];
    let personalDetailsInfo = [];
    personalInfo.forEach(res => {

      if (res == "heading") {
        personalDetailsInfo.push(
          {
            name: 'Personal Information',
            value: 'heading'
          });
      } else {
        const value = this.getData(res, "", step);
        // if (value) {
        personalDetailsInfo.push(
          {
            name: this.getData(res, "label", step)['label'],
            value
          });
        // }
      }
    });

    let contactInfo = ["heading", "email", "contactNumber", "personalCountry", "myAddressLookup", "levelUnitNumber", "postalCode"]
    contactInfo.forEach(res => {
      if (res == "heading") {
        personalDetailsInfo.push(
          {
            name: 'Contact Information',
            value: 'heading'
          });
      } else {
        if (res === 'contactNumber' && this.uiData["contactNumber"] !== null) {
          personalDetailsInfo.push(
            {
              name: 'Contact Number',
              value: this.getData("contactNumber", "", step)['phoneCode'].hasOwnProperty('label') ? this.getData("contactNumber", "", step)['phoneCode']['label'] + "-" + this.getData("contactNumber", "", step)['phoneNumber'] : this.getData("contactNumber", "", step)['phoneCode'] + "-" + this.getData("contactNumber", "", step)['phoneNumber']
            });
        } else if (res === "personalCountry" && this.getData(res, "label", step)['type'] === "dropdown") {
          personalDetailsInfo.push({
            name: this.getData('personalCountry', "label", step)['label'],
            value: this.dataStoreService.get('fnol-claimant-info').uiData['personalCountry'].name
          });
        } else if (res === 'levelUnitNumber' && this.uiData['levelUnitNumber'] !== "") {
          personalDetailsInfo.push(
            {
              name: this.getData(res, "label", step)['label'],
              value: this.getData(res, "", step)
            })
        } else {
          // if (res !== 'levelUnitNumber' && this.uiData[res]?.trim() !== "") {
          personalDetailsInfo.push(
            {
              name: this.getData(res, "label", step)['label'],
              value: this.getData(res, "", step)
            });
          // }
        }
      }
    });
    return personalDetailsInfo
  }

  getClaimType(step) {
    let claimType = ["claimBenefits"]
    let claimTypeInfo = []
    claimType.forEach(fieldName => {
      claimTypeInfo.push(
        {
          name: this.getData(fieldName, "label", step)['label'],
          value: this.getData(fieldName, "", step)
        });
    })
    return claimTypeInfo;
  }

  getTravelDetails(step) {
    let travelDetailsInfo = [];
    travelDetailsInfo.push(
      {
        name: this.getData('claimBenefits', "label", step)['label'] === "Select Claim Type" ? "What is the cause of our claim?" : "",
        value: this.dataStoreService.get(step).uiData["claimBenefits"]
      })

    let travelInfo = ["heading", "dateSymptom", "dateVisit", "diagnosisIllness", "citizenUSTravel"]

    let uiData = this.dataStoreService.get(step).uiData;
    travelInfo.forEach(fieldName => {

      if (fieldName == "heading") {
        travelDetailsInfo.push(
          {
            name: 'Travel Details',
            value: 'heading'
          })
      } else {
        if (fieldName === "diagnosisIllness" && fieldName.indexOf('diagnosisIllness') > -1) {
          travelDetailsInfo.push(
            {
              name: this.getData('diagnosisIllness', "label", step)['label'],
              value: this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisIllness']?.name
            });
        } else {
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: this.getData(fieldName, "", step)
            })
        }
      }
    });

    let travelBenefit = ["heading", "chooseClaimTypeIllness"]
    travelBenefit.forEach(fieldName => {

      if (fieldName == "heading") {
        travelDetailsInfo.push(
          {
            name: 'Travel Benefits',
            value: 'heading'
          })
      }
      else {
        const newArray = [];
        this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].forEach(element => {
          if (CHECKBOXHEADINGLITETravel.find(field => field.key === element.name) != undefined) {
            let headingValue = CHECKBOXHEADINGLITETravel.find(field => field.key === element.name).heading;
            newArray.push(headingValue);
          }
        });
        travelDetailsInfo.push(
          {
            name: this.getData(fieldName, "label", step)['label'],
            value: newArray.join(', ')
          });
      }
    });

    if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x => x.name === 'claimingIncident') != undefined) {
      let travelBenefitType = ["heading", "injuredSick"]
      travelBenefitType.forEach(fieldName => {
        if (fieldName == "heading") {
          travelDetailsInfo.push(
            {
              name: 'Medical Incident',
              value: 'heading'
            })
        } else {
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: this.dataStoreService.get('fnol-claimtype-details').uiData['injuredSick'] === 'wasInjured' ? 'I was injured' : 'I was sick',
            }
          );
        }
      });

      if (uiData['injuredSick'] === "wasInjured" && uiData['injuredSick'] !== null) {
        let insurance = ["claimDescribeWords", "whenOccur", "diagnosisInjured", "medicalExpenseInjured", "travelAccident"];
        insurance.forEach(fieldName => {
          if (fieldName === "medicalExpenseInjured") {
            let medicalDetails = ["westernMedicine", "mobileAdd", "dentalTreatment", 'sum']
            travelDetailsInfo.push(
              {
                name: "Cost of Treatment",
                value: "heading"
              });
            const medicalExpenseInjured = this.dataStoreService.get(this.step2).uiData["medicalExpenseInjured"];
            medicalDetails.forEach(key => {
              travelDetailsInfo.push(
                {
                  name: key === "westernMedicine" ? 'Medical Treatment' : key === "mobileAdd" ? 'Mobility Aid' : key === "dentalTreatment" ? 'Dental Treatment' : key === "sum" ? 'Total Claim Amount' : '',
                  value: medicalExpenseInjured[key] ? medicalExpenseInjured?.currency?.label + " " + this.decimalConverter(medicalExpenseInjured[key], 'SG') : medicalExpenseInjured?.currency?.label + "0.00"
                }
              )
            });
          }

          if (fieldName === "diagnosisInjured") {
            if (fieldName.indexOf('diagnosisInjured') > -1) {
              travelDetailsInfo.push(
                {
                  name: this.getData('diagnosisInjured', "label", step)['label'],
                  value: this.getData('diagnosisInjured', "", step),
                });
            }
            if (this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisInjured'].id === '8') {
              travelDetailsInfo.push(
                {
                  name: this.getData('diagnosisOther', "label", step)['label'],
                  value: this.getData('diagnosisOther', "", step),
                });
            }
          }

          if (fieldName === "travelAccident") {
            if (uiData['travelAccident'] === 'Yes' && uiData['travelAccident'] !== null) {
              travelDetailsInfo.push(
                {
                  name: this.getData('travelAccident', "label", step)['label'],
                  value: this.getData('travelAccident', "", step),
                },
                {
                  name: this.getData('claimDescribe', "label", step)['label'],
                  value: this.getData('claimDescribe', "", step),
                },
                {
                  name: this.getData('nameCost', "label", step)['label'],
                  value: this.checkCustom('nameCost', "", step),
                })
            } else {
              travelDetailsInfo.push({
                name: this.getData('travelAccident', "label", step)['label'],
                value: this.getData('travelAccident', "", step),
              })
            }
          } else {
            if (fieldName !== 'medicalExpenseInjured' && fieldName !== 'diagnosisInjured') {
              travelDetailsInfo.push({
                name: this.getData(fieldName, "label", step)['label'],
                value: this.getData(fieldName, "", step),
              });
            }
          }
        });
      } else if (uiData['injuredSick'] === "wasSick" && uiData['injuredSick'] !== null) {
        let insurance = ["claimDescribeWordsSick", "whenOccurSick", "diagnosisSickInjuredSick", "medicalExpenseSick", "travelAccidentSick"];
        insurance.forEach(fieldName => {
          if (fieldName === "medicalExpenseSick") {
            let medicalDetails = ["heading", "westernMedicine", "mobileAdd", "dentalTreatment", 'sum']
            medicalDetails.forEach(key => {
              if (key === "heading") {
                travelDetailsInfo.push(
                  {
                    name: "Cost of Treatment",
                    value: "heading"
                  });
              } else {
                travelDetailsInfo.push(
                  {
                    name: key === "westernMedicine" ? 'Medical Treatment' : key === "mobileAdd" ? 'Mobility Aid' : key === "dentalTreatment" ? 'Dental Treatment' : key === "sum" ? 'Total Claim Amount' : '',
                    value: this.dataStoreService.get(this.step2).uiData["medicalExpenseSick"][key] ? this.fnolService.getDataCurrency() + " " + this.decimalConverter(this.dataStoreService.get(this.step2).uiData["medicalExpenseSick"][key], 'SG') : this.fnolService.getDataCurrency() + "0.00"

                  }
                );
              }
            });
          }

          if (fieldName === "diagnosisSickInjuredSick") {
            if (fieldName.indexOf('diagnosisSickInjuredSick') > -1) {
              travelDetailsInfo.push(
                {
                  name: this.getData('diagnosisSickInjuredSick', "label", step)['label'],
                  value: this.getData('diagnosisSickInjuredSick', "", step),
                });
            }
            if (this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisSickInjuredSick'].id === '13') {
              travelDetailsInfo.push(
                {
                  name: this.getData('diagnosisSickOtherSick', "label", step)['label'],
                  value: this.getData('diagnosisSickOtherSick', "", step),
                });
            }
          }

          if (fieldName === "travelAccidentSick") {
            if (uiData['travelAccidentSick'] === 'Yes' && uiData['travelAccidentSick'] !== null) {
              travelDetailsInfo.push(
                {
                  name: this.getData('travelAccidentSick', "label", step)['label'],
                  value: this.getData('travelAccidentSick', "", step),
                },
                {
                  name: this.getData('claimDescribeSick', "label", step)['label'],
                  value: this.getData('claimDescribeSick', "", step),
                },
                {
                  name: this.getData('nameCostSick', "label", step)['label'],
                  value: this.checkCustom('nameCostSick', "", step),
                });
            } else {
              travelDetailsInfo.push({
                name: this.getData('travelAccidentSick', "label", step)['label'],
                value: this.getData('travelAccidentSick', "", step),
              });
            }
          } else {
            if (fieldName !== 'medicalExpenseSick' && fieldName !== 'diagnosisSickInjuredSick') {
              travelDetailsInfo.push({
                name: this.getData(fieldName, "label", step)['label'],
                value: this.getData(fieldName, "", step),
              });
            }
          }
        });
      }
    }

    if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x => x.name === 'claimingDelayed') !== undefined) {
      let travelBenefitType = ["heading", "originalFlightNumber", "originalArriveDate", "originalArriveTime", "revisedFlightNumber", "actualArriveDate", "actualArriveTime", "flightDelayed"]
      travelBenefitType.forEach(fieldName => {
        if (fieldName == "heading") {
          travelDetailsInfo.push(
            {
              name: 'Delayed Flight',
              value: 'heading'
            });
        } else {
          if (fieldName === "flightDelayed") {
            if (fieldName.indexOf('flightDelayed') > -1) {
              travelDetailsInfo.push(
                {
                  name: this.getData('flightDelayed', "label", step)['label'],
                  value: this.getData('flightDelayed', "", step),
                });
            }
            if (this.dataStoreService.get('fnol-claimtype-details').uiData['flightDelayed'].id === '7') {
              travelDetailsInfo.push(
                {
                  name: this.getData('provideDetailsTravel', "label", step)['label'],
                  value: this.getData('provideDetailsTravel', "", step),
                });
            }
          } else {
            travelDetailsInfo.push(
              {
                name: this.getData(fieldName, "label", step)['label'],
                value: this.getData(fieldName, "", step),
              }
            );
          }
        }
      });
    }

    if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x => x.name === 'claimingTrip') !== undefined) {
      let travelBenefitType = ["heading", "diagnosisSickTripSick", "diagnosisSickTripShip", "diagnosisTripOtherShip", "claimingForOther", "dateOfCancellation", "flightExpense", "flightRefund", "accommodationExpense", "accommodationRefund", "otherTransportationExpense", "otherTransportationRefund"]
      travelBenefitType.forEach(fieldName => {
        if (fieldName == "heading") {
          travelDetailsInfo.push(
            {
              name: 'Cancelled Trip',
              value: 'heading'
            });
        } else {
          if (fieldName === "flightExpense" || fieldName === "flightRefund" || fieldName === "accommodationExpense" || fieldName === "accommodationRefund" || fieldName === "otherTransportationExpense" || fieldName === "otherTransportationRefund") {
            travelDetailsInfo.push(
              {
                name: this.getData(fieldName, "label", step)['label'],
                value: this.checkCustom(fieldName, "", step)
              }
            )
          }

          if (fieldName === "diagnosisSickTripSick") {
            travelDetailsInfo.push(
              {
                name: this.getData('diagnosisSickTripSick', "label", step)['label'],
                value: this.getData('diagnosisSickTripSick', "", step),
              });
            const diagnosisSickTripSick = this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisSickTripSick'];
            if (diagnosisSickTripSick.id === '7') {
              travelDetailsInfo.push(
                {
                  name: this.getData('diagnosisTripOtherSick', "label", step)['label'],
                  value: this.getData('diagnosisTripOtherSick', "", step),
                });
            }

            if (diagnosisSickTripSick.id === '4') {
              travelDetailsInfo.push(
                {
                  name: this.getData('diagnosisSickTripShip', "label", step)['label'],
                  value: this.getData('diagnosisSickTripShip', "", step),
                });
              if (this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisSickTripShip']?.id === '8') {
                travelDetailsInfo.push(
                  {
                    name: this.getData('diagnosisTripOtherShip', "label", step)['label'],
                    value: this.getData('diagnosisTripOtherShip', "", step),
                  });
                console.log('travelDetailsInfo2', travelDetailsInfo);
                ;
              }
            }
          }

          if (fieldName === "claimingForOther" || fieldName === "dateOfCancellation") {
            travelDetailsInfo.push(
              {
                name: this.getData(fieldName, "label", step)['label'],
                value: this.getData(fieldName, "", step),
              }
            );
          }
        }
      });
    }

    if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x => x.name === 'claimingLost') !== undefined) {
      let travelBenefitType = ["heading", "itemClaimType"]
      travelBenefitType.forEach(fieldName => {
        if (fieldName == "heading") {
          travelDetailsInfo.push(
            {
              name: 'Baggage / Item Claim',
              value: 'heading'
            });
        } else {
          const newArray = [];
          this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'].forEach(element => {
            if (CHECKBOXLITEBAG.find(field => field.key === element.name) != undefined) {
              let headingValue = CHECKBOXLITEBAG.find(field => field.key === element.name).heading;
              newArray.push(headingValue);
            }
          });
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: newArray.toString(),
            }
          );
        }
      });

      let travelBagsDetails = ['travelBags']
      travelBagsDetails.forEach(fieldName => {
        if (fieldName === 'travelBags') {
          travelDetailsInfo.push(
            {
              name: this.getData('travelBags', "label", step)['label'],
              value: this.getData('travelBags', "", step),
            }
          );
          if (uiData['travelBags'] === "Delayed") {
            let delayedDetails = ['landDate', 'timeLand', 'returnedDate', 'timeReturned']
            delayedDetails.forEach(fieldName => {
              travelDetailsInfo.push(
                {
                  name: this.getData(fieldName, "label", step)['label'],
                  value: this.getData(fieldName, "", step),
                }
              );
            });
          }

          if (uiData['travelBags'] === "Damaged") {
            let delayedDetails = ['bagsTripSick', 'damagedDate', 'provideDetails', "heading"]
            delayedDetails.forEach(fieldName => {
              if (fieldName === "heading") {
                travelDetailsInfo.push(
                  {
                    name: 'Bags and/or Items Details',
                    value: 'heading'
                  });
              } if (fieldName === "bagsTripSick") {
                if (fieldName.indexOf('bagsTripSick') > -1) {
                  travelDetailsInfo.push(
                    {
                      name: this.getData('bagsTripSick', "label", step)['label'],
                      value: this.getData('bagsTripSick', "", step),
                    });
                }
                if (this.dataStoreService.get('fnol-claimtype-details').uiData['bagsTripSick'].id === '3') {
                  travelDetailsInfo.push(
                    {
                      name: this.getData('otherBagsTripSick', "label", step)['label'],
                      value: this.getData('otherBagsTripSick', "", step),
                    });
                }
              } else {
                travelDetailsInfo.push(
                  {
                    name: this.getData(fieldName, "label", step)['label'],
                    value: this.getData(fieldName, "", step),
                  }
                );
              }
            });

            if (this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name === "myBags" || ((this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name === "myBags" && this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][1]?.name === "myItems") || (this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][1]?.name === "myBags" && this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name === "myItems"))) {
              let bagDamage = Utils.getFieldFromStructure(this.dataStoreService.get(step).uiStructure, "addItemsMain").fields
              bagDamage.forEach((data, i) => {
                const matches = data.name.match(/(\d+)/);
                const index1 = matches != null ? matches[0] : "";
                const bagDamageDetails = ["bagItem", "nameValue", "yearPurchased", "whatDamaged", "repairCost", "heading"];
                bagDamageDetails.forEach((item, i) => {
                  if (item === "nameValue" || item === "repairCost") {
                    travelDetailsInfo.push(
                      {
                        name: this.getData(item + index1, "label", step)['label'],
                        value: this.checkCustom(item + index1, "", step)
                      }
                    );
                  } else if (item === "whatDamaged") {
                    travelDetailsInfo.push(
                      {
                        name: this.getData('whatDamaged' + index1, "label", step)['label'],
                        value: this.getData('whatDamaged' + index1, "", step),
                      });
                    if (this.dataStoreService.get('fnol-claimtype-details').uiData['whatDamaged' + index1]?.id === '5') {
                      travelDetailsInfo.push(
                        {
                          name: this.getData('provideDetailsDamaged' + index1, "label", step)['label'],
                          value: this.getData('provideDetailsDamaged' + index1, "", step),
                        })
                    }
                  } else if (item === "heading") {
                    travelDetailsInfo.push(
                      {
                        name: "",
                        value: "heading"
                      }
                    );
                  } else {
                    travelDetailsInfo.push(
                      {
                        name: this.getData(item + index1, "label", step)['label'],
                        value: this.getData(item + index1, "", step)
                      }
                    );
                  }

                });
              });
            } else if (this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name === 'myItems') {
              let bagDamage = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2).uiStructure, "addItems").fields
              bagDamage.forEach((data, i) => {
                const index = i == 0 ? "" : i;
                const bagDamageDetails = ["bagItem", "nameValue", "yearPurchased", "heading"];
                bagDamageDetails.forEach((item, i) => {
                  if (item === "nameValue") {
                    travelDetailsInfo.push(
                      {
                        name: this.getData(item + index, "label", step)['label'],
                        value: this.checkCustom(item + index, "", step)
                      }
                    );
                  }
                  else if (item === "heading") {
                    travelDetailsInfo.push(
                      {
                        name: "",
                        value: "heading"
                      }
                    );
                  } else {
                    travelDetailsInfo.push(
                      {
                        name: this.getData(item + index, "label", step)['label'],
                        value: this.getData(item + index, "", step)
                      }
                    );
                  }
                });
              });
            }
          }

          if (uiData['travelBags'] === "Lost or Stolen") {
            let lostStolenDetails = ['stolenLost', 'stolenHappened', 'stolenItem', 'stolenItemWas', 'stolenDate', 'heading']
            lostStolenDetails.forEach(fieldName => {
              if (fieldName === "heading") {
                travelDetailsInfo.push(
                  {
                    name: "Bags and/or Items Details",
                    value: "heading",
                  })
              } else {
                travelDetailsInfo.push(
                  {
                    name: this.getData(fieldName, "label", step)['label'],
                    value: this.getData(fieldName, "", step),
                  }
                );
              }
            });

            let lostStolen = Utils.getFieldFromStructure(this.dataStoreService.get(step).uiStructure, "addStolenMain").fields
            lostStolen.forEach((data, i) => {
              const matches = data.name.match(/(\d+)/);
              const index1 = matches != null ? matches[0] : "";
              const lostStolenDetails = ["bagItemStolen", "nameStolenValue", "yearStolenPurchased", "heading"];
              lostStolenDetails.forEach((item, i) => {
                if (item === "nameStolenValue") {
                  travelDetailsInfo.push(
                    {
                      name: this.getData(item + index1, "label", step)['label'],
                      value: this.checkCustom(item + index1, "", step)
                    }
                  );
                } else if (item === "heading") {
                  travelDetailsInfo.push(
                    {
                      name: "",
                      value: "heading"
                    }
                  )
                } else {
                  travelDetailsInfo.push(
                    {
                      name: this.getData(item + index1, "label", step)['label'],
                      value: this.getData(item + index1, "", step)
                    }
                  );
                }
              });
            });

            if (uiData['authorityReport'] === "Yes" && uiData['authorityReport'] !== null) {
              travelDetailsInfo.push(
                {
                  name: this.getData('authorityReport', "label", step)['label'],
                  value: this.checkCustom('authorityReport', "", step)
                },
                {
                  name: this.getData('authorityCopyReport', "label", step)['label'],
                  value: this.checkCustom('authorityCopyReport', "", step)
                }
              )
            } else {
              travelDetailsInfo.push(
                {
                  name: this.getData('authorityReport', "label", step)['label'],
                  value: this.checkCustom('authorityReport', "", step)
                }
              );
            }

            if (uiData['authorityReport'] === "Yes" && uiData['authorityReport'] !== null && uiData['authorityCopyReport'] === "Yes" && uiData['authorityCopyReport'] !== null) {
              let copyReport = ['nameAuthority', 'referenceNumber', 'phoneNumber', 'emailStolen', 'myAddressLookup']
              copyReport.forEach(fieldName => {
                if (fieldName === "myAddressLookup") {
                  travelDetailsInfo.push(
                    {
                      name: this.getData(fieldName, "label", step)['label'],
                      value: this.getData(fieldName + "val", "", step) ? this.getData(fieldName + "val", "", step) : this.getData(fieldName, "", step).formatted_address ? this.getData(fieldName, "", step).formatted_address : this.getData(fieldName, "", step)
                    }
                  );
                } else if (fieldName === "phoneNumber" && this.uiData['phoneNumber'] !== null) {
                  travelDetailsInfo.push(
                    {
                      name: 'Phone Number',
                      value: this.getData("phoneNumber", "", step)['phoneCode'].hasOwnProperty('label') ? this.getData("phoneNumber", "", step)['phoneCode']['label'] + "-" + this.getData("phoneNumber", "", step)['phoneNumber'] : this.getData("phoneNumber", "", step)['phoneCode'] + "-" + this.getData("phoneNumber", "", step)['phoneNumber']
                    });
                } else {
                  travelDetailsInfo.push(
                    {
                      name: this.getData(fieldName, "label", step)['label'],
                      value: this.checkCustom(fieldName, "", step)
                    }
                  );
                }
              });
            }
          }
        }
      });
    }

    if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x => x.name === 'claimingOther') !== undefined) {
      let travelBenefitType = ["heading", "claimingForOtherFor"]
      travelBenefitType.forEach(fieldName => {
        if (fieldName == "heading") {
          travelDetailsInfo.push(
            {
              name: 'Other',
              value: 'heading'
            });
        } else {
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: this.getData(fieldName, "", step),
            }
          );
        }
      });
    }
    return travelDetailsInfo
  }

  getPaymentData(step) {
    const paymentObj = [];
    const paymentData = this.dataStoreService.get('fnol-payment-details').uiData;

    if (this.country !== "hk") {
      if (paymentData?.paymentType) {
        paymentObj.push({
          name: "Payment Channel",
          value: paymentData['paymentType'].value
        });
      } else {
        paymentObj.push({
          name: "Payment Channel",
          value: PAYMENTCHANNEL[this.country]
        });
      }
      if (paymentData?.claimExpenses) {
        paymentObj.push({
          name: "Are you claiming or intend to claim the expenses incurred from other insurance companies or organizations?",
          value: paymentData['claimExpenses'].value
        });
      }
      Object.keys(paymentData).forEach(item => {
        if (LABELMAPPING[item]) {
          paymentObj.push({
            name: LABELMAPPING[item],
            value: item == "ownerStructure" || item == "claimExpenses" ? paymentData[item]['value'] : item == 'claimInsured' ? paymentData[item] + "%" : paymentData[item]
          });
        }
      });
    } else {
      Object.keys(paymentData).forEach((item, index) => {
        if (index !== 1) {
          if (LABELMAPPING[item]) {
            paymentObj.push({
              name: LABELMAPPING[item],
              value: item == "ownerStructure" || item == "claimExpenses" ? paymentData[item]['value'] : item == 'claimInsured' ? paymentData[item] + "%" : paymentData[item]
            });
          }
        } else {
          paymentObj.push({
            name: "Are you claiming or intend to claim the expenses incurred from other insurance companies or organizations?",
            value: paymentData['claimExpenses'].value
          });
          paymentObj.push({
            name: "Payment Channel",
            value: paymentData['paymentType'].value
          });
        }
      });
    }


    return paymentObj;

  }

  getUploadDetails(step) {
    let obj = []
    if (this.dataStoreService.get(step).uiData.customFileUpload) {
      let fileList = this.dataStoreService.get(step).uiData.customFileUpload.uploads;
      fileList.forEach((element, i) => {
        obj.push(
          {
            name: "Upload" + (i + 1) + ":",
            value: element.name
          }
        );
      });
      return obj;
    } else {
      return obj;
    }
  }

  getPersonalAccident() {
    let personalDetails = []
    personalDetails.push(
      {
        name: this.getData('claimBenefits', "label", this.step2)['label'] === "Select Claim Type" ? "What is the cause of our claim?" : "",
        value: this.dataStoreService.get(this.step2).uiData["claimBenefits"]
      });

    let arrysub1 = ["heading", "accidentOccurred", "injuriesSustained", "accidentDate", "timeIncident", "accidentLocationLookup", "firstVisit", "diagnosis", "trafficAccident", "citizenUSPA"]
    let arraysub2 = ["heading", "chooseClaimType"];
    arrysub1.forEach(key => {
      if (key === "heading") {
        personalDetails.push(
          {
            name: "Personal Accident Details",
            value: "heading"
          });
      } else if (key === "accidentLocationLookup") {
        if (this.getData(key, "", this.step2)) {
          personalDetails.push(
            {
              name: this.getData(key, "label", this.step2)['label'],
              value: this.getData(key + "val", "", this.step2) ? this.getData(key + "val", "", this.step2) : this.getData(key, "", this.step2).formatted_address ? this.getData(key, "", this.step2).formatted_address : this.getData(key, "", this.step2)
            });
        }
      } else if (key === "diagnosis") {
        if (key.indexOf('diagnosis') > -1) {
          personalDetails.push(
            {
              name: this.getData('diagnosis', "label", this.step2)['label'],
              value: this.getData('diagnosis', "", this.step2),
            });
        }
        if (this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosis']?.id === '13') {
          personalDetails.push(
            {
              name: this.getData('otherDia', "label", this.step2)['label'],
              value: this.getData('otherDia', "", this.step2),
            });
        }
      } else {
        if (this.dataStoreService.get(this.step2)?.uiData[key] !== 'diagnosis') {
          if (this.getData(key, "", this.step2)) {
            personalDetails.push(
              {
                name: this.getData(key, "label", this.step2)['label'],
                value: this.getData(key, "", this.step2)
              }
            );
          }
        }
      }
    });
    arraysub2.forEach(key => {
      if (key === "heading") {
        personalDetails.push(
          {
            name: "Personal Accident Benefits",
            value: "heading"
          })
      } else {
        const newArray = [];
        this.personalAccident = [];
        const chooseClaimType = this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimType'];
        CHECKBOXLITEPERSONAL.map(field => {
          chooseClaimType.forEach(element => {
            if (field.key === element.name) {
              let headingValue = field.heading;
              newArray.push(headingValue);
              this.personalAccident.push(headingValue);
            }
          });
        });
        // this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimType'].forEach(element => {
        //   if (CHECKBOXLITEPERSONAL.find(field => field.key === element.name) != undefined) {
        //     let headingValue = CHECKBOXLITEPERSONAL.find(field => field.key === element.name).heading;
        //     newArray.push(headingValue);
        //     this.personalAccident.push(headingValue)
        //   }
        // });
        personalDetails.push(
          {
            name: this.getData(key, "label", this.step2)['label'],
            value: newArray.join(', ')
          }
        );
      }
    });
    return this.personalDetails = personalDetails;
  }

  getCreditTravel(data?) {
    const nonBagNoEMp = ["nameClinic", "dateOfAdmission", "timeAdmission", "dateOfDischarge", "timeDischarge"];
    let travelArr = this.buildDetails({ mainUIFieldName: "addTreatmentMain", fieldNames: nonBagNoEMp, conditionalFieldName: "admittedHospital" });

    if (data && travelArr.length > 0) {
      travelArr = [{
        name: "Hospitalization details",
        value: "heading"
      }, ...travelArr];
    }
    return travelArr
  }

  getDammageDelayDetails() {
    const nonBagNoEMp = ["nameClinic", "dateOfAdmission", "timeAdmission", "timeDischarge"]
    const travelArr = this.buildDetails({ mainUIFieldName: "addTreatment", fieldNames: nonBagNoEMp, conditionalFieldName: "admittedHospital" });
    return travelArr
  }

  accidentalMedical(e?) {
    let DetailsData = []
    let arraysub4 = [
      "heading", "westernMedicine",
      "dentalTreatment",
      "mobileAdd",
      , 'sum']
    arraysub4.forEach(key => {
      if (key === "heading") {
        DetailsData.push(
          {
            name: "Cost of Treatment",
            value: "heading"
          })
      } else {
        DetailsData.push(
          {
            name: key === "westernMedicine" ? 'Medical Treatment' : key === "dentalTreatment" ? 'Dental Treatment' : key === "mobileAdd" ? 'Mobility Aid' : key === "sum" ? 'Total Claim Amount' : '',
            value: this.dataStoreService.get(this.step2).uiData["medicalExpense"][key] ? this.fnolService.getDataCurrency() + " " + this.decimalConverter(this.dataStoreService.get(this.step2).uiData["medicalExpense"][key], 'SG') : this.fnolService.getDataCurrency() + "0.00"
          }
        );
      }
    })
    if (e) {
      if (e && DetailsData.length > 1) {
        DetailsData[0].value = ''
        let array = [{
          name: "Accident Medical Reimbursement",
          value: "heading"
        }];
        DetailsData = [...array, ...DetailsData]
      }
      else {
        DetailsData = [];
      }
    }
    return DetailsData;
  }

  featureOrDisclosed() {
    const nonBagNoEMp = ["siteFracture", "typeFracture"];
    const travelArr = this.buildDetails({ mainUIFieldName: "addFractureMain", fieldNames: nonBagNoEMp, conditionalFieldName: "sustainFracture" });
    return travelArr
  }

  siteDisclosed() {
    const nonBagNoEMp = ["siteDislocation"];
    const travelArr = this.buildDetails({ mainUIFieldName: "addDislocationMain", fieldNames: nonBagNoEMp, conditionalFieldName: "sustainDislocation" });
    return travelArr
  }

  hospitalDocInfo(e?) {
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2).uiStructure, "addDoctorMain").fields;
    let travelArr = []
    let uiData = this.dataStoreService.get(this.step2).uiData;
    if (uiData["doctorInfo"] === "Yes") {
      travelInsurance.forEach((data, i) => {
        if (i > 0 && !e) {
          travelArr.push({
            "name": "",
            "value": ""
          });
        }
        data.fields.forEach((item) => {
          if (item.name.match("mobileDoctor") ) {
            travelArr.push(
              {
                "name": 'Contact Number',
                "value": uiData[item.name]?.phoneNumber?.length >0 ? this.getData(item.name, "", this.step2)['phoneCode'].hasOwnProperty('label') ? this.getData(item.name, "", this.step2)['phoneCode']['label'] + "-" + this.getData(item.name, "", this.step2)['phoneNumber'] : this.getData(item.name, "", this.step2)['phoneCode'] + "-" + this.getData(item.name, "", this.step2)['phoneNumber']:''
              });
          }
          else if (item.name.match("emailDoctor") && uiData[item.name]?.length !== 0) {
            travelArr.push({
              "name": this.getData(item.name, "label", this.step2)['label'],
              "value": this.getData(item.name, "", this.step2)
            });
          }
          else {
            travelArr.push({
              "name": this.getData(item.name, "label", this.step2)['label'],
              "value": this.getData(item.name, "", this.step2)
            });
          }
        });
      });
    } else {
      travelArr = []
    }
    return travelArr
  }

  drInfor() {
    let personalAccidentDetails = ["doctorInfo"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetailsInfo = this.pushFieldNames({ fieldNames: personalAccidentDetails, formattedArray: personalAccidentDetailsInfo, index: '' });
    this.hsotDrInfo = personalAccidentDetailsInfo;
    return personalAccidentDetailsInfo
  }


  dailyHospitalIncome(data?) {
    let personalAccidentDetails = ["admittedHospital"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetailsInfo = this.pushFieldNames({ fieldNames: personalAccidentDetails, formattedArray: personalAccidentDetailsInfo, index: '' });

    if (data && personalAccidentDetailsInfo.length > 0) {
      personalAccidentDetailsInfo = [{
        name: "Daily Hospital Income",
        value: "heading"
      }, ...personalAccidentDetailsInfo];
    }
    return personalAccidentDetailsInfo
  }

  getOtherDetails() {
    let otherDetailsInfo = []
    otherDetailsInfo.push(
      {
        name: this.getData('claimBenefits', "label", this.step2)['label'] === "Select Claim Type" ? "What was the cause of the claim?" : "",
        value: this.dataStoreService.get(this.step2).uiData["claimBenefits"]
      });
    otherDetailsInfo.push({
      name: this.getData('otherDate', "label", this.step2)['label'],
      value: this.getData('otherDate', "", this.step2)
    });
    otherDetailsInfo.push({
      name: this.getData('claimForOther', "label", this.step2)['label'],
      value: this.getData('claimForOther', "", this.step2)
    });
    otherDetailsInfo.push({
      name: this.getData('citizenUSOther', "label", this.step2)['label'],
      value: this.getData('citizenUSOther', "", this.step2)
    });
    return otherDetailsInfo
  }

  mediaclLeave(e?) {
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2).uiStructure, "addLeave").fields
    let travelArr = []
    travelInsurance.forEach((data, i) => {
      if (i > 0 && !e) {
        travelArr.push({
          "name": "",
          "value": ""
        });
      }

      const index = i > 0 ? i : "";
      const nonBagNoEMp = ["dateOfStart", "dateOfEnd"];
      nonBagNoEMp.forEach((item, i) => {
        if (this.getData(item + index, "", this.step2)) {
          travelArr.push({
            "name": this.getData(item + index, "label", this.step2)['label'],
            "value": this.getData(item + index, "", this.step2)
          });
        }
      });
    })
    if (e && travelArr.length > 0) {
      let array = [{
        name: "Medical Leave",
        value: "heading"
      }];
      travelArr = [...array, ...travelArr]
    }
    return travelArr
  }

  detailsMedicalLeave() {
    let personalAccidentDetails = ["employed", "dateOfExpected"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetailsInfo = this.pushFieldNames({ fieldNames: personalAccidentDetails, formattedArray: personalAccidentDetailsInfo, index: '' });

    return personalAccidentDetailsInfo
  }

  PermanentDisablement(e?) {
    let permanentDi = ["dateOfDisability", "typeDisability","otherCauseDisability"];
    let disabilityData = [];
    disabilityData = this.pushFieldNames({ fieldNames: permanentDi, formattedArray: disabilityData, index: '' });
   
    if (e && disabilityData.length > 0) {
      disabilityData = [{
        name: "Permanent/Temporary Disablement",
        value: "heading"
      }, ...disabilityData];
       
    }
    return disabilityData;
  }

  accidentalDeath(e?) {
    let permanentDi = ["dateOfDeath", "localTimePersonal", "causeDeath", "autopsy", "fullName", "relationName", "phoneNumberPersonal", "emailAddress"];
    let disablityData = [];
    permanentDi.forEach((res) => {
      if (res === "phoneNumberPersonal") {
        const data = this.getData("phoneNumberPersonal", "", this.step2);
        if (data) {
          disablityData.push(
            {
              "name": 'Phone Number',
              "value": data['phoneCode']?.hasOwnProperty('label') ? data['phoneCode']['label'] + "-" + data['phoneNumber'] : data['phoneCode'] + "-" + data['phoneNumber']
            });

        }
      } else if (res === 'autopsy') {
        if (this.uiData['autopsy'] === 'No' && this.uiData['autopsy'] !== null) {
          disablityData.push(
            {
              name: this.getData('autopsy', "label", this.step2)['label'],
              value: this.getData('autopsy', "", this.step2),
            },
            {
              name: this.getData('reason', "label", this.step2)['label'],
              value: this.getData('reason', "", this.step2),
            });
        } else {
          disablityData.push({
            name: this.getData('autopsy', "label", this.step2)['label'],
            value: this.getData('autopsy', "", this.step2),
          });
        }
      } else if (res === "causeDeath") {
        if (res.indexOf('causeDeath') > -1) {
          disablityData.push(
            {
              name: this.getData('causeDeath', "label", this.step2)['label'],
              value: this.getData('causeDeath', "", this.step2),
            });
        }
        if (this.dataStoreService.get('fnol-claimtype-details').uiData['causeDeath']?.id === '6') {
          disablityData.push(
            {
              name: this.getData('otherCauseDeath', "label", this.step2)['label'],
              value: this.getData('otherCauseDeath', "", this.step2),
            });
        }
      } else {
        if (this.dataStoreService.get(this.step2)?.uiData[res] !== 'causeDeath') {
          if (this.getData(res, "", this.step2)) {
            disablityData.push(
              {
                name: this.getData(res, "label", this.step2)['label'],
                value: this.getData(res, "", this.step2)
              }
            );
          }
        }
      }
    });

    if (e && disablityData.length > 0) {
      let array = [{
        name: "Accidental Death Details",
        value: "heading"
      }];

      disablityData = [...array, ...disablityData]
    }
    return disablityData;
  }

  otherDeath(e?) {
    let obj;
    const data = this.getData("claimOther", "", this.step2);
    if (data) {
      obj = {
        "name": this.getData("claimOther", "label", this.step2)['label'],
        "value": data
      }
    }
    if (e && obj) {
      let array = [{
        name: "Other",
        value: "heading"
      }];
      obj = [...array, obj]
    }
    return obj
  }


  fractureList(e) {
    let obj = {};
    if (this.getData(e, "", this.step2)) {
      obj = {
        "name": this.getData(e, "label", this.step2)['label'],
        "value": this.getData(e, "", this.step2)
      }
    }

    return obj
  }

  headingArray() {
    let array = [{
      name: "Accident Medical Reimbursement",
      value: "heading"
    },
    ]

    return [...array, ...this.accidentalDeath()]
  }

  featureOrDisclosedPdf() {
    let array = [{
      name: "Fracture  or Dislocation",
      value: "heading"
    }];
    let data = [...array, this.fractureList("sustainFracture"), ...this.featureOrDisclosed(), this.fractureList("sustainDislocation"), ...this.siteDisclosed()]
    return data
  }

  hospitalDoctoreInfoPdf() {
    const uiData = this.dataStoreService.get(this.step2).uiData;
    if (uiData["doctorInfo"] === "Yes" || uiData["doctorInfo"] === "No") {
      let array = [{
        name: "Hospital and Doctor Information",
        value: "heading"
      }];
      let data = [...array, ...this.drInfor(), ...this.hospitalDocInfo("a")];
      return data
    }
  }

  personalAccidentPayload() {
    let personalPayLoad = [];
    let hospitalDocInfoIsPresent = false;
    personalPayLoad = personalPayLoad.concat(this.getPersonalAccident());
    this.personalAccident.forEach(res => {
      if (res === "Accident Medical Reimbursement/ Daily Hospital Income/ Fracture or Dislocation") {
        personalPayLoad = personalPayLoad.concat(this.accidentalMedical("a"), this.dailyHospitalIncome('a'), this.getCreditTravel("a"), this.featureOrDisclosedPdf());
        hospitalDocInfoIsPresent = true;
      }
      if (res === "Medical Leave Benefit") {
        personalPayLoad = personalPayLoad.concat(this.mediaclLeave("a"), this.detailsMedicalLeave());
        hospitalDocInfoIsPresent = true;
      }
      if (res === "Permanent/Temporary Disablement") {
        personalPayLoad = personalPayLoad.concat(this.PermanentDisablement("a"));
        hospitalDocInfoIsPresent = true;
      }
      if (res === "Accidental Death") {
        personalPayLoad = personalPayLoad.concat(this.accidentalDeath("a"));
        hospitalDocInfoIsPresent = true;
      }
      if (res === "Other") {
        personalPayLoad = personalPayLoad.concat(this.otherDeath("a"));
      }
    });
    if (hospitalDocInfoIsPresent) {
      personalPayLoad = personalPayLoad.concat(this.hospitalDoctoreInfoPdf());
    }
    return personalPayLoad;
  }

  buildDetails({
    mainUIFieldName,
    fieldNames,
    conditionalFieldName,
  }: {
    mainUIFieldName: string;
    fieldNames: string[];
    conditionalFieldName?: string
  }) {

    // array to return
    let formattedArray = [];
    // uiStructure fields

    const container = Utils.getFieldFromStructure(
      this.dataStoreService.get(this.step2).uiStructure,
      mainUIFieldName
    ).fields;
    const uiData = this.dataStoreService.get(this.step2).uiData;
    // loop over fields
    for (let i = 0; i < container.length; i++) {
      const matches = container[i].name.match(/(\d+)/);
      const digit = matches?.[0];

      // a main if condition that if "Yes", then do the rest
      if (conditionalFieldName) {
        if (uiData[conditionalFieldName] === "Yes") {
          // array of nested fields to look for
          // loop over nested fields
          formattedArray = this.pushFieldNames({ fieldNames, formattedArray, index: digit ? digit : "" });
        }
      } else {
        formattedArray = this.pushFieldNames({ fieldNames, formattedArray, index: digit ? digit : "" });
      }
    }
    // the main data
    // return array
    return formattedArray;
  }

  pushFieldNames({ fieldNames, formattedArray, index }: { fieldNames: string[]; formattedArray: any[]; index?: string }): { name: string; data: any }[] {
    // array of nested fields to look for
    // loop over nested fields
    fieldNames.forEach((name) => {
      // check for type "checkbox" is unnecessary
      // do one check to make sure data is there
      const data = this.getData(name + index, "", this.step2);
      if (data) {
        // then push to array
        formattedArray.push({
          name: this.getData(name + index, "label", this.step2)["label"],
          value: data,
        });
      }
    });
    return formattedArray;
  }


}

