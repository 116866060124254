import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { AppConfig } from "./app/shared/config";

if (environment.production) {
  enableProdMode();
}
let url = AppConfig.getConfigUrl();
/*
if (location.hostname.includes("dev2.cdpfnol.nprd.aig.com")) {
  url = "assets/config/config.json";
} else if(location.hostname.includes("qa2.cdpfnol.nprd.aig.com")) {
 url = "assets/config/config-qa.json";
} else{
  url = "assets/config/config.json";
}
*/
fetch(url)
  .then(async (res) => {
    let configJson = await res.json();
    AppConfig.setEnvConfig(configJson);

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((err) => {
    console.log("error reading config file: ", err);
  });
